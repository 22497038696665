import React, { FocusEvent, useCallback, useEffect, useState } from "react";
import {
  withStyles,
  Typography,
  Button,
  TextField,
  WithStyles,
  createStyles,
} from "@material-ui/core";
import UnitTextField from "../../components/UnitTextField";
import { useTranslation } from "react-i18next";
import { useRegistry } from "../plugins/registry";
import { useEmployee } from "../core/context/applicationContext";
import { useMutation } from "react-apollo";
import CustomFieldsSection, {
  CustomFieldsSectionProps,
} from "./CustomFieldsSection";
import { useSnackbar } from "material-ui-snackbar-provider";
import { CustomFieldValue } from "../customFields/util";

const styles = () =>
  createStyles({
    method: {
      margin: "4px 0 0 -8px",
      color: "rgba(0, 0, 0, 0.54)",
    },
    deltaField: {
      margin: "4px 0 16px",
      width: "100%",
    },
    noteField: {
      margin: "4px 0 16px",
      width: "100%",
      "& textarea": {
        minHeight: 20, // fix scroll bar showing if empty
      },
    },
    bottom: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
    summary: {
      border: "none",
      float: "left",
      borderSpacing: 0,
      "& td": {
        padding: 0,
      },
      "& td:last-child": {
        textAlign: "right",
      },
    },
    submit: {
      float: "right",
      marginLeft: 24,
    },
  });

export interface StockActionFormTemplateProps {
  item: any;
  onClose: () => void;
  submitQuery: any;
  actionLabel: string;
  getDeltaLines: (delta: number) => Array<{
    label: string;
    value: number;
    showIf?: (value: number) => boolean;
  }>;
  canSubmit: (delta: number) => boolean;
  customFields?: CustomFieldsSectionProps["customFields"];
  successMessage: string;
  errorMessage: string;
  initialDelta?: number;
}

function StockActionFormTemplate({
  item,
  onClose,
  classes,
  submitQuery,
  actionLabel,
  getDeltaLines,
  canSubmit,
  customFields,
  successMessage,
  errorMessage,
  initialDelta,
}: StockActionFormTemplateProps & WithStyles<typeof styles>) {
  const { t } = useTranslation();
  const registry = useRegistry();
  const itemType = registry.stockItemTypes[item.type];
  const stockInfo = itemType.stockInfo;
  const snackbar = useSnackbar();

  const [delta, setDelta] = useState({
    raw: initialDelta?.toLocaleString() ?? "",
    parsed: initialDelta ?? 0,
  });

  const handleDeltaInput = useCallback(
    (e) => {
      if (stockInfo.allowDecimals) {
        setDelta({
          raw: e.target.value,
          parsed: parseFloat(e.target.value),
        });
      } else {
        setDelta({
          raw: e.target.value,
          parsed: parseInt(e.target.value, 10),
        });
      }
    },
    [stockInfo]
  );

  const [note, setNote] = useState("");
  const handleChangeNote = useCallback((e) => {
    setNote(e.target.value);
  }, []);

  const handleSelectInput = (e: FocusEvent<HTMLInputElement>) =>
    (e.target as HTMLInputElement).select();

  const [employee] = useEmployee();

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  useEffect(() => {
    if (customFields) {
      setCustomFieldValues(
        customFields
          .filter((customField) => customField.defaultValue)
          .map(
            (customField): CustomFieldValue => ({
              customFieldId: customField.customFieldId,
              value: customField.defaultValue ?? "",
            })
          )
      );
    }
  }, [customFields]);

  const [submitMutation] = useMutation(submitQuery);
  const handleSubmit = useCallback(async () => {
    try {
      await submitMutation({
        variables: {
          stockItemId: item.id,
          reservationId: item.id,
          orderId: item.id,
          employee,
          note,
          amount: stockInfo.deltaToActual
            ? stockInfo.deltaToActual(delta.parsed, item.article)
            : delta.parsed,
          customFields: customFieldValues,
        },
      });
      onClose();
      snackbar.showMessage(successMessage);
    } catch (e) {
      snackbar.showMessage(errorMessage);
    }
  }, [
    item.id,
    delta,
    note,
    employee,
    customFieldValues,
    submitMutation,
    onClose,
    successMessage,
    errorMessage,
    snackbar,
    stockInfo,
    item.article,
  ]);

  return (
    <React.Fragment>
      <Typography variant="body1">{actionLabel}</Typography>
      <Typography variant="caption">
        {[
          item.stockOrder
            ? `${t("stockReservation:Bestellung")} #${item.id}`
            : item.stockReservation
            ? `${t("stockReservation:Reservierung")} #${item.id}`
            : null,
          itemType.displayName(1, { t }),
          item.article.name,
          item.sku ?? item.article.sku,
        ]
          .filter((text) => !!text?.trim())
          .join(" · ")}
      </Typography>
      <UnitTextField
        className={classes.deltaField}
        type="number"
        label={t("stockReservation:Menge")}
        autoFocus
        InputLabelProps={{ shrink: true }}
        unit={stockInfo.deltaUnit?.({ t }) || stockInfo.unit({ t })}
        value={delta.raw}
        onChange={handleDeltaInput}
        onFocus={handleSelectInput}
      />
      <TextField
        className={classes.noteField}
        label={t("stockReservation:Bemerkung")}
        InputLabelProps={{ shrink: true }}
        placeholder={t("stockReservation:(optional)")}
        fullWidth
        multiline
        value={note}
        onChange={handleChangeNote}
      />
      {customFields && customFields.length > 0 && (
        <CustomFieldsSection
          values={customFieldValues}
          onChange={setCustomFieldValues}
          stockItemType={item.type}
          targetType={customFields[0].target}
          customFields={customFields}
        />
      )}
      <div className={classes.bottom}>
        <table className={classes.summary}>
          <tbody>
            {getDeltaLines(
              stockInfo.deltaToActual
                ? stockInfo.deltaToActual(delta.parsed, item.article)
                : delta.parsed
            ).map(({ label, value, showIf }) => (
              <tr key={label}>
                <td>
                  <Typography variant="body2">{label}:&nbsp;</Typography>
                </td>
                <td>
                  <Typography variant="body2">
                    {!showIf || showIf(value)
                      ? stockInfo.formatWithUnit(value, item.article, {
                          t,
                        })
                      : "–"}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button
          className={classes.submit}
          variant="outlined"
          color="primary"
          disabled={
            isNaN(delta.parsed) || delta.parsed <= 0 || !canSubmit(delta.parsed)
          }
          onClick={handleSubmit}
        >
          {actionLabel}
        </Button>
      </div>
    </React.Fragment>
  );
}

export default withStyles(styles)(StockActionFormTemplate);
