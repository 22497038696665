interface CustomFieldValue {
  customFieldId: string;
  value: string;
}

export const buildFormInputProps = (
  setCustomFieldValues: (values: CustomFieldValue[]) => void,
  customFieldValues: CustomFieldValue[],
  customFieldId: string
) => {
  const fieldValue = customFieldValues.find(
    (fieldValue) => fieldValue.customFieldId === customFieldId
  );
  return {
    value: fieldValue?.value,
    onChange: (e: any) => {
      const newValue = e.target.value;
      const index = fieldValue ? customFieldValues.indexOf(fieldValue) : -1;

      if (index !== -1) {
        setCustomFieldValues([
          ...customFieldValues.slice(0, index),
          { customFieldId, value: newValue },
          ...customFieldValues.slice(index + 1, customFieldValues.length),
        ]);
      } else {
        setCustomFieldValues([
          ...customFieldValues,
          { customFieldId, value: newValue },
        ]);
      }
    },
  };
};
