import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import useFormState, { getSubmitValues } from "../../../util/useFormState";
import { useRegistry } from "../../plugins/registry";
import DraggablePaper from "../../../components/DraggablePaper";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  keepOpen: {
    marginLeft: 8,
  },
  flexFill: {
    flex: 1,
  },
});

function CreateArticleDialog(props) {
  const {
    classes,
    open,
    onClose,
    onSubmit,
    type,
    defaultArticle,
    setFormMethods,
    noteColumnTitles,
    isSkuRequired,
    customFields,
  } = props;

  const { t } = useTranslation();
  const registry = useRegistry();

  const formConfig = useMemo(() => {
    const config = registry.articleTypes[type]?.createArticleFormConfig;
    if (config) {
      return {
        ...config,
        sku: {
          ...config.sku,
          required: isSkuRequired || config.required,
          error: isSkuRequired
            ? (sku) => config.sku.error?.(sku) ?? false
            : config.error,
        },
      };
    }
    return config;
  }, [registry.articleTypes, type, isSkuRequired]);

  const initialArticleWithCustomFields = useMemo(
    () =>
      defaultArticle
        ? defaultArticle
        : {
            customFields: customFields
              .filter((customField) => customField.defaultValue)
              .map((customField) => ({
                customFieldId: customField.customFieldId,
                value: customField.defaultValue ?? "",
              })),
          },
    [defaultArticle, customFields]
  );

  const [form, inputProps] = useFormState(
    formConfig,
    initialArticleWithCustomFields
  );
  const [keepOpen, setKeepOpen] = useState(false);
  if (setFormMethods) {
    setFormMethods({ reset: form.reset });
  }

  const hasInvalidCustomFields = (form.values?.customFields ?? []).some(
    (customFieldValue) =>
      customFields
        .find(
          (customField) =>
            customField.customFieldId === customFieldValue.customFieldId
        )
        ?.validate(customFieldValue.value) === false
  );

  const ArticleForm = type && registry.articleTypes[type].ArticleForm;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick
      PaperComponent={DraggablePaper}
    >
      <DialogTitle id="draggable-paper-handle">
        {t("{{type}}-Artikel hinzufügen", {
          type: type && registry.articleTypes[type].displayName(1, { t }),
          context: type,
        })}
      </DialogTitle>
      <DialogContent>
        {ArticleForm && (
          <ArticleForm
            inputProps={inputProps}
            form={form}
            noteColumnTitles={noteColumnTitles}
            customFields={customFields}
          />
        )}
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          className={classes.keepOpen}
          control={
            <Checkbox
              onChange={(e) => setKeepOpen(e.target.checked)}
              checked={keepOpen}
              color="primary"
            />
          }
          label={t("Weitere Artikel hinzufügen")}
        />
        <div className={classes.flexFill} />
        <Button
          onClick={() => {
            form.reset();
            onClose();
          }}
        >
          {t("Abbrechen")}
        </Button>
        <Button
          color="primary"
          onClick={() =>
            onSubmit(
              { ...getSubmitValues(form.values, formConfig), type },
              { keepOpen }
            )
          }
          disabled={!form.canSubmit || hasInvalidCustomFields}
        >
          {t("Hinzufügen")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(CreateArticleDialog);
