import { useMemo } from "react";
import { gql } from "graphql.macro";
import { useRegistry } from "../plugins/registry";
import { addFragmentsToQuery } from "../../util/graphqlUtil";
import { useQuery } from "react-apollo";

const GET_CUSTOM_FIELDS = gql`
  query GetCustomFields(
    $stockItemType: StockType
    $articleType: ArticleType
    $stockReservationType: StockType
    $stockOrderType: StockType
  ) {
    customFields(
      stockItemType: $stockItemType
      articleType: $articleType
      stockReservationType: $stockReservationType
      stockOrderType: $stockOrderType
    ) {
      id
      name
      type
      asField
      defaultValue
      stockItemType
      articleType
      stockReservationType
      stockOrderType
    }
  }
`;

export default function useCustomFields(filter: {
  stockItemType?: string;
  articleType?: string;
  stockReservationType?: string;
  stockOrderType?: string;
}): Array<{
  id: string;
  name: string;
  type: string;
  asField?: string;
  defaultValue?: string;
  stockItemType?: string;
  articleType?: string;
  stockReservationType?: string;
  stockOrderType?: string;
}> {
  const registry = useRegistry();

  const customFieldsQuery = useMemo(
    () =>
      addFragmentsToQuery(
        GET_CUSTOM_FIELDS,
        Object.values(registry.customFieldType).map(
          (field) => field.customFieldFragment
        )
      ),
    [registry]
  );

  const { data } = useQuery(customFieldsQuery, {
    variables: filter,
    fetchPolicy: "cache-first",
  });

  return data?.customFields ?? [];
}
