import React, { useContext, useState } from "react";
import {
  Collapse,
  List,
  ListItemSecondaryAction,
  Popover,
} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import { ChevronRight, Cog as Settings } from "mdi-material-ui";
import cx from "classnames";
import { useBooleanLocalStorageState } from "../../../util/useLocalStorageState";
import { EnhancedTooltip } from "../../../components/TooltipIconButton";
import ComponentExtensionPoint from "../../plugins/ComponentExtensionPoint";
import { useRegistry } from "../../plugins/registry";
import { useTranslation } from "react-i18next";
import { useFeatures } from "../context/applicationContext";
import { AbilityContext } from "../context/authorization";

const styles = (theme) => ({
  root: {
    overflowY: "auto",
    overflowX: "hidden",
  },
  menu: {
    width: 232,
    transition: theme.transitions.create("width"),

    "@media (min-width: 600px, max-width: 959px)": {
      width: "calc(100vw - 64px)",
      maxWidth: 320, // matches most Google apps
    },

    "@media (max-width: 599px)": {
      width: "calc(100vw - 56px)",
      maxWidth: 320, // matches most Google apps
    },

    "&$collapsed": {
      width: 56,
    },
  },
  itemText: {
    fontSize: "14px",
    color: theme.palette.text.secondary,
  },
  selectedItem: {
    backgroundColor: "rgba(0, 0, 0, 0.05) !important", // same as Inbox

    "& $itemText, & $icon": {
      color: theme.palette.primary.main,
    },
  },
  listItem: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,

    "& span": {
      fontWeight: 500,
    },

    "&:not($selectedItem):hover": {
      background: "rgba(0, 0, 0, 0.03)", // same as Inbox
    },
  },
  nestedMenu: {
    "& $listItem": {
      paddingLeft: 32,
    },
  },
  toggleChevron: {
    position: "absolute",
    left: 192,
    height: 24,
    "& svg": {
      transition: theme.transitions.create("transform"),
    },
    "&:not($collapsed) svg": {
      transform: "rotate(90deg)",
    },
  },
  collapseItem: {
    "&:focus": {
      background: "transparent",
    },
  },
  collapseIcon: {
    "& svg": {
      transition: theme.transitions.create("transform"),
    },
    "&:not($collapsed) svg": {
      transform: "rotate(180deg)",
    },
  },
  icon: {}, // don't remove this, it's used above
  collapsed: {}, // don't remove this, it's used above
});

function NavigationDrawer({
  classes,
  activeRoute,
  onChangeRoute,
  additionalStockItemTypes,
  additionalMenuItems,
  additionalSettingsMenuItems,
}) {
  const { t } = useTranslation();
  const { pages } = useRegistry();
  const features = useFeatures();
  const abilities = useContext(AbilityContext);

  const menuItems = [
    additionalMenuItems.find(({ route }) => route === "Stock"),
    ...additionalStockItemTypes.map((menuItem) => ({
      ...menuItem,
      ifCan: ["read", "Stock"],
    })),
    ...additionalMenuItems.filter(({ route }) => route !== "Stock"),
  ];

  const settingsMenuItems = [...additionalSettingsMenuItems];
  settingsMenuItems.sort((a, b) => (a.order || 30) - (b.order || 30));

  const [collapsed, setCollapsed] = useBooleanLocalStorageState(
    "skl-menu-collapsed",
    false
  );

  const settingsAnchorEl = React.useRef();
  const [showSettingsPopover, setShowSettingsPopover] = useState(false);
  const [showSettings, setShowSettings] = useBooleanLocalStorageState(
    "skl-settings-collapsed",
    false
  );

  const settingsMenuItem = (
    <MenuItem
      dense={false}
      classes={{
        root: classes.listItem,
        selected: classes.selectedItem,
      }}
      button
      data-collapsed={collapsed}
      buttonRef={settingsAnchorEl}
      onClick={() => {
        if (collapsed) {
          setShowSettingsPopover(true);
        } else {
          setShowSettings(!showSettings);
        }
      }}
    >
      <ListItemIcon classes={{ root: classes.icon }}>
        <Settings />
      </ListItemIcon>
      <ListItemText
        primary={t("Einstellungen")}
        classes={{ primary: classes.itemText }}
      />
      {!collapsed && (
        <ListItemSecondaryAction
          className={cx(classes.toggleChevron, {
            [classes.collapsed]: !showSettings,
          })}
          onClick={() => setShowSettings((s) => !s)}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <ChevronRight />
          </ListItemIcon>
        </ListItemSecondaryAction>
      )}
    </MenuItem>
  );

  const settingsMenuContent = settingsMenuItems
    .filter(
      ({ hidden, ifCan }) =>
        !hidden?.({ features, pages }) && (!ifCan || abilities.can(...ifCan))
    )
    .map(
      (
        {
          name,
          icon,
          route,
          tooltip,
          Component = MenuItem,
          disabled,
          ...other
        },
        i
      ) => (
        <Component
          key={i}
          selected={route != null && activeRoute === route}
          classes={{
            root: classes.listItem,
            selected: classes.selectedItem,
          }}
          dense={false}
          button
          onClick={() => {
            if (route !== activeRoute) onChangeRoute(route);
          }}
          data-collapsed={collapsed}
          disabled={disabled?.({ pages, features }) === true}
          {...other}
        >
          <ListItemIcon classes={{ root: classes.icon }}>{icon}</ListItemIcon>
          <ListItemText
            primary={typeof name === "function" ? name({ t }) : name}
            classes={{ primary: classes.itemText }}
          />
        </Component>
      )
    );

  const canReadSettings = abilities.can("read", "Settings");
  const visibleMenuItems = menuItems.filter(
    ({ hidden, ifCan }) =>
      !hidden?.({ features, pages }) && (!ifCan || abilities.can(...ifCan))
  );
  visibleMenuItems.sort((a, b) => (a.order || 30) - (b.order || 30));
  const totalVisibleMenuItems =
    visibleMenuItems.length + (canReadSettings ? 1 : 0);

  return (
    <div className={classes.root}>
      <List className={cx(classes.menu, { [classes.collapsed]: collapsed })}>
        {visibleMenuItems.map(
          (
            {
              name,
              icon,
              route,
              tooltip,
              Component = MenuItem,
              ifCan,
              ...other
            },
            i
          ) => {
            const menuItem = (
              <Component
                key={i}
                selected={activeRoute === route}
                classes={{
                  root: classes.listItem,
                  selected: classes.selectedItem,
                }}
                button
                dense={false}
                onClick={() => {
                  if (route !== activeRoute) onChangeRoute(route);
                }}
                data-collapsed={collapsed}
                {...other}
              >
                <ListItemIcon classes={{ root: classes.icon }}>
                  {icon}
                </ListItemIcon>
                <ListItemText
                  primary={typeof name === "function" ? name({ t }) : name}
                  classes={{ primary: classes.itemText }}
                />
              </Component>
            );
            return collapsed ? (
              <EnhancedTooltip
                key={i}
                title={
                  (typeof tooltip === "function" ? tooltip({ t }) : tooltip) ??
                  (typeof name === "function" ? name({ t }) : name)
                }
                placement="right"
              >
                {menuItem}
              </EnhancedTooltip>
            ) : (
              menuItem
            );
          }
        )}
        {abilities.can("view", "Settings") && (
          <>
            {collapsed ? (
              <>
                <EnhancedTooltip title={t("Einstellungen")} placement="right">
                  {settingsMenuItem}
                </EnhancedTooltip>
                <Popover
                  open={showSettingsPopover && collapsed}
                  anchorEl={settingsAnchorEl.current}
                  onClose={(e) => setShowSettingsPopover(false)}
                  anchorOrigin={{ horizontal: "right", vertical: "top" }}
                  transformOrigin={{ horizontal: "left", vertical: "top" }}
                  onClick={() => setShowSettingsPopover(false)}
                >
                  <List>{settingsMenuContent}</List>
                </Popover>
              </>
            ) : (
              settingsMenuItem
            )}
            {!collapsed && (
              <Collapse in={showSettings} className={classes.nestedMenu}>
                {settingsMenuContent}
              </Collapse>
            )}
          </>
        )}
        {totalVisibleMenuItems > 0 && (
          <MenuItem
            dense={false}
            onClick={() => {
              setCollapsed(!collapsed);
              setShowSettings(false);
            }}
            className={cx(classes.listItem, classes.collapseItem)}
            disableRipple
          >
            <ListItemIcon
              classes={{
                root: cx(classes.icon, classes.collapseIcon, {
                  [classes.collapsed]: collapsed,
                }),
              }}
            >
              <ChevronRight />
            </ListItemIcon>
          </MenuItem>
        )}
        <ComponentExtensionPoint
          name="navigationDrawer"
          innerProps={{ collapsed }}
          variant="list"
        />
      </List>
    </div>
  );
}

export default withStyles(styles)(NavigationDrawer);
