import { gql } from "graphql.macro";
import React from "react";
import { addLogItemType } from "../plugins/registry";
import FieldChanges from "../log/components/FieldChanges";

addLogItemType({
  id: "articleChange",
  logItemFragment: gql`
    fragment ArticleChangeLogItemData on ArticleChangeLogItem {
      article {
        id
        sku
        type
        name
      }
      changedFields {
        field
        oldValue
        newValue
      }
    }
  `,
  sku: (row) => row.article.sku,
  change: (row, { registry, t }) => {
    const articleType = registry.articleTypes[row.article.type];
    return (
      <FieldChanges changedFields={row.changedFields} type={articleType} />
    );
  },
  type: (row, { t }) => t("Artikel aktualisiert"),
});

addLogItemType({
  id: "articleStateChange",
  logItemFragment: gql`
    fragment ArticleStateChangeLogItemData on ArticleStateChangeLogItem {
      action: change
      article {
        id
        sku
        type
        name
      }
    }
  `,
  sku: (row) => row.article.sku,
  change: () => "",
  type: (row, { t }) => {
    switch (row.action) {
      case "archive":
        return t("Artikel archiviert");
      case "unarchive":
        return t("Artikel wiederhergestellt");
      case "remove":
        return t("Artikel gelöscht");
      default:
        return row.action;
    }
  },
});

addLogItemType({
  id: "noteChange",
  logItemFragment: gql`
    fragment NoteChangeLogItemData on NoteChangeLogItem {
      change {
        oldText
        newText
        title
        index
      }
      article {
        id
        sku
        type
        name
      }
      stockItem {
        id
        sku
        type
        article {
          id
          sku
          type
          name
        }
      }
    }
  `,
  sku: (row) => {
    if (row.stockItem) {
      return row.stockItem.sku ?? row.stockItem.article.sku;
    }
    return row.article.sku;
  },
  change: (row, { t }) =>
    row.stockItem ? (
      <React.Fragment>
        {row.change.title ||
          t("Bestands-Notiz {{number}}", { number: row.change.index + 1 })}
        : <del>{row.change.oldText}</del> <ins>{row.change.newText}</ins>
      </React.Fragment>
    ) : (
      <React.Fragment>
        {row.change.title ||
          t("Artikel-Notiz {{number}}", { number: row.change.index + 1 })}
        : <del>{row.change.oldText}</del> <ins>{row.change.newText}</ins>
      </React.Fragment>
    ),
  type: (row, { t }) => {
    const noteName = row.stockItem ? t("Bestands-Notiz") : t("Artikel-Notiz");
    if (row.change.oldText) {
      if (row.change.newText) {
        return t("{{note}} aktualisiert", { note: noteName });
      }
      return t("{{note}} entfernt", { note: noteName });
    }
    return t("{{note}} hinzugefügt", { note: noteName });
  },
});
