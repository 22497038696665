import React from "react";
import { red } from "@material-ui/core/colors";
import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import { numberColumn } from "../../../util/enhancedTableUtils";
import {
  aggregatedRowSum,
  currentStockFilter,
  ifStockItem,
} from "../../stock/components/stockTable";
import { compareNaturalBy } from "../../../util/compareNatural";

function getStock(row) {
  return row.initial + row.in - row.out;
}

export default ({ t }) => [
  {
    id: "name",
    title: t("Artikel", { count: 1 }),
    contentString: (row) => row.article.name,
    compare: compareNaturalBy((row) => row.article.name),
    filter: {
      type: StringSearchFilter,
    },
  },
  {
    id: "sku",
    title: t("Artikelnr."),
    contentString: (row) => row.sku ?? row.article.sku,
    compare: compareNaturalBy((row) => row.sku ?? row.article.sku),
    filter: {
      type: StringSearchFilter,
    },
  },
  numberColumn({
    id: "current",
    title: t("Realer Bestand"),
    getNumber: aggregatedRowSum(getStock, getStock),
    content: ifStockItem(
      (row, { nestedOf, cellContentParams: { features } }) => {
        // TODO move to common stock tables and reuse everywhere
        const current = getStock(row);
        if (
          !features.minimumStockByPlannedStock &&
          (nestedOf == null || nestedOf.flattened) && // nur in aggregierten Zeilen anzeigen
          row.article.minimumStock != null &&
          current <= row.article.minimumStock
        ) {
          return (
            <b style={{ color: red[500] }}>
              {current.toLocaleString()} {t("Stk.")}
            </b>
          );
        }
        return (
          <b>
            {current.toLocaleString()} {t("Stk.")}
          </b>
        );
      },
      (row, { cellContentParams: { features } }) => {
        const current =
          getStock(row) ||
          row.stockItems.reduce(
            (sum, stockItem) => sum + getStock(stockItem),
            0
          );
        if (
          !features.minimumStockByPlannedStock &&
          row.article.minimumStock != null &&
          current <= row.article.minimumStock
        ) {
          return (
            <b style={{ color: red[500] }}>
              {current.toLocaleString()} {t("Stk.")}
            </b>
          );
        }
        return (
          <b>
            {current.toLocaleString()} {t("Stk.")}
          </b>
        );
      }
    ),
    unit: { position: "end", name: t("Stk.") },
    range: { min: 0, max: null },
    filter: currentStockFilter({ getStock }),
  }),
  numberColumn({
    id: "currentWeight",
    title: t("Gesamtgewicht"),
    getNumber: aggregatedRowSum(
      (row) => getStock(row) * row.article.details.weight
    ),
    unit: {
      position: "end",
      name: "kg",
    },
    range: {
      min: 0,
      max: null,
    },
  }),
  numberColumn({
    id: "minimumStock",
    title: t("Meldebestand"),
    getNumber: (row) => row.article.minimumStock,
    unit: { position: "end", name: t("Stk.") },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "article.weight",
    title: t("Gewicht pro Stück"),
    getNumber: (row) => row.article.details.weight,
    unit: {
      position: "end",
      name: "kg",
    },
    range: {
      min: 0,
      max: null,
    },
  }),
];
