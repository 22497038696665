import React from "react";
import { red } from "@material-ui/core/colors";
import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import { numberColumn } from "../../../util/enhancedTableUtils";
import {
  aggregatedRowSum,
  currentStockFilter,
  ifStockItem,
} from "../../stock/components/stockTable";
import { formatWithUnit } from "../../../util/numberFormat";
import { compareNaturalBy } from "../../../util/compareNatural";

function getStock(row) {
  return row.initial + row.in - row.out;
}

function getWeightByLength(row, length) {
  return (row.article.details.weight / row.article.details.length) * length;
}

export default ({ t }) => [
  {
    id: "name",
    title: t("Artikel", { count: 1 }),
    contentString: (row) => row.article.name,
    compare: compareNaturalBy((row) => row.article.name),
    filter: {
      type: StringSearchFilter,
    },
  },
  {
    id: "sku",
    title: t("Artikelnr."),
    contentString: (row) => row.sku ?? row.article.sku,
    compare: compareNaturalBy((row) => row.sku ?? row.article.sku),
    filter: {
      type: StringSearchFilter,
    },
  },
  numberColumn({
    id: "current",
    title: t("Realer Bestand"),
    range: {
      min: 0,
      max: null,
    },
    unit: { position: "end", name: "m" },
    getNumber: aggregatedRowSum(getStock, getStock),
    content: ifStockItem(
      (row, { nestedOf, cellContentParams: { features } }) => {
        const current = getStock(row);
        if (
          !features.minimumStockByPlannedStock &&
          (nestedOf == null || nestedOf.flattened) && // nur in aggregierten Zeilen anzeigen
          row.article.minimumStock != null &&
          current <= row.article.minimumStock
        ) {
          return (
            <b style={{ color: red[500] }}>{formatWithUnit(current, "m")}</b>
          );
        }
        return <b>{formatWithUnit(current, "m")}</b>;
      },
      (row, { cellContentParams: { features } }) => {
        const current =
          getStock(row) ||
          row.stockItems.reduce(
            (sum, stockItem) => sum + getStock(stockItem),
            0
          );
        if (
          !features.minimumStockByPlannedStock &&
          row.article.minimumStock != null &&
          current <= row.article.minimumStock
        ) {
          return (
            <b style={{ color: red[500] }}>{formatWithUnit(current, "m")}</b>
          );
        }
        return <b>{formatWithUnit(current, "m")}</b>;
      }
    ),
    filter: currentStockFilter({ getStock }),
  }),
  numberColumn({
    id: "currentWeight",
    title: t("Gesamtgewicht"),
    getNumber: aggregatedRowSum((row) => getWeightByLength(row, getStock(row))),
    unit: {
      position: "end",
      name: "kg",
    },
    range: {
      min: 0,
      max: null,
    },
  }),
  numberColumn({
    id: "minimumStock",
    title: t("Meldebestand"),
    range: {
      min: 0,
      max: null,
    },
    unit: { position: "end", name: "m" },
    getNumber: (row) => row.article.minimumStock,
  }),
  numberColumn({
    id: "article.width",
    title: t("Breite"),
    getNumber: (row) => row.article.details.width,
    unit: {
      position: "end",
      name: "mm",
    },
    range: {
      min: 0,
      max: null,
    },
  }),
  numberColumn({
    id: "article.thickness",
    title: t("Dicke"),
    getNumber: (row) => row.article.details.thickness,
    fractionalDigits: 2,
    unit: {
      position: "end",
      name: "mm",
    },
    range: {
      min: 0,
      max: null,
    },
  }),
  {
    id: "article.material",
    title: t("Werkstoff"),
    content: (row) => row.article.details.material,
    contentString: (row) => row.article.details.material,
    filter: {
      type: StringSearchFilter,
    },
  },
];
