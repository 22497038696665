import React from "react";
import { red } from "@material-ui/core/colors";
import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import { numberColumn } from "../../../util/enhancedTableUtils";
import {
  aggregatedRowSum,
  currentStockFilter,
  ifStockItem,
} from "../../stock/components/stockTable";
import { compareNaturalBy } from "../../../util/compareNatural";

function getStock(row) {
  return row.initial + row.in - row.out;
}

function getWeightByCount(item, count) {
  const { length, width, area, specificWeight } = item.article.details;

  if (isNaN(specificWeight)) {
    return null;
  }
  if (area) {
    return count * (area * specificWeight);
  }
  return count * (length * width * specificWeight * 1e-6);
}

export default ({ t }) => [
  {
    id: "name",
    title: t("Artikel", { count: 1 }),
    contentString: (row) => row.article.name,
    compare: compareNaturalBy((row) => row.article.name),
    filter: {
      type: StringSearchFilter,
    },
  },
  {
    id: "sku",
    title: t("Artikelnr."),
    contentString: (row) => row.sku ?? row.article.sku,
    compare: compareNaturalBy((row) => row.sku ?? row.article.sku),
    filter: {
      type: StringSearchFilter,
    },
  },
  numberColumn({
    id: "current",
    title: t("Realer Bestand"),
    content: ifStockItem(
      (row, { nestedOf, cellContentParams: { features } }) => {
        // TODO move to common stock tables and reuse everywhere
        const current = getStock(row);
        if (isNaN(current)) {
          return "";
        }
        if (
          !features.minimumStockByPlannedStock &&
          (nestedOf == null || nestedOf.flattened) && // nur in aggregierten Zeilen anzeigen
          row.article.minimumStock != null &&
          current <= row.article.minimumStock
        ) {
          return (
            <b style={{ color: red[500] }}>
              {current.toLocaleString()} {t("Stk.")}
            </b>
          );
        }
        return (
          <b>
            {current.toLocaleString()} {t("Stk.")}
          </b>
        );
      },
      (row, { cellContentParams: { features } }) => {
        const current =
          getStock(row) ||
          row.stockItems.reduce(
            (sum, stockItem) => sum + getStock(stockItem),
            0
          );
        if (isNaN(current)) {
          return "";
        }
        if (
          !features.minimumStockByPlannedStock &&
          row.article.minimumStock != null &&
          current <= row.article.minimumStock
        ) {
          return (
            <b style={{ color: red[500] }}>
              {current.toLocaleString()} {t("Stk.")}
            </b>
          );
        }
        return (
          <b>
            {current.toLocaleString()} {t("Stk.")}
          </b>
        );
      }
    ),
    getNumber: aggregatedRowSum(getStock, getStock),
    unit: { position: "end", name: t("Stk.") },
    range: { min: 0, max: null },
    filter: currentStockFilter({ getStock }),
  }),
  numberColumn({
    id: "currentWeight",
    title: t("Gesamtgewicht"),
    getNumber: aggregatedRowSum(
      (row) => {
        if (row.type === "restSheet") {
          // this is a rest sheet in the stock reservation table
          if (row.reserved != null) {
            const { area } = row.details;
            const { specificWeight } = row.article.details;
            if (isNaN(specificWeight)) {
              return null;
            }
            return row.reserved * (area * specificWeight * 1e-6);
          }
          return null;
        }
        return getWeightByCount(row, getStock(row));
      },
      (row) => getWeightByCount(row, getStock(row))
    ),
    unit: {
      position: "end",
      name: "kg",
    },
    range: { min: 0, max: null },
  }),
  numberColumn({
    // TODO move to common stock tables and reuse everywhere
    id: "minimumStock",
    title: t("Meldebestand"),
    getNumber: (row) => row.article.minimumStock,
    unit: { position: "end", name: t("Stk.") },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "article.length",
    title: t("Länge"),
    getNumber: (row) => {
      if (row.type === "restSheet") {
        // this is a rest sheet in the stock reservation table
        return row.stockItem.details.length;
      }
      return row.article.details.length;
    },
    unit: { position: "end", name: "mm" },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "article.width",
    title: t("Breite"),
    getNumber: (row) => {
      if (row.type === "restSheet") {
        // this is a rest sheet in the stock reservation table
        return row.stockItem.details.width;
      }
      return row.article.details.width;
    },
    unit: { position: "end", name: "mm" },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "details.area",
    title: t("Fläche"),
    getNumber: (row) => {
      if (row.type === "restSheet") {
        // this is a rest sheet in the stock reservation table
        if (row.reserved != null) {
          return row.reserved * row.details.area * 1e-6;
        }
        return null;
      }
      return (
        getStock(row) *
        row.article.details.width *
        row.article.details.length *
        1e-6
      );
    },
    unit: { position: "end", name: "m²" },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "article.details.thickness",
    title: t("Dicke"),
    getNumber: (row) => row.article.details.thickness,
    fractionalDigits: 2,
    unit: { position: "end", name: "mm" },
    range: { min: 0, max: null },
  }),
  {
    id: "article.details.material",
    title: t("Werkstoff"),
    content: (row) => row.article.details.material,
    contentString: (row) => row.article.details.material,
    filter: {
      type: StringSearchFilter,
    },
  },
  numberColumn({
    id: "details.restSheetCount",
    title: t("Restbleche"),
    getNumber: ifStockItem(
      (row) =>
        // show sum only for aggregated rows or single-item rows (SKL-290 would fix this)
        row.parent.stockItems.length < 2
          ? row.details?.restSheetCount ?? 0
          : null,
      (aggregatedRow) => aggregatedRow.details?.restSheetCount ?? 0
    ),
    unit: { position: "end", name: t("Stk.") },
    range: { min: 0, max: null },
  }),
];
