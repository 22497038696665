import React, { useMemo } from "react";
import { useMutation, useQuery } from "react-apollo";
import { gql } from "graphql.macro";
import { MenuItem } from "@material-ui/core";
import { useSnackbar } from "material-ui-snackbar-provider";
import VectorDifferenceBa from "mdi-material-ui/VectorDifferenceBa";
import articlesTableConfig from "./sheetTable";
import ArticleForm from "./ArticleForm/SheetArticleForm";
import createArticleFormConfig from "./ArticleForm/createArticleForm";
import editArticleFormConfig from "./ArticleForm/editArticleForm";
import {
  addArticleType,
  articleProperties,
  addStockItemType,
  useRegistry,
} from "../../plugins/registry";
import sheetTableConfig from "./sheetStockTable";
import restTableConfig from "./restStockTable";
import { articleFragment, GET_STOCK } from "../../stock/containers/Stock";
import RestSheetForm from "./RestSheetForm";
import { useEmployee } from "../../core/context/applicationContext";
import { formatWithUnit } from "../../../util/numberFormat";
import { useTranslation } from "react-i18next";
import {
  buildCustomFields,
  GET_CUSTOM_FIELDS_FOR_REST_STOCKTYPE,
} from "../customFields.util";

const createRestSheetAction = {
  label: ({ t }) => t("Restblech anlegen"),
  MenuItem: ({ item, onClose, ...other }) => {
    const { t } = useTranslation();
    return <MenuItem {...other}>{t("Restblech anlegen")}</MenuItem>;
  },
  PopoverForm: ({ item, onClose }) => {
    const { t } = useTranslation();
    const [employee] = useEmployee();
    const snackbar = useSnackbar();
    const registry = useRegistry();
    const { data: customFieldDefinitions } = useQuery(
      GET_CUSTOM_FIELDS_FOR_REST_STOCKTYPE,
      {
        variables: {
          stockItemType: "restSheet",
        },
        fetchPolicy: "network-only",
      }
    );
    const [createRestSheet] = useMutation(
      gql`
        mutation CreateRestSheet(
          $item: CreateItemInput!
          $initialStock: Float!
          $changeItemStock: Float
          $employee: String
          $note: String
        ) {
          createItem(
            item: $item
            initialStock: $initialStock
            changeItemStock: $changeItemStock
            employee: $employee
            note: $note
          ) {
            id
            sku
            article {
              ...StockArticle
            }
          }
        }
        ${articleFragment}
      `,
      {
        refetchQueries: () => [
          {
            query: GET_STOCK,
            variables: { type: "sheet", articleType: "sheet" },
          },
        ],
      }
    );
    const [revertCreateRestSheet] = useMutation(
      gql`
        mutation RevertCreateRestSheet(
          $id: ID!
          $item: CreateItemInput!
          $initialStock: Float!
          $changeItemStock: Float
          $employee: String
        ) {
          revertCreateItem(
            id: $id
            item: $item
            initialStock: $initialStock
            changeItemStock: $changeItemStock
            employee: $employee
          ) {
            id
            sku
            article {
              ...StockArticle
            }
          }
        }
        ${articleFragment}
      `,
      {
        refetchQueries: () => [
          {
            query: GET_STOCK,
            variables: { type: "sheet", articleType: "sheet" },
          },
        ],
      }
    );

    const customFields = useMemo(
      () => buildCustomFields(registry, t, customFieldDefinitions),
      [customFieldDefinitions, registry, t]
    );

    const handleSubmit = async ({
      articleId,
      width,
      length,
      cutouts,
      initialStock,
      loadCarrier,
      compartment,
      updateStock,
      note,
      customFields,
    }) => {
      try {
        const variables = {
          item: {
            articleId,
            itemId: item.id,
            restSheet: {
              width,
              length,
              cutouts,
            },
            type: "restSheet",
            loadCarrierId: loadCarrier,
            compartmentId: compartment,
            customFields,
          },
          initialStock,
          changeItemStock: updateStock ? -initialStock : null,
          employee,
          note,
        };
        const restItem = await createRestSheet({
          variables,
        }).then((result) => result.data.createItem);
        snackbar.showMessage(
          t("Das Restblech {{sku}} wurde angelegt", {
            sku: restItem.sku,
          }),
          t("Rückgängig"),
          async () => {
            try {
              await revertCreateRestSheet({
                variables: { ...variables, id: restItem.id },
              });
            } catch (e) {
              console.error(e);
              snackbar.showMessage(
                t(
                  "Das Anlegen des Restblechs konnte nicht rückgängig gemacht werden"
                )
              );
            }
          }
        );
        onClose();
      } catch (e) {
        console.error(e);
        snackbar.showMessage(t("Das Restblech konnte nicht angelegt werden"));
      }
    };

    return (
      <RestSheetForm
        item={item}
        customFields={customFields}
        onSubmit={handleSubmit}
      />
    );
  },
};

addArticleType({
  id: "sheet",
  displayName: (_, { t }) => t("Blech"),
  stockInfo: {
    unit: ({ t }) => t("Stk."),
    unitName: ({ t }) => t("Anzahl"),
    deltaUnitName: ({ t }) => t("Anzahl", { context: "deltaUnitName" }),
    calculateNewStock: (currentStock, delta) =>
      currentStock + delta >= 0 ? currentStock + delta : NaN,
    supportsDeltaByWeight: (article) =>
      article.details.specificWeight != null &&
      !isNaN(article.details.specificWeight),
    formatWithUnit: (value, article, { t }) => `${value} ${t("Stk.")}`,
    allowDecimals: false,
    weightByStock: (item, count) => {
      const { length, width, area, specificWeight } = item.article.details;
      if (isNaN(specificWeight)) {
        return null;
      }
      if (area) {
        return count * (area * specificWeight);
      }
      return count * (length * width * specificWeight * 1e-6);
    },
  },
  articlesTableConfig,
  stockTableConfig: sheetTableConfig,
  ArticleForm,
  createArticleFormConfig,
  editArticleFormConfig,
  stockActions: [createRestSheetAction],
  articleActions: [
    {
      ...createRestSheetAction,
      PopoverForm: ({ article, ...other }) => {
        const item = useMemo(
          () => ({
            article,
            initial: 0,
            in: 0,
            out: 0,
            type: "sheet",
          }),
          [article]
        );

        return <createRestSheetAction.PopoverForm {...other} item={item} />;
      },
    },
  ],
  properties: {
    ...articleProperties,
    "sheet.material": { displayName: (_, { t }) => t("Werkstoff") },
    "sheet.length": {
      displayName: (_, { t }) => t("Länge"),
      formatValue: (value) => formatWithUnit(parseFloat(value), "mm"),
    },
    "sheet.width": {
      displayName: (_, { t }) => t("Breite"),
      formatValue: (value) => formatWithUnit(parseFloat(value), "mm"),
    },
    "sheet.area": {
      displayName: (_, { t }) => t("Fläche"),
      formatValue: (value) => formatWithUnit(parseFloat(value), "mm"),
    },
    "sheet.thickness": {
      displayName: (_, { t }) => t("Dicke"),
      formatValue: (value) => formatWithUnit(parseFloat(value), "mm", 2),
    },
    "sheet.specificWeight": {
      displayName: (_, { t }) => t("Gewicht"),
      formatValue: (value) => formatWithUnit(parseFloat(value), "kg/m²"),
    },
  },
});

addStockItemType({
  id: "restSheet",
  articleType: "sheet",
  displayName: (n, { t }) => t("Restblech", { count: n }),
  icon: VectorDifferenceBa,
  stockInfo: {
    unit: ({ t }) => t("Stk."),
    unitName: ({ t }) => t("Stück"),
    formatWithUnit: (value, article, { t }) =>
      `${value.toLocaleString()} ${t("Stk.")}`,
    allowDecimals: false,
    calculateNewStock: (currentStock, delta) =>
      currentStock + delta >= 0 ? currentStock + delta : NaN,
    sku: (item) => `${item.article.sku}-R${item.id}`,
    supportsDeltaByWeight: () => false,
    weightByStock: () => 0, // not needed
  },
  stockTableConfig: restTableConfig,
  stockActions: [
    {
      label: ({ t }) => t("Restblech anlegen/bearbeiten"),
      MenuItem: ({ item, onClose, ...other }) => {
        const { t } = useTranslation();
        return (
          <MenuItem {...other}>
            {item.initial + item.in - item.out <= 1
              ? t("Restblech bearbeiten")
              : t("Restblech anlegen")}
          </MenuItem>
        );
      },
      PopoverForm: ({ item, onClose }) => {
        const { t } = useTranslation();
        const isSingleSheet = item.initial + item.in - item.out <= 1;
        const [employee] = useEmployee();
        const snackbar = useSnackbar();
        const registry = useRegistry();
        const [createRestSheet] = useMutation(
          gql`
            mutation CreateRestSheet(
              $item: CreateItemInput!
              $initialStock: Float!
              $changeItemStock: Float
              $employee: String
              $note: String
            ) {
              createItem(
                item: $item
                initialStock: $initialStock
                changeItemStock: $changeItemStock
                employee: $employee
                note: $note
              ) {
                id
                sku
                article {
                  ...StockArticle
                }
              }
            }
            ${articleFragment}
          `,
          {
            refetchQueries: () => [
              {
                query: GET_STOCK,
                variables: { type: "restSheet", articleType: "sheet" },
              },
            ],
          }
        );
        const [revertCreateRestSheet] = useMutation(
          gql`
            mutation RevertCreateRestSheet(
              $id: ID!
              $item: CreateItemInput!
              $initialStock: Float!
              $changeItemStock: Float
              $employee: String
            ) {
              revertCreateItem(
                id: $id
                item: $item
                initialStock: $initialStock
                changeItemStock: $changeItemStock
                employee: $employee
              ) {
                id
                sku
                article {
                  ...StockArticle
                }
              }
            }
            ${articleFragment}
          `,
          {
            refetchQueries: () => [
              {
                query: GET_STOCK,
                variables: { type: "restSheet", articleType: "sheet" },
              },
            ],
          }
        );
        const [updateRestSheet] = useMutation(
          gql`
            mutation UpdateRestSheet(
              $item: UpdateItemInput!
              $employee: String
              $note: String
            ) {
              updateItem(item: $item, employee: $employee, note: $note) {
                id
                sku
                article {
                  ...StockArticle
                }
                details {
                  ... on RestSheetStockItemDetails {
                    width
                    length
                    area
                    cutouts {
                      x1
                      y1
                      x2
                      y2
                    }
                  }
                }
              }
            }
            ${articleFragment}
          `,
          {
            refetchQueries: [
              {
                query: GET_STOCK,
                variables: { articleType: "sheet", type: "restSheet" },
              },
            ],
          }
        );

        const handleSubmit = async ({
          width,
          length,
          cutouts,
          initialStock,
          loadCarrier,
          compartment,
          updateStock,
          note,
        }) => {
          if (isSingleSheet) {
            try {
              const updatedItem = await updateRestSheet({
                variables: {
                  item: {
                    id: item.id,
                    restSheet: {
                      width,
                      length,
                      cutouts: cutouts.map((c) => ({
                        x1: c.x1,
                        y1: c.y1,
                        x2: c.x2,
                        y2: c.y2,
                      })),
                    },
                    loadCarrierId: loadCarrier,
                    compartmentId: compartment,
                  },
                  employee,
                  note,
                },
              }).then((result) => result.data.updateItem);
              snackbar.showMessage(
                t("Das Restblech {{sku}} wurde aktualisiert", {
                  sku: updatedItem.sku,
                }),
                t("Rückgängig"),
                async () => {
                  try {
                    await updateRestSheet({
                      variables: {
                        item: {
                          id: item.id,
                          restSheet: {
                            width: item.details.width,
                            length: item.details.length,
                            cutouts: item.details.cutouts.map((c) => ({
                              x1: c.x1,
                              y1: c.y1,
                              x2: c.x2,
                              y2: c.y2,
                            })),
                          },
                          loadCarrierId: item.loadCarrier?.id ?? null,
                          compartmentId: item.compartment?.id ?? null,
                        },
                        employee,
                      },
                    });
                  } catch (e) {
                    console.error(e);
                    snackbar.showMessage(
                      t(
                        "Das Bearbeiten des Restblechs konnte nicht rückgängig gemacht werden"
                      )
                    );
                  }
                }
              );
              onClose();
            } catch (e) {
              console.error(e);
              snackbar.showMessage(
                t("Das Restblech konnte nicht aktualisiert werden")
              );
            }
          } else {
            try {
              const variables = {
                item: {
                  articleId: item.article.id,
                  itemId: item.id,
                  restSheet: {
                    width,
                    length,
                    cutouts: cutouts.map((c) => ({
                      x1: c.x1,
                      y1: c.y1,
                      x2: c.x2,
                      y2: c.y2,
                    })),
                  },
                  type: "restSheet",
                  loadCarrierId: loadCarrier,
                  compartmentId: compartment,
                },
                initialStock,
                changeItemStock: updateStock ? -initialStock : null,
                employee,
                note,
              };
              const restItem = await createRestSheet({
                variables,
              }).then((result) => result.data.createItem);
              snackbar.showMessage(
                t("Das Restblech {{sku}} wurde angelegt", {
                  sku: restItem.sku,
                }),
                t("Rückgängig"),
                async () => {
                  try {
                    await revertCreateRestSheet({
                      variables: { ...variables, id: restItem.id },
                    });
                  } catch (e) {
                    console.error(e);
                    snackbar.showMessage(
                      t(
                        "Das Anlegen des Restblechs konnte nicht rückgängig gemacht werden"
                      )
                    );
                  }
                }
              );
              onClose();
            } catch (e) {
              console.error(e);
              snackbar.showMessage(
                t("Das Restblech konnte nicht angelegt werden")
              );
            }
          }
        };

        return (
          <RestSheetForm
            item={item}
            onSubmit={handleSubmit}
            showStockChangeBox={!isSingleSheet}
            editing={isSingleSheet}
          />
        );
      },
    },
    {
      label: ({ t }) => t("Restblech ausblenden"),
      MenuItem: ({ item, onClose, ...other }) => {
        const { t } = useTranslation();
        const snackbar = useSnackbar();
        const [handleHideItem] = useMutation(
          gql`
            mutation HideStockItem($id: ID!) {
              hideItem(id: $id) {
                id
              }
            }
          `,
          {
            refetchQueries: [
              {
                query: GET_STOCK,
                variables: { type: "restSheet", articleType: "sheet" },
              },
            ],
          }
        );
        return (
          <MenuItem
            disabled={item.initial + item.in - item.out > 0}
            {...other}
            onClick={async () => {
              try {
                await handleHideItem({ variables: { id: item.id } });
                onClose();
              } catch (e) {
                console.error(e);
                snackbar.showMessage(
                  t("Das Restblech konnte nicht ausgeblendet werden")
                );
              }
            }}
          >
            {t("Restblech ausblenden")}
          </MenuItem>
        );
      },
    },
  ],
  stockActionsConfig: {
    canCreateStockItems: false,
    canHideStockItems: false,
  },
  properties: {
    "restSheet.shape": {
      // only used for the log
      displayName: (_, { t }) => t("Restfläche"),
      formatValue: (value) => {
        const { width, length, cutouts } = JSON.parse(value);
        const area =
          width * length -
          cutouts.reduce(
            (sum, c) => sum + Math.abs(c.x1 - c.x2) * Math.abs(c.y1 - c.y2),
            0
          );
        const areaQm = area * 1e-6;
        return areaQm > 0.0005
          ? formatWithUnit(area * 1e-6, "m²")
          : "< 0,001 m²";
      },
    },
    "restSheet.cutouts": {
      hideInLog: true,
    },
    "restSheet.length": {
      displayName: (_, { t }) => t("Länge"),
      formatValue: (value) => formatWithUnit(parseFloat(value), "mm"),
    },
    "restSheet.width": {
      displayName: (_, { t }) => t("Breite"),
      formatValue: (value) => formatWithUnit(parseFloat(value), "mm"),
    },
    "restSheet.location": {
      displayName: (_, { t }) => t("Lagerort"),
    },
    "restSheet.chargeCarrier": {
      displayName: (_, { t }) => t("Ladungsträger"),
    },
  },
});
