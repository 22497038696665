import React, { useMemo } from "react";
import { gql } from "graphql.macro";
import Log from "../components/Log";
import { useQuery } from "react-apollo";
import { useArticleType } from "../../core/context/applicationContext";
import { useRegistry } from "../../plugins/registry";
import { addFragmentsToQuery } from "../../../util/graphqlUtil";

const GET_LOG = gql`
  query GetLog($type: ArticleType) {
    log(articleType: $type) {
      id
      date
      type
      employee
    }
  }
`;

// TODO pagination
export default function LogContainer() {
  const registry = useRegistry();
  const [type] = useArticleType();

  const query = useMemo(
    () =>
      addFragmentsToQuery(
        GET_LOG,
        Object.values(registry.logItemTypes).map((t) => t.logItemFragment)
      ),
    [registry]
  );

  const { data, error, loading } = useQuery(query, {
    variables: { type },
    fetchPolicy: "network-only",
    pollInterval: 15000,
  });
  return (
    <Log
      items={(data && data.log) || []}
      articleType={type}
      error={error}
      loading={loading}
    />
  );
}
