import React from "react";
import {
  Theme,
  withStyles,
  Popover,
  Button,
  createStyles,
} from "@material-ui/core";
import { Pencil } from "mdi-material-ui";
import UnitTextField from "../../../components/UnitTextField";
import { formatWithUnit } from "../../../util/numberFormat";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: "100%",
      maxWidth: 170,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflowX: "hidden",
      height: 18,
      display: "inline-block",
      verticalAlign: "bottom",
      "&:hover": {
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: `0 0 0 4px ${theme.palette.background.paper}`,
        "& $icon": {
          display: "inline",
        },
      },
    },
    icon: {
      display: "none",
      fontSize: "18px",
      color: theme.palette.text.secondary,
      position: "relative",
      float: "right",
      zIndex: 1,
      background: theme.palette.background.paper,
      paddingLeft: 4,
      width: 22,
      marginLeft: -22,
    },
    popover: {
      padding: 8,
      display: "flex",
      flexDirection: "column",
    },
    saveButton: {
      float: "right",
    },
  });

export interface NumberButtonProps {
  value: number;
  title: string;
  unit?: string;
  fractionalDigits?: number;
  validate?: (value: string | number) => boolean | undefined;
  onSave: (value: number) => void;
  t: (key: string) => string;
}

const NumberButton: React.FC<NumberButtonProps & { classes: any }> = ({
  value,
  title,
  unit,
  fractionalDigits,
  validate,
  classes,
  onSave,
  t,
}) => {
  const [valueInput, setValueInput] = React.useState<string | number>("");
  const [anchorEl, setAnchorEl] = React.useState();

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.target);
    setValueInput(value);
  };

  const handleClickPopup = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClose = () => setAnchorEl(undefined);

  const handleSave = () => {
    onSave(parseFloat(valueInput.toString()));
    handleClose();
  };

  const InputProps = {
    value: valueInput,
    onChange: (e: any) => setValueInput(e.target.value),
  };

  const getText = () => {
    if (value) {
      return formatWithUnit(value, unit, fractionalDigits);
    }

    return "";
  };

  const isValid = validate?.(valueInput) ?? true;

  return (
    <React.Fragment>
      <span className={classes.root} onClick={handleClick}>
        <Pencil className={classes.icon} />
        {getText()}
      </span>
      <Popover
        open={anchorEl != null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ paper: classes.popover }}
        onClose={handleClose}
        onClick={handleClickPopup}
      >
        <UnitTextField
          label={title}
          margin="dense"
          variant="outlined"
          fullWidth
          type="number"
          unit={unit}
          error={!isValid}
          InputProps={InputProps}
        />
        <div className={classes.row}>
          <Button
            variant="text"
            color="primary"
            className={classes.saveButton}
            onClick={handleSave}
            disabled={!isValid}
          >
            {t("Speichern")}
          </Button>
        </div>
      </Popover>
    </React.Fragment>
  );
};

export default withStyles(styles)(NumberButton);
