import React from "react";
import PropTypes from "prop-types";
import { withStyles, TextField } from "@material-ui/core";
import {
  TagTextOutline,
  Buffer,
  RulerSquare,
  NoteOutline,
} from "mdi-material-ui";
import UnitTextField from "../../../../components/UnitTextField";
import InitialStockSelector from "../../../articles/components/InitialStockSelector";
import { useTranslation } from "react-i18next";
import CustomFieldsSection from "../../../articles/components/CustomFieldsSection";

const styles = (theme) => ({
  root: {},
  fieldset: {
    display: "flex",
    "& > div": {
      flex: 1,
    },
  },
  icon: {
    marginTop: 20,
    marginRight: 16,
    color: theme.palette.action.active,
  },
  row: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: 16,
    },
  },
  multiline: {
    "& > div": {
      padding: "24px 12px 6px",
    },
  },
});

function ArticleForm({
  classes,
  inputProps,
  form,
  variant = "new",
  noteColumnTitles,
  customFields,
}) {
  const { t } = useTranslation();

  const customMaterialField = customFields.find(
    ({ asField }) => asField === "article.material"
  );

  return (
    <div className={classes.root}>
      <div className={classes.fieldset}>
        <TagTextOutline className={classes.icon} />
        <div>
          <TextField
            label={t("Artikelnummer")}
            margin="dense"
            variant="filled"
            fullWidth
            autoFocus
            {...inputProps("sku")}
          />
          <TextField
            label={t("Bezeichnung")}
            className={classes.textField}
            margin="dense"
            variant="filled"
            fullWidth
            {...inputProps("name")}
          />
          {customMaterialField ? (
            <customMaterialField.CustomField
              margin="dense"
              variant="filled"
              fullWidth
              {...inputProps(
                variant === "new" ? "longGood.material" : "details.material"
              )}
            />
          ) : (
            <TextField
              label={t("Werkstoff")}
              margin="dense"
              variant="filled"
              fullWidth
              {...inputProps(
                variant === "new" ? "longGood.material" : "details.material"
              )}
            />
          )}
        </div>
      </div>
      <div className={classes.fieldset}>
        <RulerSquare className={classes.icon} />
        <div>
          <div className={classes.row}>
            <UnitTextField
              label={t("Spezifisches Gewicht")}
              margin="dense"
              variant="filled"
              fullWidth
              unit="kg/m"
              type="number"
              {...inputProps(
                variant === "new"
                  ? "longGood.specificWeight"
                  : "details.specificWeight"
              )}
            />
            <UnitTextField
              label={t("Länge pro Stück")}
              margin="dense"
              variant="filled"
              fullWidth
              unit="m"
              type="number"
              {...inputProps(
                variant === "new" ? "longGood.length" : "details.length"
              )}
            />
          </div>
        </div>
      </div>
      <div className={classes.fieldset}>
        <Buffer className={classes.icon} />
        <div>
          {variant === "new" && (
            <InitialStockSelector
              value={form.values.initialStock}
              onChange={(initialStock) => {
                form.setValues({ initialStock });
              }}
              unit={t("Stk.")}
              articleType="longGood"
            />
          )}
          <UnitTextField
            label={t("Meldebestand")}
            margin="dense"
            variant="filled"
            fullWidth
            unit={t("Stk.")}
            type="number"
            {...inputProps("minimumStock")}
          />
        </div>
      </div>
      <CustomFieldsSection
        classes={{ fieldset: classes.fieldset, icon: classes.icon }}
        customFields={customFields}
        createInputProps={inputProps}
      />
      {variant === "new" && (
        <div className={classes.fieldset}>
          <NoteOutline className={classes.icon} />
          <div>
            {noteColumnTitles.map((title, i) => (
              <TextField
                key={`note${i}`}
                label={title}
                margin="dense"
                variant="filled"
                fullWidth
                multiline
                className={classes.multiline}
                {...inputProps(i === 0 ? "note" : `note${i + 1}`)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

ArticleForm.propTypes = {
  variant: PropTypes.oneOf(["new", "edit"]),
  noteColumnTitles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default withStyles(styles)(ArticleForm);
