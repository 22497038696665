import React, { useCallback } from "react";

export default function useLocalStorageState(
  key: string,
  defaultValue: string | null = null
): [
  string | null,
  (newValue: string | ((oldValue: string) => string)) => void
] {
  const [value, setValue] = React.useState(
    localStorage.getItem(key) || defaultValue
  );
  const setLocalStorageValue = useCallback(
    (valueOrUpdateFn) => {
      const newValue =
        typeof valueOrUpdateFn === "function"
          ? valueOrUpdateFn(value)
          : valueOrUpdateFn;
      setValue(newValue);
      if (newValue == null) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, newValue);
      }
    },
    [key, value]
  );
  return [value, setLocalStorageValue];
}

export function useBooleanLocalStorageState(
  key: string,
  defaultValue: boolean | null = null
): [boolean, (newValue: boolean | ((oldValue: boolean) => boolean)) => void] {
  const [value, setValue] = useLocalStorageState(key, defaultValue?.toString());
  const setBooleanValue = useCallback(
    (value) => {
      if (typeof value === "function") {
        setValue((oldValue) => value(oldValue?.toString() === "true"));
      } else {
        setValue(value);
      }
    },
    [setValue]
  );
  return [value?.toString() === "true", setBooleanValue];
}
