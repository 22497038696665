export function addFragmentsToQuery(query, fragments) {
  const selections = fragments.map((fragment) => ({
    kind: "InlineFragment",
    directives: [],
    selectionSet: fragment.definitions[0].selectionSet,
    typeCondition: fragment.definitions[0].typeCondition,
  }));
  return {
    ...query,
    definitions: [
      {
        ...query.definitions[0],
        selectionSet: {
          ...query.definitions[0].selectionSet,
          selections: query.definitions[0].selectionSet.selections.map(
            (selection) => ({
              ...selection,
              selectionSet: selection.selectionSet
                ? {
                    ...selection.selectionSet,
                    selections: [
                      ...selection.selectionSet.selections,
                      ...selections,
                    ],
                  }
                : undefined,
            })
          ),
        },
      },
      ...query.definitions.slice(1),
    ],
  };
}
