import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { gql } from "graphql.macro";
import StockActionFormTemplate from "./StockActionFormTemplate";
import { useRegistry } from "../plugins/registry";
import useCustomFields from "../customFields/useCustomFields";
import { useFeatures } from "../core/context/applicationContext";

const RESERVE_STOCK = gql`
  mutation ReserveStock(
    $stockItemId: ID!
    $amount: Float!
    $employee: String
    $note: String
    $customFields: [CustomFieldValueInput!]
  ) {
    reserveStock(
      stockItemId: $stockItemId
      reserve: {
        amount: $amount
        employee: $employee
        note: $note
        customFields: $customFields
      }
    ) {
      id
      reserved
      stockItem {
        id
        reserved
      }
    }
  }
`;

export const ReserveStockForm = (props) => {
  const { t } = useTranslation();
  const registry = useRegistry();

  const getDeltaLines = useCallback(
    (delta) => [
      {
        label: t("stockReservation:Aktuell reserviert"),
        value: props.item.reserved,
      },
      {
        label: t("stockReservation:Neuer reservierter Bestand"),
        value: props.item.reserved + delta,
        showIf: (value) => value >= 0,
      },
      {
        label: t("stockReservation:Neuer Planbestand"),
        value:
          props.item.initial -
          props.item.out +
          props.item.in +
          props.item.ordered -
          (props.item.reserved + delta),
      },
    ],
    [t, props.item]
  );

  const canSubmit = useCallback((delta) => delta > 0, []);

  const customFieldDefinitions = useCustomFields({
    // there is only one reservations table for all stock item types, so use the root stock item type, ie. the article type (SKL-457)
    stockReservationType: props.item.article.type,
  });

  const customFields = useMemo(() => {
    return customFieldDefinitions.map((field) => {
      return {
        CustomField: registry.customFieldType[field.type].getInputField(field, {
          t,
        }),
        customFieldId: field.id,
        asField: field.asField,
        defaultValue: field.defaultValue,
        target: "stockReservationItem",
      };
    });
  }, [customFieldDefinitions, registry.customFieldType, t]);

  return (
    <StockActionFormTemplate
      {...props}
      actionLabel={t("stockReservation:Bestand reservieren")}
      submitQuery={RESERVE_STOCK}
      getDeltaLines={getDeltaLines}
      canSubmit={canSubmit}
      customFields={customFields}
      successMessage={t("stockReservation:Der Bestand wurde reserviert.")}
      errorMessage={t(
        "stockReservation:Der Bestand konnte nicht reserviert werden."
      )}
    />
  );
};

const CONSUME_RESERVED_STOCK = gql`
  mutation ConsumeReservedStock(
    $reservationId: ID!
    $amount: Float!
    $employee: String
    $note: String
  ) {
    consumeReservedStock(
      reservationId: $reservationId
      consume: { amount: $amount, employee: $employee, note: $note }
    ) {
      id
      reserved
      stockItem {
        id
        initial
        in
        out
        reserved
      }
    }
  }
`;

export const ConsumeReservedStockForm = (props) => {
  const features = useFeatures();
  const { t } = useTranslation();

  const canConsumeMoreThanReserved =
    !!features.stockReservation?.consumeMoreThanReserved;

  const getDeltaLines = useCallback(
    (delta) =>
      [
        {
          label: t("stockReservation:Aktuell reserviert"),
          value: props.item.reserved,
        },
        {
          label: t("stockReservation:Neuer reservierter Bestand"),
          value: canConsumeMoreThanReserved
            ? Math.max(0, props.item.reserved - delta)
            : props.item.reserved - delta,
          showIf: (value) => value >= 0,
        },
        canConsumeMoreThanReserved && {
          label: t("stockReservation:Zusätzliche Entnahme"),
          value: delta - props.item.reserved,
          showIf: (value) => value >= 0,
        },
        {
          label: t("stockReservation:Neuer realer Bestand"),
          value: props.item.initial - props.item.out + props.item.in - delta,
        },
      ].filter((item) => !!item),
    [t, props.item, canConsumeMoreThanReserved]
  );

  const registry = useRegistry();
  const { stockInfo } = registry.stockItemTypes[props.item.type];
  const getDelta = useCallback(
    (initialDelta) =>
      stockInfo.actualToDelta
        ? stockInfo.actualToDelta(initialDelta, props.item.article)
        : initialDelta,
    [props.item.article, stockInfo]
  );

  const canSubmit = useCallback(
    (delta) =>
      delta > 0 &&
      (delta <= getDelta(props.item.reserved) || canConsumeMoreThanReserved),
    [canConsumeMoreThanReserved, props.item.reserved, getDelta]
  );

  return (
    <StockActionFormTemplate
      {...props}
      actionLabel={t("stockReservation:Reservierten Bestand entnehmen")}
      submitQuery={CONSUME_RESERVED_STOCK}
      getDeltaLines={getDeltaLines}
      canSubmit={canSubmit}
      customFields={undefined}
      successMessage={t(
        "stockReservation:Der reservierte Bestand wurde entnommen."
      )}
      errorMessage={t(
        "stockReservation:Der reservierte Bestand konnte nicht entnommen werden."
      )}
      initialDelta={getDelta(props.item.reserved)}
    />
  );
};

const RELEASE_RESERVED_STOCK = gql`
  mutation ReleaseReservedStock(
    $reservationId: ID!
    $amount: Float!
    $employee: String
    $note: String
  ) {
    releaseReservedStock(
      reservationId: $reservationId
      release: { amount: $amount, employee: $employee, note: $note }
    ) {
      id
      reserved
      stockItem {
        id
        initial
        in
        out
        reserved
      }
    }
  }
`;

export const ReleaseReservedStockForm = (props) => {
  const { t } = useTranslation();
  const getDeltaLines = useCallback(
    (delta) => [
      {
        label: t("stockReservation:Aktuell reserviert"),
        value: props.item.reserved,
      },
      {
        label: t("stockReservation:Neuer reservierter Bestand"),
        value: props.item.reserved - delta,
        showIf: (value) => value >= 0,
      },
    ],
    [t, props.item]
  );

  const registry = useRegistry();
  const { stockInfo } = registry.stockItemTypes[props.item.type];
  const getDelta = useCallback(
    (initialDelta) =>
      stockInfo.actualToDelta
        ? stockInfo.actualToDelta(initialDelta, props.item.article)
        : initialDelta,
    [props.item.article, stockInfo]
  );

  const canSubmit = useCallback(
    (delta) => delta > 0 && delta <= getDelta(props.item.reserved),
    [props.item.reserved, getDelta]
  );

  return (
    <StockActionFormTemplate
      {...props}
      actionLabel={t("stockReservation:Reservierung aufheben")}
      submitQuery={RELEASE_RESERVED_STOCK}
      getDeltaLines={getDeltaLines}
      canSubmit={canSubmit}
      customFields={undefined}
      successMessage={t(
        "stockReservation:Die Reservierung wurde aktualisiert."
      )}
      errorMessage={t(
        "stockReservation:Die Reservierung konnte nicht aktualisiert werden."
      )}
      initialDelta={getDelta(props.item.reserved)}
    />
  );
};
