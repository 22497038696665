import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { isJsonObject } from "../../../util/json";
import {
  ArticleType,
  StockItemType,
  useRegistry,
} from "../../plugins/registry";

export interface FieldChangesProps {
  changedFields: Array<{
    field: string;
    oldValue?: string;
    newValue?: string;
  }>;
  type?: StockItemType | ArticleType;
  unformatted?: boolean;
}

export default function FieldChanges({
  changedFields,
  type,
  unformatted,
}: FieldChangesProps) {
  const { t } = useTranslation();
  const registry = useRegistry();

  const propertyName = useCallback(
    (key: string) =>
      type?.properties?.[key] != null
        ? type.properties[key].displayName(undefined, { t })
        : key,
    [t, type?.properties]
  );

  const formatValue = useCallback(
    (key: string, value: string) => {
      if (isJsonObject(value)) {
        // custom field
        const parsedValue = JSON.parse(value);
        const customFieldConfig =
          registry.customFieldType[parsedValue.customFieldType];
        if (customFieldConfig?.formatLogValue) {
          return customFieldConfig.formatLogValue(parsedValue);
        }
        return parsedValue.value ?? value;
      } else if (type?.properties) {
        return (
          type.properties[key]?.formatValue?.(
            value,
            "articleType" in type
              ? registry.articleTypes[type.articleType]
              : type,
            { t }
          ) ?? value
        );
      }
      return value;
    },
    [registry, type?.properties, type]
  );

  const Ins = unformatted ? "span" : "ins";
  const Del = unformatted ? "span" : "del";

  return (
    <>
      {changedFields
        .filter((field) => !(type?.properties?.[field.field] as any)?.hideInLog)
        .map((changedField) => (
          <React.Fragment key={changedField.field}>
            {propertyName(changedField.field)}:{" "}
            <Del>
              {changedField.oldValue != null &&
                formatValue(changedField.field, changedField.oldValue)}
            </Del>{" "}
            <Ins>
              {changedField.newValue != null &&
                formatValue(changedField.field, changedField.newValue)}
            </Ins>
            <br />
          </React.Fragment>
        ))}
    </>
  );
}
