import React from "react";
import { withStyles, Typography, Button, TextField } from "@material-ui/core";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useMutation } from "react-apollo";
import { gql } from "graphql.macro";
import { useRegistry } from "../../plugins/registry";
import { useEmployee } from "../../core/context/applicationContext";
import { articleFragment, GET_STOCK } from "./Stock";
import ComponentExtensionPoint from "../../plugins/ComponentExtensionPoint";
import DefaultTemporaryLocation from "../components/DefaultTemporaryLocation";
import { useTranslation } from "react-i18next";

const styles = {
  fields: {
    maxWidth: 420,
    margin: "0 0 4px",
    display: "flex",
    "& > *": {
      flex: 1,
    },
    "& > *:first-child:not(:last-child)": {
      marginRight: 8,
    },
    "& > *:last-child:not(:first-child)": {
      marginLeft: 8,
    },
  },
  noteField: {
    margin: "4px 0 16px",
    width: "100%",
    "& textarea": {
      minHeight: 20, // fix scroll bar showing if empty
    },
  },
  bottom: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  checkbox: { padding: "6px 6px 6px 12px" },
  submit: {
    flex: "none",
  },
};

function ChangeTemporaryLocationForm({ classes, item, customFields, onClose }) {
  const { t } = useTranslation();
  const registry = useRegistry();
  const [employee] = useEmployee();
  const snackbar = useSnackbar();
  const itemType = registry.stockItemTypes[item.type];

  const [updateItem] = useMutation(
    gql`
      mutation UpdateItem(
        $item: UpdateItemInput!
        $employee: String
        $note: String
      ) {
        updateItem(item: $item, employee: $employee, note: $note) {
          id
          article {
            ...StockArticle
          }
        }
      }
      ${articleFragment}
    `,
    {
      refetchQueries: () => [
        {
          query: GET_STOCK,
          variables: { type: item.type, articleType: item.article.type },
        },
      ],
    }
  );

  const [location, setLocation] = React.useState(item.temporaryLocation || "");
  const [compartment, setCompartment] = React.useState(
    item.temporaryCompartment || ""
  );
  const [note, setNote] = React.useState("");

  const handleRemoveTemporaryLocation = async () => {
    const oldItem = item;
    try {
      await updateItem({
        variables: {
          item: {
            id: item.id,
            temporaryLocation: "",
            temporaryCompartment: "",
          },
          employee,
          note,
        },
      });
      snackbar.showMessage(
        t("Der temporäre Lagerort wurde entfernt"),
        t("Rückgängig"),
        async () => {
          try {
            await updateItem({
              variables: {
                item: {
                  id: oldItem.id,
                  temporaryLocation: oldItem.temporaryLocation,
                  temporaryCompartment: oldItem.temporaryCompartment,
                },
                employee,
              },
            });
          } catch (e) {
            console.error(e);
            snackbar.showMessage(
              t(
                "Das Entfernen des temporären Lagerortes konnte nicht rückgängig gemacht werden"
              )
            );
          }
        }
      );
      onClose();
    } catch (e) {
      console.error(e);
      snackbar.showMessage(
        t("Der temporäre Lagerort konnte nicht entfernt werden")
      );
    }
  };

  const handleSubmit = async () => {
    const oldItem = item;
    try {
      await updateItem({
        variables: {
          item: {
            id: item.id,
            temporaryLocation: location,
            temporaryCompartment: compartment,
          },
          employee,
          note,
        },
      });
      snackbar.showMessage(
        t("Der temporäre Lagerort wurde aktualisiert"),
        t("Rückgängig"),
        async () => {
          try {
            await updateItem({
              variables: {
                item: {
                  id: oldItem.id,
                  temporaryLocation: oldItem.temporaryLocation,
                  temporaryCompartment: oldItem.temporaryCompartment,
                },
                employee,
              },
            });
          } catch (e) {
            console.error(e);
            snackbar.showMessage(
              t(
                "Das Bearbeiten des temporären Lagerortes konnte nicht rückgängig gemacht werden"
              )
            );
          }
        }
      );
      onClose();
    } catch (e) {
      console.error(e);
      snackbar.showMessage(
        t("Der temporäre Lagerort konnte nicht aktualisiert werden")
      );
    }
  };

  return (
    <React.Fragment>
      <Typography variant="body1">{t("Temporären Lagerort ändern")}</Typography>
      <Typography variant="caption">
        {itemType.displayName(1, { t })} &middot; {item.article.name} &middot;{" "}
        {item.sku ?? item.article.sku}
      </Typography>
      <div className={classes.fields}>
        <ComponentExtensionPoint
          name="temporaryLocationFormLocation"
          variant="single"
          innerProps={{
            variant: "standard",
            margin: "dense",
            value: { location, compartment },
            onChange: ({ location, compartment }) => {
              setLocation(location);
              setCompartment(compartment);
            },
            InputLabelProps: { shrink: true },
            customFields,
          }}
          DefaultComponent={DefaultTemporaryLocation}
        />
      </div>
      <TextField
        className={classes.noteField}
        label={t("Bemerkung")}
        InputLabelProps={{ shrink: true }}
        placeholder="(optional)"
        fullWidth
        multiline
        value={note}
        onChange={(e) => setNote(e.target.value)}
        margin="dense"
      />
      <div className={classes.bottom}>
        {((item.temporaryLocation || "") !== "" ||
          (item.temporaryCompartment || "") !== "") && (
          <Button
            variant="outlined"
            color="default"
            onClick={handleRemoveTemporaryLocation}
          >
            {t("Entfernen")}
          </Button>
        )}
        <div className={classes.flex} />
        <Button
          className={classes.submit}
          variant="outlined"
          color="primary"
          onClick={handleSubmit}
        >
          {t("Übernehmen")}
        </Button>
      </div>
    </React.Fragment>
  );
}

export default withStyles(styles)(ChangeTemporaryLocationForm);
