import React, { useState } from "react";
import {
  withStyles,
  Typography,
  Button,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import UnitTextField from "../../../components/UnitTextField";
import { useRegistry } from "../../plugins/registry";
import ShapeSelector from "./ShapeSelector";
import ComponentExtensionPoint from "../../plugins/ComponentExtensionPoint";
import DefaultArticleLocation from "../../articles/components/DefaultArticleLocation";
import { useTranslation } from "react-i18next";
import CustomFieldsSection from "../../stock/components/CustomFieldsSection";

const styles = (theme) => ({
  method: {
    margin: "4px 0 0 -8px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  fields: {
    maxWidth: 418,
    margin: "8px 0 16px",
    display: "flex",
    "& > *": {
      flex: 1,
    },
    "& > *:first-child:not(:last-child)": {
      marginRight: 8,
    },
    "& > *:last-child:not(:first-child)": {
      marginLeft: 8,
    },
  },
  noteField: {
    margin: "4px 0 16px",
    width: "100%",
    "& textarea": {
      minHeight: 20, // fix scroll bar showing if empty
    },
  },
  gridHeader: {
    display: "flex",
    marginBottom: 8,
    "& *:first-child": {
      flex: 1,
      alignSelf: "center",
    },
  },
  bottom: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    "& > *": {
      flex: 1,
    },
  },
  checkbox: { padding: "6px 6px 6px 12px" },
  submit: {
    flex: "none",
  },
});

function RestSheetForm({
  classes,
  item,
  customFields,
  onSubmit,
  showStockChangeBox = true,
  editing = false,
}) {
  const { t } = useTranslation();
  const registry = useRegistry();

  const [length, setLength] = React.useState(
    item.details?.length || item.article.details.length || 0
  );
  const [width, setWidth] = React.useState(
    item.details?.width || item.article.details.width || 0
  );
  const [cutouts, setCutouts] = React.useState(item.details?.cutouts || []);
  const [gridSize, setGridSize] = React.useState(100);
  const [compartment, setCompartment] = React.useState(item.compartment?.id);
  const [loadCarrier, setLoadCarrier] = React.useState(item.loadCarrier?.id);
  const [note, setNote] = React.useState("");

  const currentStock = item.initial + item.in - item.out;
  const [updateStock, setUpdateStock] = React.useState(currentStock > 0);

  const [customFieldValues, setCustomFieldValues] = useState([]);

  const handleSelectInput = (e) => e.target.select();

  const handleSubmit = () => {
    onSubmit({
      articleId: item.article.id,
      width: parseFloat(width),
      length: parseFloat(length),
      cutouts,
      initialStock: 1,
      loadCarrier,
      compartment,
      updateStock,
      note,
      customFields: customFieldValues,
    });
  };

  const itemType = registry.stockItemTypes[item.type];

  return (
    <React.Fragment>
      <Typography variant="body1">
        {editing ? t("Restblech bearbeiten") : t("Restblech anlegen")}
      </Typography>
      <Typography variant="caption">
        {itemType.displayName(1, { t })} &middot; {item.article.name} &middot;{" "}
        {item.sku}
      </Typography>
      <div className={classes.fields}>
        <UnitTextField
          type="number"
          label={t("Länge")}
          autoFocus
          InputLabelProps={{ shrink: true }}
          unit="mm"
          value={length}
          onChange={(e) => setLength(e.target.value)}
          onFocus={handleSelectInput}
        />
        <UnitTextField
          type="number"
          label={t("Breite")}
          InputLabelProps={{ shrink: true }}
          unit="mm"
          value={width}
          onChange={(e) => setWidth(e.target.value)}
          onFocus={handleSelectInput}
        />
      </div>
      <div className={classes.gridHeader}>
        <Typography variant="body1">{t("Ausschnitt")}</Typography>
        <Select
          value={gridSize}
          onChange={(e) => setGridSize(e.target.value)}
          renderValue={(value) =>
            t("translation:Rastergröße {{size}} mm", {
              size: value.toLocaleString(),
            })
          }
          disableUnderline
        >
          <MenuItem value={10}>10 mm</MenuItem>
          <MenuItem value={50}>50 mm</MenuItem>
          <MenuItem value={100}>100 mm</MenuItem>
          <MenuItem value={500}>500 mm</MenuItem>
        </Select>
      </div>
      <ShapeSelector
        width={418}
        height={418}
        grid={gridSize}
        sheetWidth={parseFloat(width)}
        sheetLength={parseFloat(length)}
        value={cutouts}
        onChange={setCutouts}
      />
      <div className={classes.fields}>
        <ComponentExtensionPoint
          name="articleFormLocation"
          variant="single"
          innerProps={{
            variant: "standard",
            margin: "normal",
            value: { compartment, loadCarrier },
            onChange: ({ compartment, loadCarrier }) => {
              setCompartment(compartment);
              setLoadCarrier(loadCarrier);
            },
            InputLabelProps: { shrink: true },
          }}
          DefaultComponent={DefaultArticleLocation}
        />
      </div>
      <TextField
        className={classes.noteField}
        label={t("Bemerkung")}
        InputLabelProps={{ shrink: true }}
        placeholder={t("(optional)")}
        fullWidth
        multiline
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
      <CustomFieldsSection
        values={customFieldValues}
        onChange={setCustomFieldValues}
        stockItemType={item.type}
        customFields={customFields}
      />
      <div className={classes.bottom}>
        {showStockChangeBox && (
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                onChange={(e) => setUpdateStock(e.target.checked)}
                checked={updateStock && currentStock > 0}
                color="primary"
              />
            }
            label={t("Bestand verändern (-1 Stk.)")}
            disabled={currentStock === 0}
          />
        )}
        <Button
          className={classes.submit}
          variant="outlined"
          color="primary"
          disabled={isNaN(width) || isNaN(length) || width <= 0 || length <= 0}
          onClick={handleSubmit}
        >
          {editing ? t("Restblech bearbeiten") : t("Restblech anlegen")}
        </Button>
      </div>
    </React.Fragment>
  );
}

export default withStyles(styles)(RestSheetForm);
