import React from "react";
import { TextFieldProps } from "@material-ui/core/TextField";
import UnitTextField from "../../../components/UnitTextField";

interface Props {
  name: string;
  unit: string;
  range?: { min?: number; max?: number };
  InputProps: any;
  margin?: TextFieldProps["margin"];
  variant?: TextFieldProps["variant"];
}

export default function NumberCustomFieldInput({
  name,
  unit,
  range,
  InputProps,
  margin,
  variant,
}: Props) {
  const valueInput = InputProps.value;
  const isValid =
    valueInput === "" ||
    ((range?.min == null || parseFloat(valueInput.toString()) >= range.min) &&
      (range?.max == null || parseFloat(valueInput.toString()) <= range.max));

  return (
    <UnitTextField
      label={name}
      margin={margin}
      variant={variant}
      fullWidth
      type="number"
      unit={unit}
      error={!isValid}
      InputProps={{ ...InputProps, value: InputProps.value ?? "" }}
    />
  );
}
