import React from "react";
import { gql } from "graphql.macro";
import { addLogItemType } from "../plugins/registry";
import FieldChanges from "../log/components/FieldChanges";

addLogItemType({
  id: "stockChange",
  logItemFragment: gql`
    fragment StockChangeLogItemData on StockChangeLogItem {
      note
      stockItem {
        id
        sku
        type
      }
      change {
        delta
        type
      }
      article {
        id
        sku
        type
        name
      }
      location
      compartment
      loadCarrier
      changedFields {
        field
        oldValue
        newValue
      }
    }
  `,
  sku: (row) => row.stockItem.sku ?? row.article.sku,
  change: (row, { registry, t }) => {
    const stockItemType = registry.stockItemTypes[row.stockItem.type];
    const stockInfo = stockItemType.stockInfo;
    const delta = stockInfo.formatWithUnit(row.change.delta, row.article, {
      t,
    });
    return row.changedFields ? (
      <>
        Realer Bestand: {row.change.delta > 0 ? `+${delta}` : delta}
        <br />
        <FieldChanges
          changedFields={row.changedFields}
          type={stockItemType}
          unformatted
        />
      </>
    ) : (
      `${t("Realer Bestand")}: ${row.change.delta > 0 ? `+${delta}` : delta}`
    );
  },
  type: (row, { t }) =>
    row.change.type === "initial"
      ? t("Artikel hinzugefügt")
      : t("Bestandsänderung"),
});

addLogItemType({
  id: "stockItemChange",
  logItemFragment: gql`
    fragment StockItemChangeLogItemData on StockItemChangeLogItem {
      note
      stockItem {
        id
        sku
        type
        article {
          id
          sku
          type
          name
        }
      }
      changedFields {
        field
        oldValue
        newValue
      }
    }
  `,
  sku: (row) => row.stockItem.sku ?? row.stockItem.article.sku,
  change: (row, { t, registry }) => {
    const stockItemType = registry.stockItemTypes[row.stockItem.type];
    return (
      <FieldChanges changedFields={row.changedFields} type={stockItemType} />
    );
  },
  type: (row, { t }) => t("Bestand aktualisiert"),
});
