import React from "react";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function DefaultTemporaryLocation({
  value,
  onChange,
  margin = "dense",
  variant = "filled",
  InputLabelProps,
  customFields,
}) {
  const { t } = useTranslation();

  const customLocationField = customFields.find(
    ({ asField }) => asField === "stockItem.temporaryLocation"
  );
  const LocationField = customLocationField?.CustomField ?? TextField;

  const customCompartmentField = customFields.find(
    ({ asField }) => asField === "stockItem.temporaryCompartment"
  );
  const CompartmentField = customCompartmentField?.CustomField ?? TextField;

  return (
    <>
      <LocationField
        label={t("Lagerort")}
        margin={margin}
        variant={variant}
        InputLabelProps={InputLabelProps}
        fullWidth
        value={value?.location ?? ""}
        onChange={(e) =>
          onChange({
            location: e.target.value,
            compartment: value?.compartment ?? "",
          })
        }
        autoFocus
      />
      <CompartmentField
        label={t("Fach")}
        margin={margin}
        variant={variant}
        InputLabelProps={InputLabelProps}
        fullWidth
        value={value?.compartment ?? ""}
        onChange={(e) =>
          onChange({
            location: value?.location ?? "",
            compartment: e.target.value,
          })
        }
      />
    </>
  );
}
