import React from "react";
import {
  addCustomFieldType,
  CustomField,
  useRegistry,
} from "../../plugins/registry";
import { formatWithUnit } from "../../../util/numberFormat";
import NumberButton, { NumberButtonProps } from "./NumberButton";
import { numberColumn } from "../../../util/enhancedTableUtils";
import { aggregatedRowSum } from "../../stock/components/stockTable";
import { TFunction } from "i18next";
import NumberCustomFieldInput from "./NumberCustomFieldInput";
import { gql } from "graphql.macro";
import { getCustomField } from "../util";

interface NumberCustomField extends CustomField {
  decimals?: number;
  minimum?: number;
  maximum?: number;
  unit?: string;
  aggregateSum: boolean;
}

const buildRange = (field: NumberCustomField) => {
  return field.minimum || field.maximum
    ? { min: field.minimum, max: field.maximum }
    : undefined;
};

function ValidatedNumberButton({
  field,
  ...other
}: NumberButtonProps & { field: CustomField }) {
  const registry = useRegistry();
  return (
    <NumberButton
      {...other}
      validate={(value) =>
        registry.customFieldType[field.type].validate?.(value.toString(), field)
      }
    />
  );
}

const customNumberColumn = ({
  field,
  t,
}: {
  field: NumberCustomField;
  t: TFunction;
}) => {
  const id = `customField-${field.id}`;
  const title = field.name;
  const unit: { position: "start" | "end"; name: string } | undefined =
    field.unit ? { position: "end", name: field.unit } : undefined;
  const range = buildRange(field);
  const fractionalDigits = field.decimals ?? undefined;

  const getNonAggregatedNumber = (row: any) => {
    const fieldValue = getCustomField(row, field.id);
    return fieldValue ? parseFloat(fieldValue.value) : undefined;
  };

  const getNumber = field.aggregateSum
    ? aggregatedRowSum(getNonAggregatedNumber)
    : getNonAggregatedNumber;

  const content = (
    row: any,
    {
      cellContentParams: { handleSaveCustomField },
    }: {
      cellContentParams: {
        handleSaveCustomField: (
          e: any,
          customFieldId: string,
          stockItemId: string
        ) => void;
      };
    }
  ) => {
    if (row.__typename === "AggregatedStockItems") {
      return field.aggregateSum
        ? formatWithUnit(getNumber(row), unit?.name, fractionalDigits)
        : "";
    }

    return (
      <ValidatedNumberButton
        field={field}
        title={title}
        unit={field.unit}
        fractionalDigits={fractionalDigits}
        value={getNumber(row) ?? ""}
        onSave={(value: number) =>
          handleSaveCustomField(
            value?.toString() ?? null,
            field.id,
            field.stockReservationType || field.stockOrderType
              ? row.id
              : row.stockItem?.id ?? row.id
          )
        }
        t={t}
      />
    );
  };

  return numberColumn({
    id,
    title,
    content,
    getNumber,
    range,
    unit,
  });
};

addCustomFieldType<NumberCustomField>("number", {
  getColumn: (customField, { t }) => {
    return customNumberColumn({ field: customField, t });
  },
  getInputField: (customField) => {
    const unit = customField.unit ?? "";
    const range = buildRange(customField);
    return ({ margin, variant, label, ...InputProps }: any) => {
      return (
        <NumberCustomFieldInput
          name={label ?? customField.name}
          unit={unit}
          range={range}
          InputProps={InputProps}
          variant={variant}
          margin={margin}
        />
      );
    };
  },
  validate: (value, customField) => {
    const parsedValue = value === "" ? null : parseFloat(value);
    if (parsedValue == null) return true;
    if (customField.minimum != null && parsedValue < customField.minimum) {
      return false;
    }
    if (customField.maximum != null && parsedValue > customField.maximum) {
      return false;
    }
    return true;
  },
  formatLogValue: ({ value, unit, decimals }) => {
    const number = parseFloat(value ?? "");
    if (Number.isNaN(number)) {
      return "";
    }
    return formatWithUnit(number, unit, decimals);
  },
  customFieldFragment: gql`
    fragment NumberCustomFieldConfig on NumberCustomField {
      aggregateSum
      decimals
      maximum
      minimum
      unit
    }
  `,
});
