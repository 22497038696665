import {
  Collapse,
  Typography,
  withStyles,
  MenuItem,
  WithStyles,
  Theme,
} from "@material-ui/core";
import React from "react";
import { ChevronRight } from "mdi-material-ui";
import cx from "classnames";
import { buildFormInputProps } from "../util";
import { useBooleanLocalStorageState } from "../../../util/useLocalStorageState";
import { CustomFieldValue } from "../../customFields/util";
import { useTranslation } from "react-i18next";

interface Props {
  stockItemType: string;
  customFields: Array<{
    target: string;
    asField?: string;
    defaultValue?: string;
    customFieldId: string;
    CustomField: React.ElementType;
  }>;
  values: CustomFieldValue[];
  onChange: (values: CustomFieldValue[]) => void;
}

const styles = (theme: Theme) => ({
  customFieldHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "4px 8px",
    margin: "0 -8px",
    "& > p": {
      color: theme.palette.primary.main,
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  customFieldContent: {
    marginBottom: 8,
  },
  customFieldContentInner: {
    "& > *:first-child": {
      marginTop: 0,
    },
  },
  customFieldIcon: {
    fontSize: "18px",
    color: theme.palette.text.primary,
  },
  customFieldIconOpen: {
    transform: "rotate(90deg)",
  },
});

function CustomFieldsSection({
  classes,
  stockItemType,
  customFields,
  values,
  onChange,
}: Props & WithStyles<typeof styles>) {
  const { t } = useTranslation();

  const [customFieldsOpen, setCustomFieldsOpen] = useBooleanLocalStorageState(
    `showCustomFieldsCreateStock-${stockItemType}`,
    false
  );

  const applicableCustomFields = customFields.filter(
    ({ target, asField }) => target === "stockItem" && asField == null
  );

  if (applicableCustomFields.length === 0) {
    return null;
  }

  return (
    <div>
      <MenuItem
        onClick={() => setCustomFieldsOpen(!customFieldsOpen)}
        button
        disableGutters
        className={classes.customFieldHeader}
      >
        <Typography>{t("Weitere Felder")}</Typography>
        <ChevronRight
          className={cx(classes.customFieldIcon, {
            [classes.customFieldIconOpen]: customFieldsOpen,
          })}
        />
      </MenuItem>
      <Collapse
        in={customFieldsOpen}
        className={classes.customFieldContent}
        classes={{ wrapperInner: classes.customFieldContentInner }}
      >
        {applicableCustomFields.map(({ CustomField, customFieldId }) => {
          const InputProps = buildFormInputProps(
            onChange,
            values,
            customFieldId
          );
          return (
            <CustomField key={customFieldId} {...InputProps} margin="dense" />
          );
        })}
      </Collapse>
    </div>
  );
}

export default withStyles(styles)(CustomFieldsSection);
