import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { findDOMNode } from "react-dom";
import {
  FormControl,
  TextField,
  InputLabel,
  FilledInput,
  Input,
  OutlinedInput,
} from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField";
// @ts-ignore
import { SearchableSelect } from "@wa/werkstoff-select";
import { useTranslation } from "react-i18next";

interface Props {
  label: string;
  allowOtherValues: boolean;
  predefinedValues?: string[];
  InputProps: any;
  margin?: TextFieldProps["margin"];
  variant?: TextFieldProps["variant"];
}

const StringCustomFieldInput: React.FC<Props> = ({
  label,
  allowOtherValues,
  predefinedValues,
  InputProps,
  variant,
  margin,
}) => {
  const { t } = useTranslation();

  const items = useMemo(() => {
    if (!predefinedValues) {
      return [];
    }

    return predefinedValues.map((value) => ({
      value,
      label: value,
    }));
  }, [predefinedValues]);

  const inputLabelRef = useRef<InputLabel>(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useLayoutEffect(() => {
    // eslint-disable-next-line react/no-find-dom-node
    const labelElement = findDOMNode(inputLabelRef.current);
    if (labelElement instanceof HTMLElement) {
      setLabelWidth(labelElement.offsetWidth);
    }
  }, []);

  if ((predefinedValues?.length ?? 0) > 0 || !allowOtherValues) {
    const { value, onChange } = InputProps;
    return (
      <FormControl fullWidth margin={margin} variant={variant}>
        <InputLabel ref={inputLabelRef}>{label}</InputLabel>
        <SearchableSelect
          value={value}
          input={
            variant === "filled" ? (
              <FilledInput />
            ) : variant === "outlined" ? (
              <OutlinedInput labelWidth={labelWidth} />
            ) : (
              <Input />
            )
          }
          items={items}
          noSelectionLabel={<em>{t("Keine Angabe")}</em>}
          freeSolo={allowOtherValues}
          searchTextFieldProps={{ placeholder: t("Suchen…") }}
          addItemLabel={(value: string) =>
            t('"{{value}}" hinzufügen', { value })
          }
          onChange={(value: string) => {
            if (typeof value === "string") {
              onChange({ target: { value } });
            } else {
              onChange({
                target: { value: value ?? "" },
              });
            }
          }}
          margin={margin}
          variant={variant}
        />
      </FormControl>
    );
  }

  return (
    <TextField
      label={label}
      variant={variant}
      margin={margin}
      fullWidth
      InputProps={{ ...InputProps, value: InputProps.value ?? "" }}
    />
  );
};

export default StringCustomFieldInput;
