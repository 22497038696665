import React, { useMemo } from "react";
import { useQuery, useMutation } from "react-apollo";
import { gql } from "graphql.macro";
import Articles from "../components/Articles";
import {
  useArticleType,
  useEmployee,
  useFeatures,
} from "../../core/context/applicationContext";
import { useRegistry } from "../../plugins/registry";
import { addFragmentsToQuery } from "../../../util/graphqlUtil";

const articleFragment = gql`
  fragment ArticlesArticle on Article {
    id
    name
    sku
    minimumStock
    type
    details {
      ... on LongGoodDetails {
        material
        specificWeight
        length
      }
      ... on SheetDetails {
        material
        specificWeight
        length
        width
        area
        thickness
      }
      ... on BreakBulkDetails {
        weight
      }
      ... on CoilDetails {
        material
        length
        width
        thickness
        weight
      }
    }
    customFields {
      customFieldId
      value
    }
    note {
      text
      lastUpdated
    }
    note2 {
      text
      lastUpdated
    }
    note3 {
      text
      lastUpdated
    }
  }
`;

const GET_ARTICLES = gql`
  query GetArticles($archived: Boolean, $articleType: ArticleType!) {
    articles(archived: $archived, type: $articleType) {
      ...ArticlesArticle
    }
    articleNoteColumns(articleType: $articleType)
    articleSkuGenerator(type: $articleType) {
      format
    }
  }
  ${articleFragment}
`;

const ADD_ARTICLE = gql`
  mutation CreateArticle(
    $article: CreateArticleInput!
    $initialStock: [InitialStockInput!]!
    $employee: String
  ) {
    createArticle(
      article: $article
      initialStock: $initialStock
      employee: $employee
    ) {
      ...ArticlesArticle
    }
  }
  ${articleFragment}
`;

const UPDATE_ARTICLE = gql`
  mutation UpdateArticle(
    $articleId: ID!
    $update: UpdateArticleInput!
    $employee: String
  ) {
    updatedArticle: updateArticle(
      id: $articleId
      update: $update
      employee: $employee
    ) {
      ...ArticlesArticle
    }
  }
  ${articleFragment}
`;

const UPDATE_ARTICLE_NOTE = gql`
  mutation UpdateArticleNote(
    $articleId: ID!
    $noteIndex: Int!
    $text: String!
    $employee: String
  ) {
    updatedArticle: updateArticleNote(
      articleId: $articleId
      noteIndex: $noteIndex
      text: $text
      employee: $employee
    ) {
      ...ArticlesArticle
    }
  }
  ${articleFragment}
`;

const UPDATE_ARTICLE_NOTE_COLUMN_TITLE = gql`
  mutation UpdateArticleNoteColumnTitle(
    $noteIndex: Int!
    $title: String!
    $articleType: ArticleType!
  ) {
    newTitle: setArticleNoteColumnTitle(
      articleType: $articleType
      index: $noteIndex
      title: $title
    ) {
      index
      title
    }
  }
`;

const ARCHIVE_ARTICLE = gql`
  mutation ArchiveArticle($id: ID!, $employee: String) {
    updatedArticle: archiveArticle(id: $id, employee: $employee) {
      ...ArticlesArticle
    }
  }
  ${articleFragment}
`;

const RESTORE_ARTICLE = gql`
  mutation RestoreArticle($id: ID!, $employee: String) {
    updatedArticle: restoreArticle(id: $id, employee: $employee) {
      ...ArticlesArticle
    }
  }
  ${articleFragment}
`;

const REMOVE_ARTICLE = gql`
  mutation RemoveArticle($id: ID!, $employee: String) {
    removedArticle: removeArticle(id: $id, employee: $employee) {
      id
    }
  }
`;

const IMPORT_ARTICLES = gql`
  mutation ImportArticles(
    $file: Upload!
    $employee: String
    $articleType: ArticleType
  ) {
    importArticles(file: $file, employee: $employee, type: $articleType) {
      id
    }
  }
`;

const GET_CUSTOM_FIELDS_FOR_ARTICLE = gql`
  query GetCustomFieldsForArticle($articleType: ArticleType) {
    customFields(articleType: $articleType) {
      id
      name
      type
      asField
      defaultValue
    }
  }
`;

export default function ArticlesContainer() {
  const registry = useRegistry();
  const [tab, setTab] = React.useState("active");
  const [employee] = useEmployee();
  const [articleType] = useArticleType();
  const features = useFeatures();

  //#region Mutations
  const [addArticle] = useMutation(ADD_ARTICLE, {
    update: (cache, { data: { createArticle } }) => {
      // try...catch is actually required, see https://github.com/apollographql/apollo-client/issues/1542
      const { articles: active } = cache.readQuery({
        query: GET_ARTICLES,
        variables: { archived: false, articleType },
      });
      cache.writeQuery({
        query: GET_ARTICLES,
        variables: { archived: false, articleType },
        data: { articles: [...active, createArticle] },
      });
    },
  });

  const [updateArticle] = useMutation(UPDATE_ARTICLE, {
    update: (cache, { data: { updatedArticle } }) => {
      try {
        const { articles: active } = cache.readQuery({
          query: GET_ARTICLES,
          variables: { archived: false, articleType },
        });
        cache.writeQuery({
          query: GET_ARTICLES,
          variables: { archived: false, articleType },
          data: {
            articles: active.map((article) =>
              article.id === updatedArticle.id ? updatedArticle : article
            ),
          },
        });
      } catch (e) {}

      try {
        const { articles: archive } = cache.readQuery({
          query: GET_ARTICLES,
          variables: { archived: true, articleType },
        });
        cache.writeQuery({
          query: GET_ARTICLES,
          variables: { archived: true, articleType },
          data: {
            articles: archive.map((article) =>
              article.id === updatedArticle.id ? updatedArticle : article
            ),
          },
        });
      } catch (e) {}
    },
  });

  const [updateArticleNote] = useMutation(UPDATE_ARTICLE_NOTE, {
    update: (cache, { data: { updatedArticle } }) => {
      try {
        const { articles: active } = cache.readQuery({
          query: GET_ARTICLES,
          variables: { archived: false, articleType },
        });
        cache.writeQuery({
          query: GET_ARTICLES,
          variables: { archived: false, articleType },
          data: {
            articles: active.map((article) =>
              article.id === updatedArticle.id ? updatedArticle : article
            ),
          },
        });
      } catch (e) {}

      try {
        const { articles: archive } = cache.readQuery({
          query: GET_ARTICLES,
          variables: { archived: true, articleType },
        });
        cache.writeQuery({
          query: GET_ARTICLES,
          variables: { archived: true, articleType },
          data: {
            articles: archive.map((article) =>
              article.id === updatedArticle.id ? updatedArticle : article
            ),
          },
        });
      } catch (e) {}
    },
  });

  const [updateArticleNoteColumnTitle] = useMutation(
    UPDATE_ARTICLE_NOTE_COLUMN_TITLE,
    {
      update: (cache, { data: { newTitle } }) => {
        try {
          const { articles, articleNoteColumns } = cache.readQuery({
            query: GET_ARTICLES,
            variables: { archived: false, articleType },
          });
          cache.writeQuery({
            query: GET_ARTICLES,
            variables: { archived: false, articleType },
            data: {
              articles,
              articleNoteColumns: articleNoteColumns.map((title, i) =>
                i === newTitle.index ? newTitle.title : title
              ),
            },
          });
        } catch (e) {}

        try {
          const { articles, articleNoteColumns } = cache.readQuery({
            query: GET_ARTICLES,
            variables: { archived: true, articleType },
          });
          cache.writeQuery({
            query: GET_ARTICLES,
            variables: { archived: false, articleType },
            data: {
              articles,
              articleNoteColumns: articleNoteColumns.map((title, i) =>
                i === newTitle.index ? newTitle.title : title
              ),
            },
          });
        } catch (e) {}
      },
    }
  );

  const [archiveArticle] = useMutation(ARCHIVE_ARTICLE, {
    update: (cache, { data: { updatedArticle } }) => {
      try {
        const { articles: active } = cache.readQuery({
          query: GET_ARTICLES,
          variables: { archived: false, articleType },
        });
        cache.writeQuery({
          query: GET_ARTICLES,
          variables: { archived: false, articleType },
          data: {
            articles: active.filter(
              (article) => article.id !== updatedArticle.id
            ),
          },
        });
      } catch (e) {}

      try {
        const { articles: archive } = cache.readQuery({
          query: GET_ARTICLES,
          variables: { archived: true, articleType },
        });
        cache.writeQuery({
          query: GET_ARTICLES,
          variables: { archived: true, articleType },
          data: {
            articles: [...archive, updatedArticle],
          },
        });
      } catch (e) {}
    },
  });

  const [restoreArticle] = useMutation(RESTORE_ARTICLE, {
    update: (cache, { data: { updatedArticle } }) => {
      try {
        const { articles: active } = cache.readQuery({
          query: GET_ARTICLES,
          variables: { archived: false, articleType },
        });
        cache.writeQuery({
          query: GET_ARTICLES,
          variables: { archived: false, articleType },
          data: {
            articles: [...active, updatedArticle],
          },
        });
      } catch (e) {}

      try {
        const { articles: archive } = cache.readQuery({
          query: GET_ARTICLES,
          variables: { archived: true, articleType },
        });
        cache.writeQuery({
          query: GET_ARTICLES,
          variables: { archived: true, articleType },
          data: {
            articles: archive.filter(
              (article) => article.id !== updatedArticle.id
            ),
          },
        });
      } catch (e) {}
    },
  });

  const [removeArticle] = useMutation(REMOVE_ARTICLE, {
    update: (cache, { data: { removedArticle } }) => {
      try {
        const { articles: active } = cache.readQuery({
          query: GET_ARTICLES,
          variables: { archived: false, articleType },
        });
        cache.writeQuery({
          query: GET_ARTICLES,
          variables: { archived: false, articleType },
          data: {
            articles: active.filter(
              (article) => article.id !== removedArticle.id
            ),
          },
        });
      } catch (e) {}

      try {
        const { articles: archive } = cache.readQuery({
          query: GET_ARTICLES,
          variables: { archived: true, articleType },
        });
        cache.writeQuery({
          query: GET_ARTICLES,
          variables: { archived: true, articleType },
          data: {
            articles: archive.filter(
              (article) => article.id !== removedArticle.id
            ),
          },
        });
      } catch (e) {}
    },
  });

  const [importArticles] = useMutation(IMPORT_ARTICLES, {
    refetchQueries: [
      { query: GET_ARTICLES, variables: { archived: false, articleType } },
    ],
  });
  //#endregion

  const customFieldsQuery = useMemo(
    () =>
      addFragmentsToQuery(
        GET_CUSTOM_FIELDS_FOR_ARTICLE,
        Object.values(registry.customFieldType).map(
          (field) => field.customFieldFragment
        )
      ),
    [registry]
  );

  const { data: dataCustomField, loading: loadingCustomField } = useQuery(
    customFieldsQuery,
    {
      variables: { articleType },
      fetchPolicy: "network-only",
      skip: articleType == null,
      pollInterval: 300000,
    }
  );

  const { data, error, loading } = useQuery(GET_ARTICLES, {
    variables: { archived: tab === "archive", articleType },
    fetchPolicy: "network-only",
    skip: articleType == null,
    pollInterval: 15000,
  });

  return (
    <Articles
      articles={(data && data.articles) || []}
      fields={(dataCustomField && dataCustomField.customFields) || []}
      error={error}
      loading={loading || loadingCustomField}
      articleNoteColumns={data && data.articleNoteColumns}
      isSkuRequired={data?.articleSkuGenerator == null}
      onUpdateArticleNote={(article, noteIndex, text) =>
        updateArticleNote({
          variables: {
            articleId: article.id,
            noteIndex,
            text,
            employee,
          },
        })
      }
      onUpdateArticleNoteColumnTitle={(noteIndex, title) =>
        updateArticleNoteColumnTitle({
          variables: {
            noteIndex,
            title,
            articleType,
          },
        })
      }
      onAddArticle={({ initialStock, ...article }) =>
        addArticle({
          variables: {
            article,
            initialStock,
            employee,
          },
        })
      }
      onEditArticle={(article, { details, ...updatedFields }) =>
        updateArticle({
          variables: {
            articleId: article.id,
            update: {
              [article.type]: details,
              ...updatedFields,
            },
            employee,
          },
        })
      }
      tab={tab}
      onChangeTab={setTab}
      type={articleType}
      features={features}
      onArchive={(id) => archiveArticle({ variables: { id, employee } })}
      onRestore={(id) => restoreArticle({ variables: { id, employee } })}
      onRemove={(id) => removeArticle({ variables: { id, employee } })}
      onImportArticles={async (file) => {
        const result = await importArticles({
          variables: { file, employee, articleType },
        });
        return result.data.importArticles;
      }}
    />
  );
}
