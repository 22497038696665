import { withStyles, WithStyles } from "@material-ui/core";
import { NoteOutline } from "mdi-material-ui";
import React from "react";
import { buildFieldInputProps } from "../../customFields/util";

interface Props {
  customFields: Array<{
    target: string;
    asField?: string;
    defaultValue?: string;
    customFieldId: string;
    CustomField: React.ElementType;
  }>;
  createInputProps: (field: string) => {
    value: any;
    onChange: (newValue: any) => void;
  };
}

const styles = {
  fieldset: {},
  icon: {},
};

function CustomFieldsSection({
  classes,
  customFields,
  createInputProps,
}: Props & WithStyles<typeof styles>) {
  const applicableCustomFields = customFields.filter(
    ({ target, asField }) => target === "article" && asField == null
  );

  if (applicableCustomFields.length === 0) {
    return null;
  }

  return (
    <div className={classes.fieldset}>
      <NoteOutline className={classes.icon} />
      <div>
        {applicableCustomFields.map(({ CustomField, customFieldId }) => {
          const InputProps = buildFieldInputProps(
            createInputProps,
            customFieldId
          );
          return (
            <CustomField
              key={customFieldId}
              {...InputProps}
              margin="dense"
              variant="filled"
            />
          );
        })}
      </div>
    </div>
  );
}

export default withStyles(styles)(CustomFieldsSection);
