import { gql } from "graphql.macro";
import { CustomField, Registry } from "../plugins/registry";
import { TFunction } from "i18next";

export const GET_CUSTOM_FIELDS_FOR_REST_STOCKTYPE = gql`
  query GetCustomFieldsForRestStocktype($stockItemType: StockType!) {
    customFields(stockItemType: $stockItemType) {
      id
      name
      type
      asField
      stockItemType
      ... on NumberCustomField {
        aggregateSum
        decimals
        minimum
        maximum
        unit
      }
      ... on StringCustomField {
        predefinedValues
        allowOtherValues
      }
    }
  }
`;

export const buildCustomFields = (
  registry: Registry,
  t: TFunction,
  customFieldDefinitions: { customFields: CustomField[] }
) => {
  if (!customFieldDefinitions) {
    return [];
  }

  const customFields = customFieldDefinitions.customFields;

  return customFields
    .filter((definition) => definition.stockItemType !== null)
    .map((definition) => {
      return {
        CustomField: registry.customFieldType[definition.type].getInputField(
          definition,
          { t }
        ),
        customFieldId: definition.id,
      };
    });
};
