import {
  InputAdornment,
  TextField,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField";
import React, { useCallback, useMemo } from "react";
import PopoverIconButton from "../../../components/PopoverIconButton";
import { Palette, SquareRounded } from "mdi-material-ui";
import { SketchPicker, ColorResult } from "react-color";

interface Props {
  label: string;
  InputProps: any;
  margin?: TextFieldProps["margin"];
  variant?: TextFieldProps["variant"];
}

const styles = createStyles({
  adornment: {
    width: 20,
    height: 20,
    borderRadius: 4,
    border: "1px solid rgba(0,0,0,0.54)",
  },
  colorInput: {
    marginRight: -8,
  },
});

const presetColors = [
  "#f28b82",
  "#f9bc04",
  "#fdf475",
  "#ccff90",
  "#a7ffeb",
  "#cbf0f8",
  "#aecbfa",
  "#d7aefb",
  "#fbcfe8",
  "#e6c9a8",
  "#e8eaed",
];

const ColorCustomFieldInput: React.FC<Props & WithStyles<typeof styles>> = ({
  classes,
  InputProps,
  label,
  margin,
  variant,
}) => {
  const onChange = InputProps.onChange;
  const handleChange = useCallback(
    (color: ColorResult) => {
      onChange({ target: { value: color.hex } });
    },
    [onChange]
  );

  const enhancedInputProps = useMemo(() => {
    return {
      ...InputProps,
      value: InputProps.value ?? "",
      endAdornment: (
        <InputAdornment position="end">
          <PopoverIconButton
            className={classes.colorInput}
            IconProps={
              InputProps.value
                ? { style: { color: `${InputProps.value || "#ffffff"}` } }
                : undefined
            }
            icon={InputProps.value ? SquareRounded : Palette}
            closeOnClick={false}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PopoverProps={undefined}
          >
            <SketchPicker
              disableAlpha
              presetColors={presetColors}
              color={InputProps.value}
              onChange={handleChange}
            />
          </PopoverIconButton>
        </InputAdornment>
      ),
    };
  }, [InputProps, handleChange, classes.colorInput]);

  return (
    <TextField
      fullWidth
      label={label}
      margin={margin}
      variant={variant}
      InputProps={enhancedInputProps}
    />
  );
};

export default withStyles(styles)(ColorCustomFieldInput);
