import React from "react";
import { addCustomFieldType, CustomField } from "../../plugins/registry";
import { compareNaturalBy } from "../../../util/compareNatural";
// @ts-ignore
import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import StringButton from "./StringButton";
import StringCustomFieldInput from "./StringCustomFieldInput";
import { gql } from "graphql.macro";
import { getCustomField } from "../util";

interface StringCustomField extends CustomField {
  predefinedValues?: string[];
  allowOtherValues: boolean;
}

const customStringColumn = ({ field }: { field: StringCustomField }) => {
  const id = `customField-${field.id}`;
  const title = field.name;
  const contentString = (row: any) => {
    return getCustomField(row, field.id)?.value;
  };

  const content = (
    row: any,
    {
      cellContentParams: { handleSaveCustomField },
    }: {
      cellContentParams: {
        handleSaveCustomField: (
          e: any,
          customFieldId: string,
          stockItemId: string
        ) => void;
      };
    }
  ) => {
    if (field.stockItemType && row.id.includes("aggregate")) {
      return contentString(row);
    }

    return (
      <StringButton
        title={title}
        allowOtherValues={field.allowOtherValues}
        predefinedValues={field.predefinedValues}
        value={contentString(row) ?? ""}
        onSave={(value: string) =>
          handleSaveCustomField(
            value,
            field.id,
            field.stockReservationType || field.stockOrderType
              ? row.id
              : row.stockItem?.id ?? row.id
          )
        }
      />
    );
  };

  return {
    id,
    title,
    content,
    contentString,
    tooltip: contentString,
    compare: compareNaturalBy(contentString),
    filter: {
      type: StringSearchFilter,
    },
    export: {
      // only used by the CSV export, not by the table
      getRawValue: contentString,
      title,
    },
  };
};

addCustomFieldType<StringCustomField>("string", {
  getColumn: (customField) => {
    const field = customField;
    return customStringColumn({ field });
  },
  getInputField: (customField) => {
    const field = customField;
    return ({ margin, variant, label, ...InputProps }: any) => {
      return (
        <StringCustomFieldInput
          label={label ?? field.name}
          allowOtherValues={field.allowOtherValues}
          predefinedValues={field.predefinedValues}
          InputProps={InputProps}
          variant={variant}
          margin={margin}
        />
      );
    };
  },
  customFieldFragment: gql`
    fragment StringCustomFieldConfig on StringCustomField {
      predefinedValues
      allowOtherValues
    }
  `,
});
