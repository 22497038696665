interface InputProps {
  value: string;
  onChange: (e: any) => void;
}

export const buildFieldInputProps = (
  inputProps: (field: string) => {
    value: any;
    onChange: (newValue: any) => void;
  },
  customFieldId: string
): InputProps => {
  const props = inputProps("customFields");
  const index = props.value.findIndex(
    (val: CustomFieldValue) => val.customFieldId === customFieldId
  );
  const value = index !== -1 ? props.value[index].value : "";
  return {
    value,
    onChange: (e) => {
      props.onChange({
        target: {
          value:
            index === -1
              ? [
                  ...props.value,
                  {
                    customFieldId: customFieldId,
                    value: String(e.target.value),
                  },
                ]
              : [
                  ...props.value.slice(0, index),
                  {
                    customFieldId: customFieldId,
                    value: String(e.target.value),
                  },
                  ...props.value.slice(index + 1, props.value.length),
                ],
        },
      });
    },
  };
};

export interface CustomFieldValue {
  customFieldId: string;
  value: string;
}

export function getCustomField(
  item: any,
  fieldId: string
): CustomFieldValue | undefined {
  for (const customFieldValue of iterateCustomFields(item)) {
    if (customFieldValue.customFieldId === fieldId) {
      return customFieldValue;
    }
  }
}

export function getAllCustomFields(item: any): CustomFieldValue[] {
  return [...iterateCustomFields(item)];
}

function* iterateCustomFields(
  item: any
): Generator<CustomFieldValue, undefined, undefined> {
  if (item.customFields) {
    yield* item.customFields;
  }
  if (item.stockItem?.customFields) {
    yield* item.stockItem.customFields;
  }
  if (item.article?.customFields) {
    yield* item.article.customFields;
  }
  if (item.stockOrder?.customFields) {
    yield* item.stockOrder.customFields;
  }
  if (item.stockReservation?.customFields) {
    yield* item.stockReservation.customFields;
  }
  return undefined;
}
