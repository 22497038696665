import React from "react";
import { addCustomFieldType, CustomField } from "../../plugins/registry";
import { compareNaturalBy } from "../../../util/compareNatural";
// @ts-ignore
import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import ColorCustomFieldInput from "./ColorCustomFieldInput";
import { gql } from "graphql.macro";
import { getCustomField } from "../util";

interface ColorCustomField extends CustomField {
  predefinedValues?: string[];
  allowOtherValues: boolean;
}

const customColorColumn = ({ field }: { field: ColorCustomField }) => {
  const id = `customField-${field.id}`;
  const title = field.name;
  const contentString = (row: any) => {
    return getCustomField(row, field.id)?.value;
  };

  return {
    id,
    title,
    content: (row: any) => {
      const color = contentString(row);
      return (
        <>
          <span
            style={
              color
                ? {
                    background: color,
                    width: 15,
                    height: 15,
                    borderRadius: 4,
                    border: "1px solid rgba(0,0,0,0.54)",
                    marginRight: 4,
                    display: "inline-block",
                    float: "left",
                  }
                : undefined
            }
          />
          {color}
        </>
      );
    },
    contentString,
    tooltip: contentString,
    compare: compareNaturalBy((row: any) => contentString(row)),
    filter: {
      type: StringSearchFilter,
    },
    export: {
      // only used by the CSV export, not by the table
      getRawValue: contentString,
      title,
    },
  };
};

addCustomFieldType<ColorCustomField>("color", {
  getColumn: (customField) => customColorColumn({ field: customField }),
  getInputField: (customField) => {
    const field = customField;
    return ({ margin, variant, label, ...InputProps }: any) => {
      return (
        <ColorCustomFieldInput
          label={label ?? field.name}
          InputProps={InputProps}
          variant={variant}
          margin={margin}
        />
      );
    };
  },
  customFieldFragment: gql`
    fragment ColorCustomFieldConfig on ColorCustomField {
      useAsTableRowColor
    }
  `,
});
