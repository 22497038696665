import React, {
  ChangeEvent,
  ComponentType,
  FocusEventHandler,
  ReactNode,
} from "react";
import { MenuItemProps } from "@material-ui/core/MenuItem";
import { formatWithUnit } from "../../util/numberFormat";
import { TFunction } from "i18next";
import { DocumentNode } from "graphql";
import { InputLabelProps } from "@material-ui/core/InputLabel";
import { CanParameters } from "@casl/ability";
import { FeatureToggles } from "../core/context/applicationContext";

const listeners = new Set<Function>();
const notify = () => listeners.forEach((l) => l());

export interface Registry {
  articleTypes: { [name: string]: ArticleType };
  articleTypeChangers: {
    [name: string]: Array<(articleType: ArticleType) => ArticleType>;
  };
  articleActions: { [name: string]: ArticleAction[]; _common: ArticleAction[] };
  getArticleActions(type: string, { t }: { t: TFunction }): ArticleAction[];
  articleToolbarActions: {
    [name: string]: ArticleToolbarAction[];
    _common: ArticleToolbarAction[];
  };
  getArticleToolbarActions(
    type: string,
    { t }: { t: TFunction }
  ): ArticleToolbarAction[];
  stockItemTypes: { [name: string]: StockItemType };
  stockItemTypeChangers: {
    [name: string]: Array<(stockItemType: StockItemType) => StockItemType>;
  };
  stockActions: { [name: string]: StockAction[]; _common: StockAction[] };
  getStockActions(type: string, { t }: { t: TFunction }): StockAction[];
  stockToolbarActions: {
    [name: string]: StockToolbarAction[];
    _common: StockToolbarAction[];
  };
  getStockToolbarActions(
    type: string,
    { t }: { t: TFunction }
  ): StockToolbarAction[];
  stockColumns: {
    [name: string]: Array<
      (options: { registry: Registry; type: string; t: TFunction }) => object[]
    >;
    _common: Array<
      (options: { registry: Registry; type: string; t: TFunction }) => object[]
    >;
  };
  aggregatedStockItemsFragments: {
    [name: string]: DocumentNode[];
    _common: DocumentNode[];
  };
  getStockTableConfig(type: string, { t }: { t: TFunction }): object[];
  pageActions: { [pageId: string]: PageAction[] };
  pageToolbarActions: { [pageId: string]: PageToolbarAction[] };
  appWrappers: Array<{ name: string; Component: ComponentType }>;
  __appWrapper: ComponentType | null;
  getAppWrapper: () => ComponentType;
  componentExtensionsPoints: {
    [extensionPoint: string]: Array<
      | ComponentType
      | ((InnerComponent: ComponentType, innerProps: object) => ComponentType)
    >;
  };
  menuItems: Array<MenuItem>;
  settingsMenuItems: Array<MenuItem>;
  pages: Array<Page>;
  logTableModifiers: {
    [name: string]: Array<(config: object[]) => object[]>;
    _common: Array<(config: object[]) => object[]>;
  };
  logItemTypes: Record<string, LogItemType>;
  getLogTableConfig(type: string, defaultConfig: object[]): object[];
  customFieldType: Record<string, CustomFieldType<any>>;
}

const registryContent: Registry = {
  articleTypes: {},
  articleTypeChangers: {},
  articleActions: { _common: [] },
  getArticleActions: (type: string) => [
    ...(registryContent.articleActions[type] || []),
    ...registryContent.articleActions._common,
  ],
  articleToolbarActions: { _common: [] },
  getArticleToolbarActions: (type: string) => [
    ...(registryContent.articleToolbarActions[type] || []),
    ...registryContent.articleToolbarActions._common,
  ],
  stockItemTypes: {},
  stockItemTypeChangers: {},
  stockActions: { _common: [] },
  getStockActions: (type: string) => [
    ...(registryContent.stockItemTypes[type].stockActions || []),
    ...(registryContent.stockActions[type] || []),
    ...registryContent.stockActions._common,
  ],
  stockToolbarActions: { _common: [] },
  getStockToolbarActions: (type: string) => [
    ...(registryContent.stockToolbarActions[type] || []),
    ...registryContent.stockToolbarActions._common,
  ],
  stockColumns: { _common: [] },
  aggregatedStockItemsFragments: { _common: [] },
  getStockTableConfig: (type: string, { t }: { t: TFunction }) => [
    ...registryContent.stockItemTypes[type].stockTableConfig({ t }),
    ...registryContent.stockColumns._common.flatMap((columns) =>
      columns({ registry: registryContent, type, t })
    ),
    ...(registryContent.stockColumns[type] || []).flatMap((columns) =>
      columns({ registry: registryContent, type, t })
    ),
  ],
  pageActions: {},
  pageToolbarActions: {},
  appWrappers: [],
  __appWrapper: null,
  getAppWrapper: () => {
    if (registryContent.__appWrapper != null) {
      return registryContent.__appWrapper;
    }

    let AppWrapper: ComponentType = (props: { children?: any }) =>
      props.children;
    for (const { name, Component } of registryContent.appWrappers) {
      const OldWrapper = AppWrapper;
      AppWrapper = (props) => (
        <Component>
          <OldWrapper {...props} />
        </Component>
      );
      AppWrapper.displayName = name;
    }
    registryContent.__appWrapper = AppWrapper;
    return AppWrapper;
  },
  componentExtensionsPoints: {},
  menuItems: [],
  settingsMenuItems: [],
  pages: [],
  logTableModifiers: { _common: [] },
  logItemTypes: {},
  getLogTableConfig: (type: string, defaultConfig: object[]) => {
    let config = defaultConfig;
    for (const modifier of registryContent.logTableModifiers._common) {
      config = modifier(config);
    }
    if (registryContent.logTableModifiers[type]) {
      for (const modifier of registryContent.logTableModifiers[type]) {
        config = modifier(config);
      }
    }
    return config;
  },
  customFieldType: {},
};

export interface FormField {
  defaultValue: any;
  error?: (value: any) => boolean;
  convert?: (value: any) => any;
}

export interface StockInfo {
  unit: ({ t }: { t: TFunction }) => string;
  unitName: ({ t }: { t: TFunction }) => string;
  deltaUnit?: ({ t }: { t: TFunction }) => string;
  deltaUnitName?: ({ t }: { t: TFunction }) => string;
  /**
   * Convert the delta from deltaUnit to delta, e.g. from pieces to meters.
   */
  deltaToActual?: (delta: number, article?: object) => number;
  /**
   * Convert the delta from delta to deltaUnit, e.g. from meters to pieces.
   */
  actualToDelta?: (delta: number, article?: object) => number;
  calculateNewStock: (
    currentStock: number,
    delta: number,
    item: object
  ) => number;
  calculateStock?: (
    initialStock: number,
    stockIn: number,
    stockOut: number
  ) => number;
  supportsDeltaByWeight: (article: object) => boolean;
  /**
   * Get the weight of the given stock (count, length, …) of the given item.
   */
  weightByStock: (item: object, currentStock: number) => number;
  formatWithUnit: (
    value: number,
    article: object | undefined,
    { t }: { t: TFunction }
  ) => string;
  allowDecimals: boolean;
  /** @deprecated not used anymore, the sku is calculated on the server-side */
  sku?: (item: object) => string;
  StockDeltaInputField?: React.ComponentType<{
    className: string;
    method: "unit" | "weight";
    label: string;
    autoFocus?: boolean;
    InputLabelProps?: InputLabelProps;
    unit: string;
    inputRef: any;
    value: string;
    onChange: (
      value: string,
      e?: ChangeEvent<
        HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
      >
    ) => void;
    onFocus: FocusEventHandler<
      HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
    >;
    item: any;
    article: any;
  }>;
}

export interface ArticleType {
  id: string;
  displayName: (count: number, { t }: { t: TFunction }) => string;
  stockInfo: StockInfo;
  articlesTableConfig: ({
    t,
    registry,
  }: {
    t: TFunction;
    registry: Registry;
  }) => any[]; // TODO
  stockTableConfig: ({ t }: { t: TFunction }) => any[]; // TODO
  ArticleForm: React.ComponentType<{
    inputProps: (field: string) => object;
    variant: "new" | "edit";
  }>;
  createArticleFormConfig: {
    [field: string]: FormField;
  };
  editArticleFormConfig: {
    [field: string]: FormField;
  };
  articleActions?: Array<Omit<ArticleAction, "type">>;
  stockActions?: Array<StockAction>;
  stockActionsConfig?: StockItemType["stockActionsConfig"];
  properties: {
    [field: string]: {
      displayName: (unknown: unknown, { t }: { t: TFunction }) => string;
      formatValue?: (
        value: string | number,
        articleType: ArticleType,
        { t }: { t: TFunction }
      ) => string;
    };
  };
}

export interface ArticleAction {
  label: string | (({ t }: { t: TFunction }) => string);
  Icon?: React.ComponentType<{ fontSize: string }>;
  MenuItem?: React.ComponentType<{
    article: object;
    onClose: () => void;
  }>;
  PopoverForm?: React.ComponentType<{
    article: object;
    onClose: () => void;
  }>;
}

export interface ArticleToolbarAction {
  label: string | (({ t }: { t: TFunction }) => string);
  Icon: React.ComponentType<{ fontSize: string }>;
  Component: React.ComponentType<{
    Button: React.ComponentType<{ onClick: () => void }>;
    articlesApi: {
      setSearchInput: (input: string) => void;
    };
  }>;
}

export interface StockItemType {
  id: string;
  displayName: (count: number, { t }: { t: TFunction }) => string;
  articleType: string;
  icon: React.ComponentType;
  stockInfo: StockInfo;
  stockTableConfig: ({ t }: { t: TFunction }) => any[]; // TODO
  stockActions?: Array<StockAction>;
  stockActionsConfig?: {
    canCreateStockItems?: boolean;
    canHideStockItems?: boolean;
  };
  properties?: {
    [field: string]: {
      displayName: (unknown: unknown, { t }: { t: TFunction }) => string;
      formatValue?: (
        value: string | number,
        articleType: ArticleType,
        { t }: { t: TFunction }
      ) => string;
      hideInLog?: boolean;
    };
  };
}

export interface StockAction {
  label: string | (({ t }: { t: TFunction }) => string);
  Icon?: React.ComponentType<{ fontSize: string }>;
  MenuItem?: React.ComponentType<{
    item: object;
    onClose: () => void;
  }>;
  PopoverForm?: React.ComponentType<{
    item: object;
    article: object;
    customFields: CustomFieldInput[];
    onClose: () => void;
  }>;
  showIf?: ({
    item,
    stockItemType,
    features,
  }: {
    item: object;
    stockItemType: StockItemType;
    features: object;
  }) => boolean;
}

export interface StockToolbarAction {
  label: string | (({ t }: { t: TFunction }) => string);
  Icon: React.ComponentType<{ fontSize: string }>;
  Component: React.ComponentType<{
    Button: React.ComponentType<{ onClick: () => void }>;
    stockApi: {
      setSearchInput: (input: string) => void;
    };
  }>;
}

export interface PageAction {
  label: string | (({ t }: { t: TFunction }) => string);
  Icon?: React.ComponentType<{ fontSize: string }>;
  MenuItem?: React.ComponentType<{
    item: object;
    onClose: () => void;
  }>;
  PopoverForm?: React.ComponentType<{
    item: object;
    article: object;
    customFields: CustomFieldInput[];
    onClose: () => void;
  }>;
}

export interface PageToolbarAction {
  label: string | (({ t }: { t: TFunction }) => string);
  Icon: React.ComponentType<{ fontSize: string }>;
  Component: React.ComponentType<{
    Button: React.ComponentType<{ onClick: () => void }>;
    pageApi?: any;
  }>;
}

export type ComponentExtensionPoint = "navigationDrawer" | "footerRight";

export type NestedComponentExtensionPoint = "articleTableRow" | "stockTableRow";

export type SingleComponentExtensionPoint =
  | "articleFormLocation"
  | "temporaryLocationFormLocation";

export interface MenuItem {
  name: string | (({ t }: { t: TFunction }) => string);
  icon: ReactNode;
  route?: string;
  tooltip?: string | (({ t }: { t: TFunction }) => string);
  Component?: ComponentType<MenuItemProps & { ["data-collapsed"]: boolean }>;
  order?: number;
  ifCan?: CanParameters<string>;
  hidden?: ({
    features,
    pages,
  }: {
    features: Partial<FeatureToggles>;
    pages: Page[];
  }) => boolean;
  disabled?: ({
    features,
    pages,
  }: {
    features: Partial<FeatureToggles>;
    pages: Page[];
  }) => boolean;
}

export interface Page {
  route: string;
  /** @deprecated use Component instead */
  component: ComponentType<{
    match?: string;
  }>;
  Component: ComponentType<{
    match?: string;
  }>;
}

export interface LogItemType<T = any> {
  id: string;
  logItemFragment: DocumentNode;
  sku: (
    logItem: T,
    { registry, t }: { registry: Registry; t: TFunction }
  ) => string;
  articleName?: (
    logItem: T,
    { registry, t }: { registry: Registry; t: TFunction }
  ) => string;
  change: (
    logItem: T,
    { registry, t }: { registry: Registry; t: TFunction }
  ) => ReactNode;
  type: (
    logItem: T,
    { registry, t }: { registry: Registry; t: TFunction }
  ) => string;
}

export function addArticleType(type: ArticleType) {
  const changers = registryContent.articleTypeChangers[type.id] || [];
  for (const changer of changers) {
    type = changer(type);
  }
  registryContent.articleTypes[type.id] = type;
  if (type.articleActions) {
    for (const action of type.articleActions) {
      addArticleAction({ articleType: type.id, ...action });
    }
  }
  addStockItemType({
    // every article type implicitly defines a stock item type
    id: type.id,
    articleType: type.id,
    displayName: type.displayName,
    stockInfo: type.stockInfo,
    icon: null!,
    stockTableConfig: type.stockTableConfig,
    stockActions: type.stockActions,
    stockActionsConfig: type.stockActionsConfig,
  });
  notify();

  if (process.env.NODE_ENV !== "production") {
    for (const column of type.articlesTableConfig({
      t: (x: string) => x,
      registry: registryContent,
    })) {
      if (
        !(
          column.contentString ||
          (column.export &&
            (column.export.exclude || column.export.getRawValue))
        )
      ) {
        console.warn(
          `The article table column ${column.id} of ${type.id} neither defines contentString nor export.getRawValue. CSV export might not work properly.`
        );
      }
    }
  }
}

export function changeArticleType(
  type: string,
  changer: (article: Partial<ArticleType>) => ArticleType
) {
  if (registryContent.articleTypeChangers[type]) {
    registryContent.articleTypeChangers[type].push(changer);
  } else {
    registryContent.articleTypeChangers[type] = [changer];
  }
  if (registryContent.stockItemTypes[type]) {
    registryContent.articleTypes[type] = changer(
      registryContent.articleTypes[type]
    );
  }
  notify();
}

export function addArticleAction({
  articleType,
  ...action
}: { articleType?: string } & ArticleAction) {
  if (articleType == null) {
    registryContent.articleActions._common.push(action);
  } else {
    if (registryContent.articleActions[articleType] == null) {
      registryContent.articleActions[articleType] = [action];
    } else {
      registryContent.articleActions[articleType].push(action);
    }
  }
  notify();
}

export function addArticleToolbarAction({
  articleType,
  ...action
}: { articleType?: string } & ArticleToolbarAction) {
  if (articleType == null) {
    registryContent.articleToolbarActions._common.push(action);
  } else {
    if (registryContent.articleToolbarActions[articleType] == null) {
      registryContent.articleToolbarActions[articleType] = [action];
    } else {
      registryContent.articleToolbarActions[articleType].push(action);
    }
  }
  notify();
}

export function addStockItemType(stockItem: StockItemType) {
  let stockItemType: StockItemType = {
    ...stockItem,
    properties: {
      ...stockItem.properties,
      temporaryLocation: {
        displayName: (_, { t }) => t("Temporärer Lagerort"),
      },
      temporaryCompartment: { displayName: (_, { t }) => t("Temporäres Fach") },
      loadCarrier: { displayName: (_, { t }) => t("Ladungsträger") },
      compartment: { displayName: (_, { t }) => t("Fach") },
    },
  };
  const changers = registryContent.stockItemTypeChangers[stockItem.id] || [];
  for (const changer of changers) {
    stockItemType = changer(stockItemType);
  }
  registryContent.stockItemTypes[stockItem.id] = stockItemType;
  notify();

  if (process.env.NODE_ENV !== "production") {
    for (const column of stockItem.stockTableConfig({ t: (x: string) => x })) {
      if (
        !(
          column.contentString ||
          (column.export &&
            (column.export.exclude || column.export.getRawValue))
        )
      ) {
        console.warn(
          `The stock table column ${column.id} of ${stockItem.id} neither defines contentString nor export.getRawValue. CSV export might not work properly.`
        );
      }
    }
  }
}

export function changeStockItemType(
  type: string,
  changer: (stockItem: Partial<StockItemType>) => StockItemType
) {
  if (registryContent.stockItemTypeChangers[type]) {
    registryContent.stockItemTypeChangers[type].push(changer);
  } else {
    registryContent.stockItemTypeChangers[type] = [changer];
  }
  if (registryContent.stockItemTypes[type]) {
    registryContent.stockItemTypes[type] = changer(
      registryContent.stockItemTypes[type]
    );
  }
  notify();
}

export function addStockAction({
  stockType,
  ...action
}: { stockType?: string } & StockAction) {
  if (stockType == null) {
    registryContent.stockActions._common.push(action);
  } else {
    if (registryContent.stockActions[stockType] == null) {
      registryContent.stockActions[stockType] = [action];
    } else {
      registryContent.stockActions[stockType].push(action);
    }
  }
  notify();
}

export function addStockToolbarAction({
  stockType,
  ...action
}: { stockType?: string } & StockToolbarAction) {
  if (stockType == null) {
    registryContent.stockToolbarActions._common.push(action);
  } else {
    if (registryContent.stockToolbarActions[stockType] == null) {
      registryContent.stockToolbarActions[stockType] = [action];
    } else {
      registryContent.stockToolbarActions[stockType].push(action);
    }
  }
  notify();
}

export function addStockTableColumns({
  stockType,
  columnFactory,
  aggregatedStockItemsFragment,
}: {
  stockType?: string;
  columnFactory: (options: {
    registry: Registry;
    type: string;
    t: TFunction;
  }) => object[];
  aggregatedStockItemsFragment?: DocumentNode;
}) {
  if (stockType == null) {
    registryContent.stockColumns._common.push(columnFactory);
    if (aggregatedStockItemsFragment) {
      registryContent.aggregatedStockItemsFragments._common.push(
        aggregatedStockItemsFragment
      );
    }
  } else {
    if (registryContent.stockColumns[stockType] == null) {
      registryContent.stockColumns[stockType] = [columnFactory];
    } else {
      registryContent.stockColumns[stockType].push(columnFactory);
    }
    if (aggregatedStockItemsFragment) {
      if (registryContent.stockColumns[stockType] == null) {
        registryContent.aggregatedStockItemsFragments[stockType] = [
          aggregatedStockItemsFragment,
        ];
      } else {
        registryContent.aggregatedStockItemsFragments[stockType].push(
          aggregatedStockItemsFragment
        );
      }
    }
  }
  notify();
}

export function addPageAction({
  pageId,
  ...action
}: { pageId: string } & PageAction) {
  if (registryContent.pageActions[pageId] == null) {
    registryContent.pageActions[pageId] = [action];
  } else {
    registryContent.pageActions[pageId].push(action);
  }
  notify();
}

export function addPageToolbarAction({
  pageId,
  ...action
}: { pageId: string } & PageToolbarAction) {
  if (registryContent.pageToolbarActions[pageId] == null) {
    registryContent.pageToolbarActions[pageId] = [action];
  } else {
    registryContent.pageToolbarActions[pageId].push(action);
  }
  notify();
}

export function addLogTableModifier({
  modifier,
  articleType,
}: {
  articleType: string;
  modifier: (config: object[]) => object[];
}) {
  if (articleType == null) {
    registryContent.logTableModifiers._common.push(modifier);
  } else {
    if (registryContent.logTableModifiers[articleType] == null) {
      registryContent.logTableModifiers[articleType] = [modifier];
    } else {
      registryContent.logTableModifiers[articleType].push(modifier);
    }
  }
  notify();
}

export function addLogItemType(logItemType: LogItemType) {
  registryContent.logItemTypes[logItemType.id] = logItemType;
  notify();
}

export function addAppWrapper(wrapper: {
  name: string;
  Component: ComponentType;
}) {
  registryContent.__appWrapper = null;
  registryContent.appWrappers.push(wrapper);
  notify();
}

export function addComponentToExtensionPoint(
  extensionPoint: NestedComponentExtensionPoint,
  component: (
    InnerComponent: ComponentType,
    innerProps: object
  ) => ComponentType
): void;
export function addComponentToExtensionPoint(
  extensionPoint: ComponentExtensionPoint,
  component: ComponentType
): void;
export function addComponentToExtensionPoint(
  extensionPoint: SingleComponentExtensionPoint,
  component: ComponentType
): void;
export function addComponentToExtensionPoint(
  extensionPoint:
    | ComponentExtensionPoint
    | NestedComponentExtensionPoint
    | SingleComponentExtensionPoint,
  component:
    | ComponentType
    | ((InnerComponent: ComponentType, innerProps: object) => ComponentType)
) {
  if (!registryContent.componentExtensionsPoints[extensionPoint]) {
    registryContent.componentExtensionsPoints[extensionPoint] = [component];
  } else {
    registryContent.componentExtensionsPoints[extensionPoint].push(component);
  }
  notify();
}

export function addMenuItem(menuItem: MenuItem) {
  registryContent.menuItems.push(menuItem);
  notify();
}

export function addSettingsMenuItem(menuItem: MenuItem) {
  registryContent.settingsMenuItems.push(menuItem);
  notify();
}

export function addPage(page: Page) {
  registryContent.pages.push(page);
  notify();
}

export function useRegistry() {
  const [registry, setRegistry] = React.useState(registryContent);
  React.useEffect(() => {
    const handleChange = () => {
      setRegistry({ ...registryContent }); // must be a new reference to trigger updates
    };
    listeners.add(handleChange);
    return () => {
      listeners.delete(handleChange);
    };
  }, []);
  return registry;
}

export function withRegistry<P extends object>() {
  return (Component: ComponentType<P & { registry: any }>) => (props: P) => {
    const registry = useRegistry();
    return <Component registry={registry} {...props} />;
  };
}

export const articleProperties: ArticleType["properties"] = {
  sku: { displayName: (_, { t }) => t("Artikelnummer") },
  name: { displayName: (_, { t }) => t("Bezeichnung") },
  location: { displayName: (_, { t }) => t("Lagerort") },
  chargeCarrier: { displayName: (_, { t }) => t("Ladungsträger") },
  initialStock: {
    displayName: (_, { t }) => t("Anfangsbestand"),
    formatValue: (value, articleType, { t }) =>
      formatWithUnit(
        typeof value === "string" ? parseFloat(value) : value,
        articleType.stockInfo.unit({ t })
      ),
  },
  minimumStock: {
    displayName: (_, { t }) => t("Meldebestand"),
    formatValue: (value, articleType, { t }) =>
      formatWithUnit(
        typeof value === "string" ? parseFloat(value) : value,
        articleType.stockInfo.unit({ t })
      ),
  },
};

export interface CustomField {
  id: string;
  name: string;
  type: string;
  asField?: string;
  defaultValue?: string;
  articleType?: string;
  stockItemType?: string;
  stockReservationType?: string;
  stockOrderType?: string;
}

export interface CustomFieldType<T extends CustomField> {
  /**
   * @param customField Custom field definition
   * @param options.t i18next t function
   * @returns Configured column for this custom field type
   */
  getColumn: (customField: T, { t }: { t: TFunction }) => any;
  getInputField: (customField: T, { t }: { t: TFunction }) => any;
  validate?: (value: string, customField: T) => boolean;
  formatLogValue?: (customFieldLogValue: {
    value?: string;
    [customLogKey: string]: any;
  }) => string;
  customFieldFragment: DocumentNode;
}

export function addCustomFieldType<T extends CustomField>(
  type: string,
  field: CustomFieldType<T>
) {
  registryContent.customFieldType[type] = field;
  notify();
}

export interface CustomFieldInput {
  CustomField: any;
  customFieldId: string;
  target?: "stockItem" | "article";
}
