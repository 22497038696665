import React from "react";
import {
  Theme,
  withStyles,
  Popover,
  Button,
  createStyles,
} from "@material-ui/core";
import { Pencil } from "mdi-material-ui";
import StringCustomFieldInput from "./StringCustomFieldInput";
import { useTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: "100%",
      maxWidth: 170,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflowX: "hidden",
      height: 18,
      display: "inline-block",
      verticalAlign: "bottom",
      "&:hover": {
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: `0 0 0 4px ${theme.palette.background.paper}`,
        "& $icon": {
          display: "inline",
        },
      },
    },
    icon: {
      display: "none",
      fontSize: "18px",
      color: theme.palette.text.secondary,
      position: "relative",
      float: "right",
      zIndex: 1,
      background: theme.palette.background.paper,
      paddingLeft: 4,
      width: 22,
      marginLeft: -22,
    },
    popover: {
      padding: 8,
      display: "flex",
      flexDirection: "column",
      minWidth: "300px",
    },
    saveButton: {
      float: "right",
    },
  });

interface Props {
  value: string;
  title: string;
  allowOtherValues: boolean;
  predefinedValues?: string[];
  onSave: (value: string) => void;
  classes: any;
}

const StringButton: React.FC<Props> = ({
  value,
  title,
  allowOtherValues,
  predefinedValues,
  classes,
  onSave,
}) => {
  const { t } = useTranslation();

  const [valueInput, setValueInput] = React.useState(
    predefinedValues ? predefinedValues[0] : ""
  );
  const [anchorEl, setAnchorEl] = React.useState();

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.target);
    setValueInput(value);
  };

  const handleClickPopup = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClose = () => setAnchorEl(undefined);

  const handleSave = () => {
    onSave(valueInput);
    handleClose();
  };

  const InputProps = {
    value: valueInput,
    onChange: (e: any) => setValueInput(e.target.value),
  };

  return (
    <React.Fragment>
      <span className={classes.root} onClick={handleClick}>
        <Pencil className={classes.icon} />
        {value}
      </span>
      <Popover
        open={anchorEl != null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ paper: classes.popover }}
        onClose={handleClose}
        onClick={handleClickPopup}
      >
        <StringCustomFieldInput
          label={title}
          allowOtherValues={allowOtherValues}
          predefinedValues={predefinedValues}
          InputProps={InputProps}
          variant="outlined"
          margin="dense"
        />
        <div className={classes.row}>
          <Button
            variant="text"
            color="primary"
            className={classes.saveButton}
            onClick={handleSave}
          >
            {t("Speichern")}
          </Button>
        </div>
      </Popover>
    </React.Fragment>
  );
};

export default withStyles(styles)(StringButton);
