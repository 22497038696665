import React, { useCallback, useMemo } from "react";
import { gql } from "graphql.macro";
import { useQuery, useMutation } from "react-apollo";
import Stock from "../components/Stock";
import {
  useArticleType,
  useEmployee,
  useFeatures,
} from "../../core/context/applicationContext";
import { useRegistry } from "../../plugins/registry";
import { addFragmentsToQuery } from "../../../util/graphqlUtil";

export const articleFragment = gql`
  fragment StockArticle on Article {
    id
    name
    sku
    type
    minimumStock
    type
    details {
      ... on LongGoodDetails {
        specificWeight
        length
        material
      }
      ... on SheetDetails {
        specificWeight
        length
        width
        area
        thickness
        material
      }
      ... on BreakBulkDetails {
        weight
      }
      ... on CoilDetails {
        length
        width
        thickness
        weight
        material
      }
    }
    customFields {
      customFieldId
      value
    }
    note {
      text
      lastUpdated
    }
    note2 {
      text
      lastUpdated
    }
    note3 {
      text
      lastUpdated
    }
  }
`;

export const stockItemFragment = gql`
  fragment StockItemFragment on StockItem {
    id
    sku
    type
    initial
    in
    out
    details {
      ... on RestSheetStockItemDetails {
        width
        length
        area
        cutouts {
          x1
          y1
          x2
          y2
        }
      }
      ... on SheetStockItemDetails {
        restSheetCount
      }
      ... on LongGoodStockItemDetails {
        restLongGoodCount
      }
      ... on RestLongGoodStockItemDetails {
        length
      }
    }
    loadCarrier {
      id
      name
      compartment {
        id
        compartment
        tower {
          id
          location
        }
      }
    }
    compartment {
      id
      compartment
      tower {
        id
        location
      }
    }
    temporaryLocation
    temporaryCompartment
    customFields {
      customFieldId
      value
    }
    note {
      text
      lastUpdated
    }
    note2 {
      text
      lastUpdated
    }
    note3 {
      text
      lastUpdated
    }
  }
`;

export const aggregatedStockItemsFragment = gql`
  fragment AggregatedStockItemsFragment on AggregatedStockItems {
    id
    type
    initial
    in
    out
    article {
      ...StockArticle
    }
    stockItems {
      ...StockItemFragment
    }
    details {
      ... on AggregatedSheetStockItemDetails {
        restSheetCount
      }
      ... on AggregatedLongGoodStockItemDetails {
        restLongGoodCount
      }
    }
  }
  ${articleFragment}
  ${stockItemFragment}
`;

export const GET_STOCK = gql`
  query GetStock($type: StockType!, $articleType: ArticleType!) {
    aggregatedStock(type: $type, articleType: $articleType) {
      ...AggregatedStockItemsFragment
    }
    articleNoteColumns(articleType: $articleType)
    stockItemNoteColumns(stockItemType: $type)
  }
  ${aggregatedStockItemsFragment}
`;

const ADD_DELTA = gql`
  mutation AddDelta($itemId: ID!, $change: ChangeStockInput!) {
    updatedArticle: changeStock(id: $itemId, change: $change) {
      id
      type
      initial
      in
      out
      article {
        ...StockArticle
      }
    }
  }
  ${articleFragment}
`;

const CREATE_ITEM = gql`
  mutation CreateItem(
    $item: CreateItemInput!
    $initialStock: Float!
    $changeItemStock: Float
    $employee: String
    $note: String
  ) {
    createItem(
      item: $item
      initialStock: $initialStock
      changeItemStock: $changeItemStock
      employee: $employee
      note: $note
    ) {
      id
    }
  }
`;

const UPDATE_ARTICLE_NOTE = gql`
  mutation UpdateArticleNote(
    $articleId: ID!
    $noteIndex: Int!
    $text: String!
    $employee: String
  ) {
    updatedArticle: updateArticleNote(
      articleId: $articleId
      noteIndex: $noteIndex
      text: $text
      employee: $employee
    ) {
      ...StockArticle
    }
  }
  ${articleFragment}
`;

const UPDATE_ARTICLE_NOTE_COLUMN_TITLE = gql`
  mutation UpdateArticleNoteColumnTitle(
    $articleType: ArticleType!
    $noteIndex: Int!
    $title: String!
  ) {
    newTitle: setArticleNoteColumnTitle(
      articleType: $articleType
      index: $noteIndex
      title: $title
    ) {
      index
      title
    }
  }
`;

const UPDATE_STOCK_ITEM_NOTE = gql`
  mutation UpdateStockItemNote(
    $stockItemId: ID!
    $noteIndex: Int!
    $text: String!
    $employee: String
  ) {
    updatedItem: updateStockItemNote(
      stockItemId: $stockItemId
      noteIndex: $noteIndex
      text: $text
      employee: $employee
    ) {
      ...StockItemFragment
    }
  }
  ${stockItemFragment}
`;

const UPDATE_STOCK_ITEM_NOTE_COLUMN_TITLE = gql`
  mutation UpdateStockItemNoteColumnTitle(
    $stockItemType: StockType!
    $noteIndex: Int!
    $title: String!
  ) {
    newTitle: setStockItemNoteColumnTitle(
      stockItemType: $stockItemType
      index: $noteIndex
      title: $title
    ) {
      index
      title
    }
  }
`;

const GET_CUSTOM_FIELDS_FOR_STOCK_ITEM = gql`
  query GetCustomFieldsForStockItem($stockItemType: StockType!) {
    customFields(stockItemType: $stockItemType) {
      id
      name
      type
      asField
      defaultValue
      stockItemType
      articleType
    }
    changeStockCustomFields: customFields(stockChangeType: $stockItemType) {
      id
      name
      type
      asField
      defaultValue
      stockChangeType
    }
  }
`;

const UPDATE_CUSTOM_FIELD_VALUE = gql`
  mutation UpdateCustomFieldOfStockItem(
    $customFieldId: ID!
    $stockItemId: ID
    $articleId: ID
    $stockReservationId: ID
    $stockOrderId: ID
    $value: String!
    $employee: String
  ) {
    updateCustomFieldValue(
      customFieldId: $customFieldId
      stockItemId: $stockItemId
      articleId: $articleId
      stockReservationId: $stockReservationId
      stockOrderId: $stockOrderId
      value: $value
      employee: $employee
    ) {
      customFieldId
      value
    }
  }
`;

export default function StockContainer({
  match,
  stockQuery = GET_STOCK,
  keepQueryUntouched = false,
  mapStockData,
  getStockItemById,
  Placeholder,
  actions,
  quickFilters,
  hiddenColumns,
  fixedColumns,
  additionalColumns,
  additionalCustomFields,
  hideStockToolbarActions,
  pageToolbarActions,
  configKey,
}) {
  const [articleType] = useArticleType();
  const stockItemType = match || articleType;
  const [employee] = useEmployee();
  const features = useFeatures();
  const registry = useRegistry();

  const getStockQuery = useMemo(
    () =>
      keepQueryUntouched
        ? stockQuery
        : addFragmentsToQuery(stockQuery, [
            ...registry.aggregatedStockItemsFragments._common,
            ...(registry.aggregatedStockItemsFragments[stockItemType] ?? []),
          ]),
    [registry, stockItemType, stockQuery, keepQueryUntouched]
  );

  //#region Mutations
  const [addDelta] = useMutation(ADD_DELTA);

  const [createItem] = useMutation(CREATE_ITEM, {
    refetchQueries: () => [
      { query: getStockQuery, variables: { type: stockItemType, articleType } },
    ],
  });

  const [hideItem] = useMutation(
    gql`
      mutation HideStockItem($id: ID!) {
        hideItem(id: $id) {
          id
        }
      }
    `,
    {
      refetchQueries: () => [
        {
          query: getStockQuery,
          variables: { type: stockItemType, articleType },
        },
      ],
    }
  );

  const [updateArticleNote] = useMutation(UPDATE_ARTICLE_NOTE);

  const [updateArticleNoteColumnTitle] = useMutation(
    UPDATE_ARTICLE_NOTE_COLUMN_TITLE,
    {
      update: (cache, { data: { newTitle } }) => {
        try {
          const data = cache.readQuery({
            query: getStockQuery,
            variables: { type: stockItemType, articleType },
          });
          cache.writeQuery({
            query: getStockQuery,
            variables: { type: stockItemType, articleType },
            data: {
              ...data,
              articleNoteColumns: data.articleNoteColumns.map((title, i) =>
                i === newTitle.index ? newTitle.title : title
              ),
            },
          });
        } catch (e) {
          console.warn("Updating cache failed", e);
        }
      },
    }
  );

  const [updateStockItemNote] = useMutation(UPDATE_STOCK_ITEM_NOTE);

  const [updateStockItemNoteColumnTitle] = useMutation(
    UPDATE_STOCK_ITEM_NOTE_COLUMN_TITLE,
    {
      update: (cache, { data: { newTitle } }) => {
        try {
          const data = cache.readQuery({
            query: getStockQuery,
            variables: { type: stockItemType, articleType },
          });
          cache.writeQuery({
            query: getStockQuery,
            variables: { type: stockItemType, articleType },
            data: {
              ...data,
              stockItemNoteColumns: data.stockItemNoteColumns.map((title, i) =>
                i === newTitle.index ? newTitle.title : title
              ),
            },
          });
        } catch (e) {
          console.warn("Updating cache failed", e);
        }
      },
    }
  );
  //#endregion

  const [updateCustomFieldValue] = useMutation(UPDATE_CUSTOM_FIELD_VALUE, {
    refetchQueries: [
      { query: stockQuery, variables: { type: stockItemType, articleType } },
    ],
  });

  const customFieldsQuery = useMemo(
    () =>
      addFragmentsToQuery(
        GET_CUSTOM_FIELDS_FOR_STOCK_ITEM,
        Object.values(registry.customFieldType).map(
          (field) => field.customFieldFragment
        )
      ),
    [registry]
  );

  const { data: customFieldDefinitions, loading: loadingCustomField } =
    useQuery(customFieldsQuery, {
      variables: { stockItemType },
      fetchPolicy: "network-only",
      skip: stockItemType == null,
      pollInterval: 300000,
    });

  // loading the articles for each stock item (which are the same as the aggregated stock itemarticle) is about
  // 10 times slower due to the huge payload that graphql needs to verify, so we don't do that and assemble it manually
  const { data, loading, error } = useQuery(getStockQuery, {
    variables: {
      type: stockItemType,
      articleType,
    },
    fetchPolicy: "network-only",
    pollInterval: 15000,
  });
  const aggregatedStock = useMemo(
    () =>
      mapStockData
        ? mapStockData(data)
        : data?.aggregatedStock?.map((aggregatedStockItem) => {
            const row = {
              ...aggregatedStockItem,
              id: `aggregated-${aggregatedStockItem.id}`,
              stockItems: aggregatedStockItem.stockItems.map((stockItem) => ({
                ...stockItem,
                article: aggregatedStockItem.article,
              })),
            };
            row.stockItems.forEach((item) => (item.parent = row));
            return row;
          }) ?? [],
    [data, mapStockData]
  );

  const defaultGetStockItemById = useCallback((aggregatedStock, id) => {
    if (id.includes("aggregated")) {
      return aggregatedStock.find((stock) => stock.id === id);
    }
    for (const { stockItems } of aggregatedStock) {
      for (const stockItem of stockItems) {
        if (stockItem.id === id) {
          return stockItem;
        }
      }
    }
  }, []);

  const stockCustomFields = customFieldDefinitions?.customFields;
  const customFields = useMemo(
    () => [...(additionalCustomFields || []), ...(stockCustomFields || [])],
    [additionalCustomFields, stockCustomFields]
  );
  const changeStockCustomFields = useMemo(
    () => customFieldDefinitions?.changeStockCustomFields ?? [],
    [customFieldDefinitions]
  );

  return (
    <Stock
      stock={aggregatedStock}
      type={stockItemType}
      features={features}
      articleNoteColumns={data?.articleNoteColumns}
      stockItemNoteColumns={data?.stockItemNoteColumns}
      fields={customFields}
      changeStockFields={changeStockCustomFields}
      onCreateItem={(
        articleId,
        { initialStock, loadCarrierId, compartmentId, customFields },
        note
      ) =>
        createItem({
          variables: {
            item: {
              articleId,
              type: stockItemType,
              loadCarrierId,
              compartmentId,
              customFields,
            },
            initialStock,
            note,
            employee,
          },
        })
      }
      onUpdateItem={(item, { delta, note, customFields }) =>
        addDelta({
          variables: {
            itemId: item.id,
            change: {
              delta,
              note,
              customFields,
              employee,
            },
          },
        })
      }
      onHideStockItem={(item) => hideItem({ variables: { id: item.id } })}
      onUpdateArticleNote={(item, noteIndex, text) =>
        updateArticleNote({
          variables: {
            articleId: item.article.id,
            noteIndex,
            text,
            employee,
          },
        })
      }
      onUpdateArticleNoteColumnTitle={(noteIndex, title) =>
        updateArticleNoteColumnTitle({
          variables: {
            noteIndex,
            title,
            articleType,
          },
        })
      }
      onUpdateStockItemNote={(item, noteIndex, text) =>
        updateStockItemNote({
          variables: {
            stockItemId: item.id,
            noteIndex,
            text,
            employee,
          },
        })
      }
      onUpdateStockItemNoteColumnTitle={(noteIndex, title) =>
        updateStockItemNoteColumnTitle({
          variables: {
            noteIndex,
            title,
            stockItemType,
          },
        })
      }
      onUpdateCustomFieldValue={(customFieldId, targetId, value) =>
        updateCustomFieldValue({
          variables: { customFieldId, ...targetId, value, employee },
        })
      }
      error={error}
      loading={loading || loadingCustomField}
      Placeholder={Placeholder}
      actions={actions}
      quickFilters={quickFilters}
      getStockItemById={getStockItemById ?? defaultGetStockItemById}
      hiddenColumns={hiddenColumns}
      fixedColumns={fixedColumns}
      additionalColumns={additionalColumns}
      hideStockToolbarActions={hideStockToolbarActions}
      pageToolbarActions={pageToolbarActions}
      configKey={configKey}
    />
  );
}
