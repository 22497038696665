import React, { useCallback } from "react";
import { MenuItem } from "@material-ui/core";
import { gql } from "graphql.macro";
import { useTranslation } from "react-i18next";
import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import { numberColumn } from "../../util/enhancedTableUtils";
import {
  addLogItemType,
  addMenuItem,
  addPage,
  addStockAction,
  addStockTableColumns,
  useRegistry,
} from "../plugins/registry";
import {
  aggregatedRowSum,
  currentStockFilter,
  ifStockItem,
  maybeAggregated,
} from "../stock/components/stockTable";
import {
  ConsumeReservedStockForm,
  ReleaseReservedStockForm,
  ReserveStockForm,
} from "./ReserveStockForm";
import {
  CancelOrderedStockForm,
  ConfirmOrderedStockForm,
  OrderStockForm,
} from "./OrderStockForm";
import Stock, {
  articleFragment,
  stockItemFragment,
} from "../stock/containers/Stock";
import { Forklift, TruckDeliveryOutline } from "mdi-material-ui";
import useCustomFields from "../customFields/useCustomFields";
import { useArticleType } from "../core/context/applicationContext";
import FieldChanges from "../log/components/FieldChanges";
import { red } from "@material-ui/core/colors";

addStockTableColumns({
  columnFactory: ({ t, registry, type }) =>
    [
      type.startsWith("rest")
        ? null
        : numberColumn({
            id: "ordered",
            title: t("stockReservation:Geplanter Eingang"),
            getNumber: aggregatedRowSum((row) => row.ordered),
            unit: {
              position: "end",
              name: registry.stockItemTypes[type].stockInfo.unit({ t }),
            },
            range: { min: 0, max: null },
          }),
      numberColumn({
        id: "reserved",
        title: t("stockReservation:Reserviert"),
        getNumber: aggregatedRowSum((row) =>
          type !== "restLongGoodVariant" && row.type === "restLongGoodVariant"
            ? row.reserved * row.details.length
            : row.reserved
        ),
        unit: {
          position: "end",
          name: registry.stockItemTypes[type].stockInfo.unit({ t }),
        },
        range: { min: 0, max: null },
      }),
      numberColumn({
        id: "planned",
        title: t("stockReservation:Planbestand"),
        getNumber: aggregatedRowSum(
          (row) => row.initial + row.in - row.out + row.ordered - row.reserved
        ),
        content: ifStockItem(
          (row, { nestedOf, cellContentParams: { features } }) => {
            const planned =
              row.initial + row.in - row.out + row.ordered - row.reserved;
            if (isNaN(planned)) {
              return "";
            }
            if (
              features.minimumStockByPlannedStock &&
              (nestedOf == null || nestedOf.flattened) && // nur in aggregierten Zeilen anzeigen
              row.article.minimumStock != null &&
              planned <= row.article.minimumStock
            ) {
              return (
                <span style={{ color: red[500] }}>
                  {planned.toLocaleString()}{" "}
                  {registry.stockItemTypes[type].stockInfo.unit({ t })}
                </span>
              );
            }
            return `${planned.toLocaleString()} ${registry.stockItemTypes[
              type
            ].stockInfo.unit({ t })}`;
          },
          (row, { cellContentParams: { features } }) => {
            const planned = row.stockItems.reduce(
              (sum, stockItem) =>
                sum +
                stockItem.initial +
                stockItem.in -
                stockItem.out +
                stockItem.ordered -
                stockItem.reserved,
              0
            );
            if (isNaN(planned)) {
              return "";
            }
            if (
              features.minimumStockByPlannedStock &&
              row.article.minimumStock != null &&
              planned <= row.article.minimumStock
            ) {
              return (
                <span style={{ color: red[500] }}>
                  {planned.toLocaleString()}{" "}
                  {registry.stockItemTypes[type].stockInfo.unit({ t })}
                </span>
              );
            }
            return `${planned.toLocaleString()} ${registry.stockItemTypes[
              type
            ].stockInfo.unit({ t })}`;
          }
        ),
        unit: {
          position: "end",
          name: registry.stockItemTypes[type].stockInfo.unit({ t }),
        },
        range: { min: 0, max: null },
        filter: currentStockFilter({
          getStock: (row) =>
            row.initial + row.in - row.out + row.ordered - row.reserved,
        }),
      }),
    ].filter((column) => column != null),
  aggregatedStockItemsFragment: gql`
    fragment ReservationData on AggregatedStockItems {
      reserved
      ordered
      stockItems {
        reserved
        ordered
      }
    }
  `,
});

addLogItemType({
  id: "stockItemOrder",
  logItemFragment: gql`
    fragment StockOrderLogItemData on StockOrderLogItem {
      note
      stockItem {
        id
        sku
        type
      }
      order {
        delta
        type
        order {
          id
        }
      }
      article {
        id
        sku
        type
        name
      }
      changedFields {
        field
        oldValue
        newValue
      }
    }
  `,
  sku: (row) => row.stockItem.sku ?? row.article.sku,
  change: (row, { registry, t }) => {
    const stockItemType = registry.stockItemTypes[row.stockItem.type];
    const stockInfo = stockItemType.stockInfo;
    const delta = stockInfo.formatWithUnit(row.order.delta, row.article, {
      t,
    });
    return (
      <>
        {row.order.order &&
          t("stockReservation:Bestellung #{{id}}", { id: row.order.order.id })}
        <br />
        {`${t("stockReservation:Geplanter Eingang")}: ${
          row.order.delta > 0 ? `+${delta}` : delta
        }`}
        <br />
        <FieldChanges changedFields={row.changedFields} type={stockItemType} />
      </>
    );
  },
  type: (row, { t }) => {
    switch (row.order.type) {
      case "order":
        return t("stockReservation:Artikel bestellt");
      case "confirm":
        return t("stockReservation:Wareneingang bestätigt");
      case "cancel":
        return t("stockReservation:Bestellung storniert");
      default:
        return "";
    }
  },
});

addLogItemType({
  id: "stockOrderChange",
  logItemFragment: gql`
    fragment StockOrderChangeLogItemData on StockOrderChangeLogItem {
      note
      order {
        id
        stockItem {
          id
          sku
          type
          article {
            id
            sku
            type
            name
          }
        }
      }
      changedFields {
        field
        oldValue
        newValue
      }
    }
  `,
  articleName: (row) => {
    return row.order.stockItem.article.name;
  },
  sku: (row) => row.order.stockItem.sku ?? row.order.stockItem.article.sku,
  change: (row, { t, registry }) => {
    const stockItemType = registry.stockItemTypes[row.order.stockItem.type];
    return (
      <>
        {t("stockReservation:Bestellung #{{id}}", { id: row.order.id })}
        <br />
        <FieldChanges changedFields={row.changedFields} type={stockItemType} />
      </>
    );
  },
  type: (row, { t }) => t("stockReservation:Bestellung aktualisiert"),
});

addLogItemType({
  id: "stockItemReservation",
  logItemFragment: gql`
    fragment StockReservationLogItemData on StockReservationLogItem {
      note
      stockItem {
        id
        sku
        type
      }
      reservation {
        delta
        type
        reservation {
          id
        }
      }
      article {
        id
        sku
        type
        name
      }
      changedFields {
        field
        oldValue
        newValue
      }
    }
  `,
  sku: (row) => row.stockItem.sku ?? row.article.sku,
  change: (row, { registry, t }) => {
    const stockItemType = registry.stockItemTypes[row.stockItem.type];
    const stockInfo = stockItemType.stockInfo;
    const delta = stockInfo.formatWithUnit(row.reservation.delta, row.article, {
      t,
    });
    return (
      <>
        {row.reservation.reservation &&
          t("stockReservation:Reservierung #{{id}}", {
            id: row.reservation.reservation.id,
          })}
        <br />
        {`${t("stockReservation:Reserviert")}: ${
          row.reservation.delta > 0 ? `+${delta}` : delta
        }`}
        <br />
        <FieldChanges changedFields={row.changedFields} type={stockItemType} />
      </>
    );
  },
  type: (row, { t }) => {
    switch (row.reservation.type) {
      case "reserve":
        return t("stockReservation:Bestand reserviert");
      case "consume":
        return t("stockReservation:Reservierten Bestand entnommen");
      case "release":
        return t("stockReservation:Reservierung aufgehoben");
      default:
        return "";
    }
  },
});

addLogItemType({
  id: "stockReservationChange",
  logItemFragment: gql`
    fragment StockReservationChangeLogItemData on StockReservationChangeLogItem {
      note
      reservation {
        id
        stockItem {
          id
          sku
          type
          article {
            id
            sku
            type
            name
          }
        }
      }
      changedFields {
        field
        oldValue
        newValue
      }
    }
  `,
  articleName: (row) => {
    return row.reservation.stockItem.article.name;
  },
  sku: (row) =>
    row.reservation.stockItem.sku ?? row.reservation.stockItem.article.sku,
  change: (row, { t, registry }) => {
    const stockItemType =
      registry.stockItemTypes[row.reservation.stockItem.type];
    return (
      <>
        {t("stockReservation:Reservierung #{{id}}", { id: row.reservation.id })}
        <br />
        <FieldChanges changedFields={row.changedFields} type={stockItemType} />
      </>
    );
  },
  type: (row, { t }) => t("stockReservation:Reservierung aktualisiert"),
});

const reserveStockAction = {
  label: ({ t }) => t("stockReservation:Bestand reservieren"),
  MenuItem: ({ onClose, item, ...other }) => {
    const { t } = useTranslation();
    return (
      <MenuItem {...other}>
        {t("stockReservation:Bestand reservieren")}
      </MenuItem>
    );
  },
  PopoverForm: ReserveStockForm,
};
addStockAction(reserveStockAction);
const consumeReservedStockAction = {
  label: ({ t }) => t("stockReservation:Reservierten Bestand entnehmen"),
  MenuItem: ({ onClose, item, ...other }) => {
    const { t } = useTranslation();
    return (
      <MenuItem {...other} disabled={item.reserved <= 0}>
        {t("stockReservation:Reservierten Bestand entnehmen")}
      </MenuItem>
    );
  },
  PopoverForm: ConsumeReservedStockForm,
};
const releaseReservedStockAction = {
  label: ({ t }) => t("stockReservation:Reservierung aufheben"),
  MenuItem: ({ onClose, item, ...other }) => {
    const { t } = useTranslation();
    return (
      <MenuItem {...other} disabled={item.reserved <= 0}>
        {t("stockReservation:Reservierung aufheben")}
      </MenuItem>
    );
  },
  PopoverForm: ReleaseReservedStockForm,
};

addStockAction({
  label: ({ t }) => t("stockReservation:Artikel bestellen"),
  MenuItem: ({ onClose, item, ...other }) => {
    const { t } = useTranslation();
    return (
      <MenuItem {...other}>{t("stockReservation:Artikel bestellen")}</MenuItem>
    );
  },
  PopoverForm: OrderStockForm,
  showIf: ({ stockItemType }) => !stockItemType.id.startsWith("rest"),
});
const confirmOrderAction = {
  label: ({ t }) => t("stockReservation:Wareneingang bestätigen"),
  MenuItem: ({ onClose, item, ...other }) => {
    const { t } = useTranslation();
    return (
      <MenuItem {...other} disabled={item.ordered <= 0}>
        {t("stockReservation:Wareneingang bestätigen")}
      </MenuItem>
    );
  },
  PopoverForm: ConfirmOrderedStockForm,
};
const cancelOrderAction = {
  label: ({ t }) => t("stockReservation:Bestellung stornieren"),
  MenuItem: ({ onClose, item, ...other }) => {
    const { t } = useTranslation();
    return (
      <MenuItem {...other} disabled={item.ordered <= 0}>
        {t("stockReservation:Bestellung stornieren")}
      </MenuItem>
    );
  },
  PopoverForm: CancelOrderedStockForm,
};

addMenuItem({
  name: ({ t }) => t("stockReservation:Wareneingang"),
  tooltip: ({ t }) => t("stockReservation:Wareneingang"),
  route: "ordered",
  icon: <TruckDeliveryOutline />,
});

const GET_ORDERED_STOCK = gql`
  query GetStock($type: StockType!, $articleType: ArticleType!) {
    aggregatedStock: aggregatedOrderedStock(
      type: $type
      articleType: $articleType
    ) {
      id
      type
      initial
      in
      out
      article {
        ...StockArticle
      }
      orders {
        id
        ordered
        stockItem {
          ...StockItemFragment
        }
        customFields {
          customFieldId
          value
          lastUpdated
        }
      }
    }
    customFields(stockItemType: $type) {
      id
      name
      type
      asField
      stockItemType
      articleType
    }
  }
  ${articleFragment}
  ${stockItemFragment}
`;

function OrderedStockPlaceholder({ error, type }) {
  const { t } = useTranslation();
  const registry = useRegistry();

  return error ? (
    <>
      {t(
        "stockReservation:Beim Laden der geplanten Eingänge ist ein Fehler aufgetreten."
      )}
      <br />
      <br />
      {error.graphQLErrors?.length === 0 &&
        t("Bitte überprüfen Sie Ihre Internetverbindung.")}
    </>
  ) : (
    <span>
      {t(
        "stockReservation:Es gibt derzeit keine geplanten Eingänge für {{type}}.",
        {
          type: registry.stockItemTypes[type].displayName(2, { t }),
        }
      )}
      <br />
      <br />
      {t(
        "stockReservation:Wechseln Sie in die Bestands-Ansicht, um neue {{type}} zu bestellen.",
        {
          type: registry.stockItemTypes[type].displayName(2, { t }),
        }
      )}
    </span>
  );
}

addPage({
  route: "ordered",
  Component: ({ match }) => {
    const handleMapStockData = useCallback(
      (data) =>
        data?.aggregatedStock?.flatMap((aggregatedStockOrder) =>
          aggregatedStockOrder.orders.map((stockOrder) => {
            const row = {
              ...aggregatedStockOrder,
              id: `aggregated-${aggregatedStockOrder.id}`,
              stockItems: [
                {
                  ...stockOrder.stockItem,
                  id: stockOrder.id,
                  article: aggregatedStockOrder.article,
                  stockOrder,
                  stockItem: stockOrder.stockItem,
                  ordered: stockOrder.ordered,
                },
              ],
              customFields: [
                ...stockOrder.customFields,
                ...stockOrder.stockItem.customFields,
              ],
            };
            row.stockItems.forEach((item) => (item.parent = row));
            return row;
          })
        ) ?? [],
      []
    );

    const getStockItemById = useCallback((aggregatedStock, id) => {
      if (id.includes("aggregated")) {
        return aggregatedStock.find((stock) => stock.id === id);
      }
      for (const { stockItems } of aggregatedStock) {
        for (const order of stockItems) {
          if (order.stockItem.id === id) {
            return order;
          }
        }
      }
    }, []);

    const [type] = useArticleType();

    const customFieldDefinitions = useCustomFields({
      stockOrderType: type,
    });

    const registry = useRegistry();

    return (
      <Stock
        match={match}
        stockQuery={GET_ORDERED_STOCK}
        keepQueryUntouched
        mapStockData={handleMapStockData}
        getStockItemById={getStockItemById}
        Placeholder={OrderedStockPlaceholder}
        actions={[
          {
            id: "orderStock",
            items: [confirmOrderAction, cancelOrderAction],
            showIf: ({ selectedItem }) =>
              selectedItem.__typename !== "AggregatedStockOrders",
          },
          {
            id: "pluginActions",
            items: registry.pageActions.ordered || [],
            showIf: ({ selectedItem }) =>
              selectedItem.__typename !== "AggregatedStockOrders",
          },
        ]}
        hideStockToolbarActions
        pageToolbarActions={registry.pageToolbarActions.ordered}
        quickFilters={[]}
        hiddenColumns={[
          "details.restSheetCount",
          "details.restLongGoodCount",
          "reserved",
          "planned",
          "note",
          "note2",
          "note3",
          "stockItemNote",
          "stockItemNote2",
          "stockItemNote3",
        ]}
        fixedColumns={0}
        additionalCustomFields={customFieldDefinitions}
        additionalColumns={({ t }) => [
          {
            id: "orderId",
            title: t("stockReservation:Bestellung"),
            contentString: (row) =>
              row.__typename === "AggregatedStockOrders"
                ? ""
                : `#${row.stockOrder.id}`,
            filter: {
              type: StringSearchFilter,
            },
            compare: (a, b) =>
              maybeAggregated((row) => row.stockOrder?.id ?? "")(a) -
              maybeAggregated((row) => row.stockOrder?.id ?? "")(b),
          },
        ]}
        configKey={`${type}-orders`}
      />
    );
  },
});

addMenuItem({
  name: ({ t }) => t("stockReservation:Warenausgang"),
  tooltip: ({ t }) => t("stockReservation:Warenausgang"),
  route: "reserved",
  icon: <Forklift />,
});

const GET_RESERVED_STOCK = gql`
  query GetStock($type: StockType!, $articleType: ArticleType!) {
    aggregatedStock: aggregatedReservedStock(articleType: $articleType) {
      id
      type
      initial
      in
      out
      article {
        ...StockArticle
      }
      reservations {
        id
        reserved
        stockItem {
          ...StockItemFragment
        }
        customFields {
          customFieldId
          value
          lastUpdated
        }
      }
    }
    customFields(stockItemType: $type) {
      id
      name
      type
      asField
      stockItemType
      articleType
    }
  }
  ${articleFragment}
  ${stockItemFragment}
`;

function ReservedStockPlaceholder({ error, type }) {
  const { t } = useTranslation();
  const registry = useRegistry();

  return error ? (
    <>
      {t(
        "stockReservation:Beim Laden der Reservierungen ist ein Fehler aufgetreten."
      )}
      <br />
      <br />
      {error.graphQLErrors?.length === 0 &&
        t("Bitte überprüfen Sie Ihre Internetverbindung.")}
    </>
  ) : (
    <span>
      {t(
        "stockReservation:Es gibt derzeit keine Reservierungen für {{type}}.",
        {
          type: registry.stockItemTypes[type].displayName(2, { t }),
        }
      )}
      <br />
      <br />
      {t(
        "stockReservation:Wechseln Sie in die Bestands-Ansicht, um {{type}} zu reservieren.",
        {
          type: registry.stockItemTypes[type].displayName(2, { t }),
        }
      )}
    </span>
  );
}

addPage({
  route: "reserved",
  Component: ({ match }) => {
    const handleMapStockData = useCallback(
      (data) =>
        data?.aggregatedStock?.flatMap((aggregatedStockReservation) =>
          aggregatedStockReservation.reservations.map((stockReservation) => {
            const row = {
              ...aggregatedStockReservation,
              id: `aggregated-${aggregatedStockReservation.id}`,
              stockItems: [
                {
                  ...stockReservation.stockItem,
                  id: stockReservation.id,
                  article: aggregatedStockReservation.article,
                  stockReservation,
                  stockItem: stockReservation.stockItem,
                  reserved: stockReservation.reserved,
                },
              ],
              customFields: [
                ...stockReservation.customFields,
                ...stockReservation.stockItem.customFields,
              ],
            };
            row.stockItems.forEach((item) => (item.parent = row));
            return row;
          })
        ) ?? [],
      []
    );

    const getStockItemById = useCallback((aggregatedStock, id) => {
      if (id.includes("aggregated")) {
        return aggregatedStock.find((stock) => stock.id === id);
      }
      for (const { stockItems } of aggregatedStock) {
        for (const reservation of stockItems) {
          if (reservation.stockItem.id === id) {
            return reservation;
          }
        }
      }
    }, []);

    const [type] = useArticleType();

    const customFieldDefinitions = useCustomFields({
      stockReservationType: type,
    });

    const registry = useRegistry();

    return (
      <Stock
        match={match}
        stockQuery={GET_RESERVED_STOCK}
        keepQueryUntouched
        mapStockData={handleMapStockData}
        getStockItemById={getStockItemById}
        Placeholder={ReservedStockPlaceholder}
        actions={[
          {
            id: "orderStock",
            items: [consumeReservedStockAction, releaseReservedStockAction],
            showIf: ({ selectedItem }) =>
              selectedItem.__typename !== "AggregatedStockReservations",
          },
          {
            id: "pluginActions",
            items: registry.pageActions.reserved || [],
            showIf: ({ selectedItem }) =>
              selectedItem.__typename !== "AggregatedStockReservations",
          },
        ]}
        hideStockToolbarActions
        pageToolbarActions={registry.pageToolbarActions.reserved}
        quickFilters={[]}
        hiddenColumns={[
          "details.restSheetCount",
          "details.restLongGoodCount",
          "ordered",
          "planned",
          "note",
          "note2",
          "note3",
          "stockItemNote",
          "stockItemNote2",
          "stockItemNote3",
        ]}
        fixedColumns={0}
        additionalCustomFields={customFieldDefinitions}
        additionalColumns={({ t }) => [
          {
            id: "reservationId",
            title: t("stockReservation:Reservierung"),
            contentString: (row) =>
              row.__typename === "AggregatedStockReservations"
                ? ""
                : `#${row.stockReservation.id}`,
            filter: {
              type: StringSearchFilter,
            },
            compare: (a, b) =>
              maybeAggregated((row) => row.stockReservation?.id ?? "")(a) -
              maybeAggregated((row) => row.stockReservation?.id ?? "")(b),
          },
        ]}
        configKey={`${type}-reservations`}
      />
    );
  },
});
