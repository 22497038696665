import React, { createRef } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableRow,
  Menu,
  MenuItem,
  ListSubheader,
  Divider,
  Popover,
  ListItemIcon,
} from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { TableArrowDown, DatabaseImportOutline } from "mdi-material-ui";
import EnhancedTable from "@wa/werkstoff-data-table";
import withLocalStorageConfig from "@wa/werkstoff-data-table/lib/withLocalStorageConfig";
import withFixedColumns from "@wa/werkstoff-data-table/lib/withFixedColumns";
import withVerticalScroll from "@wa/werkstoff-data-table/lib/withVerticalScroll";
import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import debounce from "debounce";
import ToggleButton from "../../../components/ToggleButton";
import CreateArticleDialog from "./CreateArticleDialog";
import EditArticleDialog from "./EditArticleDialog";
import { withRegistry } from "../../plugins/registry";
import NoteButton from "../../../components/NoteButton";
import RenamePopover from "../../../components/RenamePopover";
import { saveCsv, saveXlsx } from "../../../util/spreadsheetExport";
import PopoverIconButton from "../../../components/PopoverIconButton";
import TooltipIconButton from "../../../components/TooltipIconButton";
import TableExportForm from "../../stock/components/TableExportForm";
import { withSnackbar } from "material-ui-snackbar-provider";
import ComponentExtensionPoint from "../../plugins/ComponentExtensionPoint";
import SearchTextField from "../../../components/SearchTextField";
import DraggablePaper from "../../../components/DraggablePaper";
import TablePlaceholder from "../../../components/TablePlaceholder";
import { compareNatural } from "../../../util/compareNatural";
import { Trans, withTranslation } from "react-i18next";
import { prepareSmartSearch } from "../../../util/smartSearch";

const ArticlesTable = withLocalStorageConfig()(
  withFixedColumns(2)(withVerticalScroll()(EnhancedTable))
);

const noteColumn = ({
  noteIndex,
  note,
  defaultTitle,
  title,
  type,
  ...other
}) => ({
  content: (row, { cellContentParams: { handleSaveNote } }) => (
    <NoteButton
      note={note(row)}
      id={row.id}
      onSaveNote={(e, { ...other }) =>
        handleSaveNote(e, { ...other, noteIndex, type })
      }
    />
  ),
  contentString: (row) => note(row)?.text || "",
  tooltip: (row) => note(row)?.text || "",
  compare: (a, b) => {
    const noteA = note(a);
    const noteB = note(b);
    if (noteA && noteB) {
      return compareNatural(noteA?.text, noteB?.text);
    } else if (noteA) {
      return -1;
    } else if (noteB) {
      return 1;
    } else {
      return 0;
    }
  },
  filter: {
    type: StringSearchFilter,
    getSearchString: (row) => note(row)?.text || "",
  },
  headerActions: ({ handleSaveNoteColumnTitle }) => (
    <RenamePopover
      key={title || defaultTitle(noteIndex)}
      defaultTitle={title || defaultTitle(noteIndex)}
      onSubmit={(e, title) =>
        handleSaveNoteColumnTitle(e, { noteIndex, title, type })
      }
    />
  ),
  title: title || defaultTitle(noteIndex),
  ...other,
});

const articleNoteColumn = (other) =>
  noteColumn({
    defaultTitle: (index) => `Artikel-Notiz ${index + 1}`,
    note: (row) => {
      switch (other.noteIndex) {
        case 0:
          return row.note;
        case 1:
          return row.note2;
        case 2:
          return row.note3;
        default:
          return null;
      }
    },
    ...other,
  });

const styles = (theme) => ({
  root: {
    padding: "24px 24px 14px",
    flex: 1,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  toolbar: {
    marginBottom: 16,
    display: "flex",
    flexDirection: "row",
  },
  search: {
    width: 350,
  },
  flex: {
    flex: 1,
  },
  toolbarButton: {
    height: 36,
  },
  toolbarIconButton: {
    margin: "-4px 0",
    padding: 10,
  },
  addArticleButton: {
    height: 36,
    marginRight: 8,
  },
  exportPopover: {
    padding: 8,
    width: 400,
  },
  table: {
    flex: 1,
  },
  deltaPopover: {
    padding: 16,
    marginTop: -8,
    marginLeft: 24,
    minWidth: 350,
    "&$popoverDragging": {
      transition: "opacity 355ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
    },
    "& > *:first-child": {
      cursor: "default",
    },
  },
  popoverDragging: {},
});

class Articles extends React.PureComponent {
  fileInput = React.createRef();
  state = {
    createDialogOpen: false,
    createArticle: null,
    editArticleDialogOpen: false,
    editArticle: null,
    showDuplicateSkuDialog: false,
    duplicateSkuArticle: null,
    search: null,
    selectedArticle: null,
    actionMenuOpen: false,
    actionMenuPosition: null,
    updateItemDialogOpen: false,
  };

  searchRef = createRef();

  articlesApi = {
    setSearchInput: (value) => {
      this.searchRef.current.setValue(value);
    },
  };

  handleSaveNote = async (e, { note, noteIndex, id }) => {
    try {
      const article = this.props.articles.find((row) => row.id === id);
      const previousNote =
        article[`note${noteIndex === 0 ? "" : noteIndex + 1}`]?.text ?? "";
      await this.props.onUpdateArticleNote(article, noteIndex, note);
      this.props.snackbar.showMessage(
        note === ""
          ? this.props.t("Die Notiz wurde entfernt")
          : this.props.t("Die Notiz wurde aktualisiert"),
        this.props.t("Rückgängig"),
        async () => {
          try {
            const article = this.props.articles.find((row) => row.id === id);
            await this.props.onUpdateArticleNote(
              article,
              noteIndex,
              previousNote
            );
          } catch (e) {
            console.error(e);
            this.props.snackbar.showMessage(
              this.props.t(
                "Die Notizänderung konnte nicht rückgängig gemacht werden"
              )
            );
          }
        }
      );
    } catch (e) {
      console.error(e);
      this.props.snackbar.showMessage(
        this.props.t("Die Notiz konnte nicht gespeichert werden")
      );
    }
  };

  handleArticleAction = (action) => (e) => {
    if (action.PopoverForm) {
      this.setState({
        updateItemDialogOpen: true,
        action,
        // sometimes the table rows re-render between the context menu opening and the action being clicked, so update the anchor element here
        anchorEl: document.querySelector(
          `[data-row-id='${this.state.selectedArticle.id}']`
        ),
      });
      console.log(
        "anchor el",
        document.querySelector(
          `[data-row-id='${this.state.selectedArticle.id}']`
        ),
        this.state.selectedArticle.id
      );
    }
    this.setState({ actionMenuOpen: false });
  };

  handleSaveNoteColumnTitle = async (e, { noteIndex, title }) => {
    try {
      await this.props.onUpdateArticleNoteColumnTitle(noteIndex, title);
    } catch (e) {
      console.error(e);
      this.props.snackbar.showMessage(
        this.props.t("Der Spaltentitel konnte nicht gespeichert werden")
      );
    }
  };

  handleOpenNewArticleDialog = () => {
    this.setState({
      createDialogOpen: true,
      newArticleType: this.props.type,
      createArticle: null,
    });
  };

  handleCloseNewArticleDialog = () => {
    this.setState({ createDialogOpen: false });
  };

  handleCopy = (article) => {
    const { note, note2, note3, ...createArticle } = article;
    createArticle[article.type] = article.details;
    delete createArticle.details;
    createArticle.note = note?.text;
    createArticle.note2 = note2?.text;
    createArticle.note3 = note3?.text;
    createArticle.initialStock = [];

    const numericSku = createArticle.sku.match(/(.+?)(\d+)$/);
    if (numericSku) {
      // if the sku ends with a number, increment it
      createArticle.sku = `${numericSku[1]}${parseInt(numericSku[2], 10) + 1}`;
    } else {
      // otherwise, clear it
      createArticle.sku = "";
    }

    this.setState({
      createDialogOpen: true,
      editArticleDialogOpen: false,
      newArticleType: article.type,
      createArticle,
    });
  };

  handleCreateArticle = async (
    newArticle,
    { keepOpen, ignoreDuplicateSku }
  ) => {
    const sameSkuArticle = this.props.articles.find(
      (article) => article.sku === newArticle.sku
    );
    if (!ignoreDuplicateSku && sameSkuArticle) {
      this.setState({
        duplicateSkuArticle: newArticle,
        showDuplicateSkuDialog: true,
        keepCreateDialogOpen: keepOpen,
      });
    } else {
      this.setState({ showDuplicateSkuDialog: false });
      try {
        await this.props.onAddArticle(newArticle);
        this.resetCreateArticleForm();
        if (!keepOpen) {
          this.setState({ createDialogOpen: false });
        }
        this.props.snackbar.showMessage(
          this.props.t("Der Artikel wurde hinzugefügt")
        );
      } catch (e) {
        console.error(e);
        this.props.snackbar.showMessage(
          this.props.t("Der Artikel konnte nicht hinzugefügt werden")
        );
      }
    }
  };

  handleCreateDuplicateSkuArticle = () =>
    this.handleCreateArticle(this.state.duplicateSkuArticle, {
      keepOpen: this.state.keepCreateDialogOpen,
      ignoreDuplicateSku: true,
    });

  handleCancelDuplicateSkuArticle = () => {
    this.setState({ showDuplicateSkuDialog: false });
  };

  handleRowClick = (e, rowId, row) => {
    const articleActions = this.props.registry.getArticleActions(
      this.props.type
    );
    if (articleActions.length > 0) {
      const actionMenuPosition = {
        top: e.clientY,
        left: e.clientX,
      };
      this.setState({
        anchorEl: document.querySelector(`[data-row-id='${rowId}']`),
        selectedArticle: row,
        actionMenuOpen: true,
        actionMenuPosition,
      });
    } else {
      const article = this.props.articles.find((row) => row.id === rowId);
      this.setState({ editArticleDialogOpen: true, editArticle: article });
    }
  };

  handleHidePopover = () =>
    this.setState({
      updateItemDialogOpen: false,
      actionMenuOpen: false,
    });

  handleOpenEditSelectedArticleDialog = () => {
    this.setState((state) => ({
      editArticleDialogOpen: true,
      editArticle: state.selectedArticle,
      actionMenuOpen: false,
    }));
  };

  handleCloseEditArticleDialog = () => {
    this.setState({ editArticleDialogOpen: false });
  };

  handleEditArticle = async (article, updatedFields) => {
    try {
      await this.props.onEditArticle(article, updatedFields);
      this.setState({ editArticleDialogOpen: false });
    } catch (e) {
      console.error(e);
      this.props.snackbar.showMessage(
        this.props.t("Der Artikel konnte nicht bearbeitet werden")
      );
    }
  };

  handleArchive = async (articleId, noUndo) => {
    try {
      await this.props.onArchive(articleId);
      this.setState({ editArticleDialogOpen: false });
      this.props.snackbar.showMessage(
        this.props.t("Der Artikel wurde archiviert"),
        !noUndo && "Rückgängig",
        () => this.handleRestore(articleId, true)
      );
    } catch (e) {
      console.error(e);
      this.props.snackbar.showMessage(
        this.props.t("Der Artikel konnte nicht archiviert werden")
      );
    }
  };

  handleRestore = async (articleId, noUndo) => {
    try {
      await this.props.onRestore(articleId);
      this.setState({ editArticleDialogOpen: false });
      this.props.snackbar.showMessage(
        this.props.t("Der Artikel wurde wiederhergestellt"),
        !noUndo && "Rückgängig",
        () => this.handleArchive(articleId, true)
      );
    } catch (e) {
      console.error(e);
      this.props.snackbar.showMessage(
        this.props.t("Der Artikel konnte nicht wiederhergestellt werden")
      );
    }
  };

  handleRemove = async (articleId, noUndo) => {
    try {
      await this.props.onRemove(articleId);
      this.setState({ editArticleDialogOpen: false });
      this.props.snackbar.showMessage(
        this.props.t("Der Artikel wurde gelöscht"),
        !noUndo && this.props.t("Rückgängig"),
        () => this.handleRestore(articleId, true) // restoring also restores deleted articles
      );
    } catch (e) {
      console.error(e);
      this.props.snackbar.showMessage(
        this.props.t("Der Artikel konnte nicht gelöscht werden")
      );
    }
  };

  setCreateFormMethods = ({ reset }) => (this.resetCreateArticleForm = reset);

  getColumns = () => {
    const { registry, articleNoteColumns, type, fields, t } = this.props;

    let customFieldsKey = "";
    const columns = fields
      .filter(({ asField }) => asField == null)
      .map((field) => {
        customFieldsKey += field.id;
        return registry.customFieldType[field.type]?.getColumn(field, { t });
      });

    return {
      columns: [
        ...registry.articleTypes[type].articlesTableConfig({
          articleNoteColumns,
          t: this.props.t,
          registry,
        }),
        ...columns,
        articleNoteColumn({
          id: "note",
          title: articleNoteColumns?.[0],
          noteIndex: 0,
        }),
        articleNoteColumn({
          id: "note2",
          title: articleNoteColumns?.[1],
          noteIndex: 1,
        }),
        articleNoteColumn({
          id: "note3",
          title: articleNoteColumns?.[2],
          noteIndex: 2,
        }),
      ],
      tableKey: `${type}-${customFieldsKey}`,
    };
  };

  articleToStrings = (item, columns, rawValue = true) =>
    columns
      .filter((c) => c.export == null || !c.export.exclude)
      .map((c) => {
        if (c.export && c.export.getRawValue) {
          return c.export.getRawValue(item);
        }
        if (c.contentString) {
          return c.contentString(item);
        } else if (c.content) {
          return c.content(item);
        } else {
          return item[c.id];
        }
      })
      .map((value) => (value == null ? "" : value.toString()));

  articleToExcel = (item, columns, rawValue = true) =>
    columns
      .filter((c) => c.export == null || !c.export.exclude)
      .map((c) => {
        if (c.export && c.export.getRawValue) {
          return c.export.getRawValue(item);
        }
        if (c.contentString) {
          return c.contentString(item);
        } else if (c.content) {
          return c.content(item);
        } else {
          return item[c.id];
        }
      })
      .map((value) => (value == null ? "" : value));

  handleExport = async (columnIds, filename, format) => {
    const { articles } = this.props;
    const columns = this.getColumns().columns.filter(
      ({ id }) =>
        (c) =>
          columnIds.includes(c.id) && (c.export == null || !c.export.exclude)
    );

    if (format === "csv") {
      saveCsv(
        [
          columns.map((c) => c.id.replace(/^details\./, `${this.props.type}.`)),
          columns.map((c) => (c.export != null && c.export.title) || c.title),
          ...articles.map((item) => this.articleToStrings(item, columns)),
        ],
        filename
      );
    } else if (format === "xlsx") {
      saveXlsx(
        [
          columns.map((c) => c.id.replace(/^details\./, `${this.props.type}.`)),
          columns.map((c) => (c.export != null && c.export.title) || c.title),
          ...articles.map((item) => this.articleToExcel(item, columns)),
        ],
        filename,
        this.props.t("Artikel"),
        (worksheet) => {
          worksheet.views = [{ state: "frozen", ySplit: 2 }];
          worksheet.getRow(1).hidden = true;
        }
      );
    }
  };

  handleImportCsv = async (e) => {
    try {
      const importedArticlesPromise = this.props.onImportArticles(
        e.target.files[0]
      );
      e.target.value = ""; // allow selecting the same file twice
      const importedArticles = await importedArticlesPromise;
      this.props.snackbar.showMessage(
        this.props.t("{{count}} Artikel erfolgreich importiert", {
          count: importedArticles.length.toLocaleString(),
        })
      );
    } catch (e) {
      console.error(e);
      this.props.snackbar.showMessage(
        this.props.t("Die CSV-Datei konnte nicht importiert werden")
      );
    }
  };

  handleChangeSearch = debounce((search) => {
    this.setState({ search });
  }, 100);

  getFixedColumnStyles = ({ row, columnIndex, fixedColumnsCount }) => {
    const customField = this.props.fields.find(
      (field) => field.type === "color" && field.useAsTableRowColor
    );
    const background = customField
      ? row?.customFields.find(
          (field) => field.customFieldId === customField.id
        )?.value
      : undefined;
    if (!background) {
      return undefined;
    }
    if (columnIndex === fixedColumnsCount - 1) {
      return {
        background: `linear-gradient(270deg, ${fade(
          background,
          0
        )}, ${background} 8px)`,
        color: this.props.theme.palette.getContrastText(background),
      };
    }
    return {
      background,
      color: this.props.theme.palette.getContrastText(background),
    };
  };

  getFilteredArticles = (columns) => {
    const { search } = this.state;
    const { filters, searchTerms } = prepareSmartSearch(search);

    return (row) => {
      if (filters.some(({ isMatch }) => !isMatch(row))) {
        return false;
      }

      if (this.props.features.strictArticleSearch) {
        // at least one column must contain all search terms
        return this.articleToStrings(row, columns, false).some((value) =>
          searchTerms.every(
            (s) => value.toString().toLowerCase().indexOf(s.toLowerCase()) >= 0
          )
        );
      } else {
        // every search term must be contained in at least one column
        return searchTerms.every((s) =>
          this.articleToStrings(row, columns, false).some(
            (value) =>
              value.toString().toLowerCase().indexOf(s.toLowerCase()) >= 0
          )
        );
      }
    };
  };

  getCustomInputFields = () => {
    const { registry, fields } = this.props;

    return fields.map((field) => {
      return {
        CustomField: registry.customFieldType[field.type].getInputField(field, {
          t: this.props.t,
        }),
        customFieldId: field.id,
        asField: field.asField,
        defaultValue: field.defaultValue,
        target: "article",
        validate: (value) =>
          registry.customFieldType[field.type].validate?.(value, field),
      };
    });
  };

  handleSaveCustomField = async (value, customFieldId, articleId) => {
    const article = this.props.articles.find(
      (article) => article.id === articleId
    );
    const customFields = article.customFields;
    const customFieldIndex = article.customFields.findIndex(
      (field) => field.customFieldId === customFieldId
    );

    try {
      const updatedFields =
        customFieldIndex === -1
          ? [
              ...customFields,
              {
                customFieldId: customFieldId,
                value: String(value),
              },
            ]
          : [
              ...customFields.slice(0, customFieldIndex),
              {
                customFieldId: customFieldId,
                value: String(value),
              },
              ...customFields.slice(customFieldIndex + 1, customFields.length),
            ];
      await this.props.onEditArticle(article, {
        // Necessary to remove the __typename property
        customFields: updatedFields.map(
          ({ customFieldId, value, lastUpdated }) => ({
            customFieldId,
            value,
            lastUpdated,
          })
        ),
      });
    } catch (e) {
      console.error(e);
      const { fields } = this.props;
      const fieldDefinition = fields.find(
        (field) => field.id === customFieldId
      );
      const name = fieldDefinition
        ? fieldDefinition.name
        : `mit der internen ID ${customFieldId}`;
      this.props.snackbar.showMessage(
        this.props.t(
          "Das benutzerdefinierte Feld konnte nicht bearbeitet werden",
          { name }
        )
      );
    }
  };

  render() {
    const {
      classes,
      articles,
      articleNoteColumns,
      type,
      tab,
      onChangeTab,
      registry,
      error,
      loading,
      t,
    } = this.props;

    const {
      createDialogOpen,
      createArticle,
      editArticleDialogOpen,
      editArticle,
      newArticleType,
      showDuplicateSkuDialog,
      duplicateSkuArticle,
      selectedArticle,
      actionMenuOpen,
      actionMenuPosition,
      updateItemDialogOpen,
      action,
      anchorEl,
    } = this.state;

    const { columns, tableKey } = this.getColumns();
    const customInputFields = this.getCustomInputFields();

    const articleActions = this.props.registry.getArticleActions(type);

    return (
      <div className={classes.root}>
        <div className={classes.toolbar}>
          <SearchTextField
            innerRef={this.searchRef}
            onChange={this.handleChangeSearch}
            placeholder={this.props.t("Suchen…")}
            className={classes.search}
          />
          <div className={classes.flex} />
          <ToggleButton
            value={tab === "active"}
            onClick={() => onChangeTab("active")}
            className={classes.toolbarButton}
          >
            <Trans>Aktive Artikel</Trans>
          </ToggleButton>
          <ToggleButton
            value={tab === "archive"}
            onClick={() => onChangeTab("archive")}
            className={classes.toolbarButton}
          >
            <Trans>Archivierte Artikel</Trans>
          </ToggleButton>
          <div className={classes.flex} />
          <Button
            color="primary"
            variant="outlined"
            onClick={this.handleOpenNewArticleDialog}
            className={classes.addArticleButton}
          >
            <Trans>Artikel hinzufügen</Trans>
          </Button>
          <PopoverIconButton
            icon={TableArrowDown}
            PopoverProps={{ classes: { paper: classes.exportPopover } }}
            tooltip={this.props.t("Exportieren")}
            className={classes.toolbarIconButton}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {({ closePopover }) => (
              <TableExportForm
                columns={columns}
                onSubmit={(columnIds, filename, format) => {
                  closePopover();
                  this.handleExport(columnIds, filename, format);
                }}
                defaultFilename={this.props.t("Artikel {{type}} {{date}}", {
                  type: registry.articleTypes[type].displayName(2, { t }),
                  date: new Date().toLocaleDateString(),
                })}
              />
            )}
          </PopoverIconButton>
          <TooltipIconButton
            className={classes.toolbarIconButton}
            tooltip={this.props.t("Aus CSV importieren")}
            onClick={() => this.fileInput.current.click()}
          >
            <DatabaseImportOutline />
          </TooltipIconButton>
          <input
            type="file"
            ref={this.fileInput}
            style={{ display: "none" }}
            onChange={this.handleImportCsv}
            accept=".csv"
          />
          {registry.getArticleToolbarActions(type).map((action) => {
            const label = action.label({ t });
            const Button = ({ onClick }) => (
              <TooltipIconButton
                tooltip={label}
                className={classes.toolbarIconButton}
                onClick={onClick}
              >
                <action.Icon />
              </TooltipIconButton>
            );
            return (
              <action.Component
                key={label}
                Button={Button}
                articlesApi={this.articlesApi}
              />
            );
          })}
        </div>
        <ArticlesTable
          key={tableKey}
          className={classes.table}
          localStorageKey={`skl-articlesTable-${type}`}
          columns={columns}
          data={loading ? null : articles}
          rowFilter={this.getFilteredArticles(columns)}
          order="asc"
          orderBy="sku"
          cellContentParams={{
            handleSaveNote: this.handleSaveNote,
            handleSaveCustomField: this.handleSaveCustomField,
          }}
          headerActionsParams={{
            handleSaveNoteColumnTitle: this.handleSaveNoteColumnTitle,
          }}
          rowId={(row) => row.id}
          onRowClick={this.handleRowClick}
          size="small"
          EnhancedTableHeadProps={{ size: "medium" }}
          disableLineWrap
          maxColumnWidth={170 + 32}
          rowRenderer={(props, { row }) => (
            <ComponentExtensionPoint
              key={row.id}
              name="articleTableRow"
              innerProps={{ ...props, "data-row-id": row.id, row }}
              InnerComponent={TableRow}
              variant="nested"
            />
          )}
          highlightedRows={
            actionMenuOpen || updateItemDialogOpen
              ? [selectedArticle.id]
              : undefined
          }
          placeholder={
            <TablePlaceholder>
              {error ? (
                <Trans>
                  Beim Laden der Artikel ist ein Fehler aufgetreten. Bitte
                  überprüfen Sie Ihre Internetverbindung.
                </Trans>
              ) : tab === "active" ? (
                <>
                  {t("Es gibt keine aktiven {{type}}-Artikel.", {
                    type: registry.articleTypes[type].displayName(1, { t }),
                    context: type,
                  })}
                  <br />
                  <br />
                  <Trans>
                    Klicken Sie auf <em>Artikel hinzufügen</em>, um einen neuen
                    Artikel anzulegen.
                  </Trans>
                </>
              ) : (
                <>
                  {t("Es gibt keine archivierten {{type}}-Artikel.", {
                    type: registry.articleTypes[type].displayName(1, { t }),
                    context: type,
                  })}
                  <br />
                  <br />
                  {t(
                    "Sobald Sie aktive Artikel archivieren, erscheinen diese in dieser Ansicht und werden nicht mehr im Bestand angezeigt."
                  )}
                </>
              )}
            </TablePlaceholder>
          }
          fixedColumnStyles={this.getFixedColumnStyles}
        />
        <Menu
          open={actionMenuOpen}
          onClose={this.handleHidePopover}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          anchorReference="anchorPosition"
          anchorPosition={
            actionMenuPosition
              ? {
                  top: actionMenuPosition.top - 24 - 16,
                  left: actionMenuPosition.left - 24,
                }
              : null
          }
        >
          <ListSubheader className={classes.menuHeader}>
            {[selectedArticle?.name, selectedArticle?.sku]
              .filter((text) => !!text?.trim())
              .join(" · ")}
          </ListSubheader>
          <MenuItem onClick={this.handleOpenEditSelectedArticleDialog}>
            {t("Artikel bearbeiten")}
          </MenuItem>
          <Divider />
          {articleActions.map((action) => {
            if (action.MenuItem) {
              return (
                <action.MenuItem
                  key={
                    typeof action.label === "string"
                      ? action.label
                      : action.label({ t })
                  }
                  dense
                  article={selectedArticle}
                  onClick={this.handleArticleAction(action)}
                  onClose={this.handleHidePopover}
                />
              );
            }
            return (
              <MenuItem
                key={
                  typeof action.label === "string"
                    ? action.label
                    : action.label({ t })
                }
                dense
                onClick={this.handleArticleAction(action)}
                style={action.Icon ? { paddingRight: 48 } : undefined}
              >
                {typeof action.label === "string"
                  ? action.label
                  : action.label({ t })}
                {action.Icon && (
                  <ListItemIcon style={{ position: "absolute", right: 0 }}>
                    <action.Icon fontSize="small" />
                  </ListItemIcon>
                )}
              </MenuItem>
            );
          })}
        </Menu>
        <CreateArticleDialog
          open={createDialogOpen}
          defaultArticle={createArticle}
          onClose={this.handleCloseNewArticleDialog}
          onSubmit={this.handleCreateArticle}
          type={newArticleType}
          setFormMethods={this.setCreateFormMethods}
          noteColumnTitles={[
            articleNoteColumns?.[0] || this.props.t("Artikel-Notiz 1"),
            articleNoteColumns?.[1] || this.props.t("Artikel-Notiz 2"),
            articleNoteColumns?.[2] || this.props.t("Artikel-Notiz 3"),
          ]}
          isSkuRequired={this.props.isSkuRequired}
          customFields={customInputFields}
        />
        <Dialog
          open={showDuplicateSkuDialog}
          disableBackdropClick
          PaperComponent={DraggablePaper}
        >
          <DialogTitle id="draggable-paper-handle-delete">
            {t("Doppelte Artikelnummer")}
          </DialogTitle>
          <DialogContent>
            <>
              {t(
                "Es existiert bereits ein Artikel mit der Artikelnummer {{sku}}.",
                { sku: duplicateSkuArticle && duplicateSkuArticle.sku }
              )}
              <br />
              {t(
                "Möchten Sie einen weiteren Artikel mit der gleichen Artikelnummer anlegen?"
              )}
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancelDuplicateSkuArticle}>
              {t("Abbrechen")}
            </Button>
            <Button
              color="primary"
              onClick={this.handleCreateDuplicateSkuArticle}
            >
              {t("Artikel hinzufügen")}
            </Button>
          </DialogActions>
        </Dialog>
        <EditArticleDialog
          open={editArticleDialogOpen}
          article={editArticle}
          onClose={this.handleCloseEditArticleDialog}
          onSubmit={this.handleEditArticle}
          archived={tab === "archive"}
          onArchive={() => this.handleArchive(this.state.editArticle.id)}
          onRestore={() => this.handleRestore(this.state.editArticle.id)}
          onRemove={() => this.handleRemove(this.state.editArticle.id)}
          onCopy={() => this.handleCopy(this.state.editArticle)}
          noteColumnTitles={[
            articleNoteColumns?.[0] || this.props.t("Artikel-Notiz 1"),
            articleNoteColumns?.[1] || this.props.t("Artikel-Notiz 2"),
            articleNoteColumns?.[2] || this.props.t("Artikel-Notiz 3"),
          ]}
          customFields={customInputFields}
        />
        <Popover
          open={updateItemDialogOpen}
          onClose={this.handleHidePopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: classes.deltaPopover }}
          PaperProps={{
            component: DraggablePaper,
            DraggableProps: {
              handle: `.${classes.deltaPopover} > *:first-child`,
              defaultClassNameDragging: classes.popoverDragging,
            },
          }}
        >
          {selectedArticle && action && action !== "changeStock" && (
            <action.PopoverForm
              article={selectedArticle}
              onClose={this.handleHidePopover}
            />
          )}
        </Popover>
      </div>
    );
  }
}

Articles.propTypes = {
  classes: PropTypes.object.isRequired,
  articles: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  tab: PropTypes.oneOf(["active", "archive"]),
  onChangeTab: PropTypes.func.isRequired,
  onAddArticle: PropTypes.func.isRequired,
  onEditArticle: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
  onUpdateArticleNoteColumnTitle: PropTypes.func.isRequired,
  onImportArticles: PropTypes.func.isRequired,
  snackbar: PropTypes.object.isRequired,
  registry: PropTypes.object.isRequired,
  error: PropTypes.any,
  loading: PropTypes.bool,
  isSkuRequired: PropTypes.bool,
  features: PropTypes.object.isRequired,
};

export default withTranslation()(
  withStyles(styles, { withTheme: true })(
    withRegistry()(withSnackbar()(Articles))
  )
);
