import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { gql } from "graphql.macro";
import StockActionFormTemplate from "./StockActionFormTemplate";
import useCustomFields from "../customFields/useCustomFields";
import { useRegistry } from "../plugins/registry";

const ORDER_STOCK = gql`
  mutation OrderStock(
    $stockItemId: ID!
    $amount: Float!
    $employee: String
    $note: String
    $customFields: [CustomFieldValueInput!]
  ) {
    orderStock(
      stockItemId: $stockItemId
      order: {
        amount: $amount
        employee: $employee
        note: $note
        customFields: $customFields
      }
    ) {
      id
      ordered
      stockItem {
        id
        ordered
      }
    }
  }
`;

export const OrderStockForm = (props) => {
  const { t } = useTranslation();
  const registry = useRegistry();

  const getDeltaLines = useCallback(
    (delta) => [
      {
        label: t("stockReservation:Aktuell geplanter Eingang"),
        value: props.item.ordered,
      },
      {
        label: t("stockReservation:Neuer geplanter Eingang"),
        value: props.item.ordered + delta,
        showIf: (value) => value >= 0,
      },
      {
        label: t("stockReservation:Neuer Planbestand"),
        value:
          props.item.initial -
          props.item.out +
          props.item.in -
          props.item.reserved +
          props.item.ordered +
          delta,
      },
    ],
    [t, props.item]
  );

  const canSubmit = useCallback((delta) => delta > 0, []);

  const customFieldDefinitions = useCustomFields({
    stockOrderType: props.item.type,
  });

  const customFields = useMemo(() => {
    return customFieldDefinitions.map((field) => {
      return {
        CustomField: registry.customFieldType[field.type].getInputField(field, {
          t,
        }),
        customFieldId: field.id,
        asField: field.asField,
        defaultValue: field.defaultValue,
        target: "stockOrderItem",
      };
    });
  }, [customFieldDefinitions, registry.customFieldType, t]);

  return (
    <StockActionFormTemplate
      {...props}
      actionLabel={t("stockReservation:Artikel bestellen")}
      submitQuery={ORDER_STOCK}
      getDeltaLines={getDeltaLines}
      canSubmit={canSubmit}
      customFields={customFields}
      successMessage={t("stockReservation:Die Bestellung wurde angelegt.")}
      errorMessage={t(
        "stockReservation:Die Bestellung konnte nicht angelegt werden."
      )}
    />
  );
};

const CONFIRM_ORDERED_STOCK = gql`
  mutation ConfirmOrderedStock(
    $orderId: ID!
    $amount: Float!
    $employee: String
    $note: String
  ) {
    confirmOrderedStock(
      orderId: $orderId
      confirm: { amount: $amount, employee: $employee, note: $note }
    ) {
      id
      ordered
      stockItem {
        id
        initial
        in
        out
        ordered
      }
    }
  }
`;

export const ConfirmOrderedStockForm = (props) => {
  const { t } = useTranslation();
  const getDeltaLines = useCallback(
    (delta) => [
      {
        label: t("stockReservation:Neuer geplanter Eingang"),
        value: props.item.ordered - delta,
        showIf: (value) => value >= 0,
      },
      {
        label: t("stockReservation:Neuer realer Bestand"),
        value: props.item.initial - props.item.out + props.item.in + delta,
      },
    ],
    [t, props.item]
  );

  const registry = useRegistry();
  const { stockInfo } = registry.stockItemTypes[props.item.type];
  const getDelta = useCallback(
    (initialDelta) =>
      stockInfo.actualToDelta
        ? stockInfo.actualToDelta(initialDelta, props.item.article)
        : initialDelta,
    [props.item.article, stockInfo]
  );

  const canSubmit = useCallback(
    (delta) => delta > 0 && delta <= getDelta(props.item.ordered),
    [props.item.ordered, getDelta]
  );

  return (
    <StockActionFormTemplate
      {...props}
      actionLabel={t("stockReservation:Wareneingang bestätigen")}
      submitQuery={CONFIRM_ORDERED_STOCK}
      getDeltaLines={getDeltaLines}
      canSubmit={canSubmit}
      customFields={undefined}
      successMessage={t("stockReservation:Der Wareneingang wurde bestätigt.")}
      errorMessage={t(
        "stockReservation:Der Wareneingang konnte nicht bestätigt werden."
      )}
      initialDelta={getDelta(props.item.ordered)}
    />
  );
};

const CANCEL_ORDERED_STOCK = gql`
  mutation CancelOrderedStock(
    $orderId: ID!
    $amount: Float!
    $employee: String
    $note: String
  ) {
    cancelOrderedStock(
      orderId: $orderId
      cancel: { amount: $amount, employee: $employee, note: $note }
    ) {
      id
      ordered
      stockItem {
        id
        initial
        in
        out
        ordered
      }
    }
  }
`;

export const CancelOrderedStockForm = (props) => {
  const { t } = useTranslation();
  const getDeltaLines = useCallback(
    (delta) => [
      {
        label: t("Neuer geplanter Eingang"),
        value: props.item.ordered - delta,
        showIf: (value) => value >= 0,
      },
    ],
    [t, props.item]
  );

  const registry = useRegistry();
  const { stockInfo } = registry.stockItemTypes[props.item.type];
  const getDelta = useCallback(
    (initialDelta) =>
      stockInfo.actualToDelta
        ? stockInfo.actualToDelta(initialDelta, props.item.article)
        : initialDelta,
    [props.item.article, stockInfo]
  );

  const canSubmit = useCallback(
    (delta) => delta <= getDelta(props.item.ordered),
    [props.item.ordered, getDelta]
  );

  return (
    <StockActionFormTemplate
      {...props}
      actionLabel={t("stockReservation:Bestellung stornieren")}
      submitQuery={CANCEL_ORDERED_STOCK}
      getDeltaLines={getDeltaLines}
      canSubmit={canSubmit}
      customFields={undefined}
      successMessage={t("stockReservation:Die Bestellung wurde aktualisiert.")}
      errorMessage={t(
        "stockReservation:Die Bestellung konnte nicht aktualisiert werden."
      )}
      initialDelta={getDelta(props.item.ordered)}
    />
  );
};
